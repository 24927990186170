<template>
  <div class="img-container">
    <img :src="getUrlIcons('play')" alt="play">
  </div>
</template>

<script>
export default {
  name: "PlayButton"
}
</script>

<style scoped lang="scss">

  .img-container {
    cursor: pointer;
    transition: all .3s ease;
    &:hover {
      opacity: .8;
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }



  @media (max-width: 3024px){
    .img-container {
      width: 84px;
      height: 84px;
    }
  }


  @media (max-width: 1920px) {
    .img-container {
      width: 80px;
      height: 80px;
    }
  }


  @media (max-width: 1600px) {
    .img-container {
      width: 64px;
      height: 64px;
    }
  }
  @media (max-height: 800px) {
    .img-container {
      width: 44px;
      height: 44px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .img-container {
      width: 64px;
      height: 64px;
    }
  }

  @media (max-width: 1133px) {
    .img-container {
      width: 44px;
      height: 44px;
    }
  }

  @media (max-width: 769px) {
    .img-container {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .img-container {
      width: 24px;
      height: 24px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .img-container {
      width: 90px;
      height: 90px;
    }
  }

</style>