<template>
  <div class="card-main-title-container text-content">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "MainTitleCard"
}
</script>

<style scoped lang="scss">
  .card-main-title-container {
    font-weight: 600;
    line-height: 120%;
  }



  @media (max-width: 3024px){
    .card-main-title-container {
      font-size: 32px;
    }
  }


  @media (max-width: 1920px) {
    .card-main-title-container {
      font-size: 24px;
    }

  }


  @media (max-width: 1600px) {
    .card-main-title-container {
      font-size: 20px;
    }
  }

  @media (max-height: 800px) {
    .card-main-title-container {
      font-size: 18px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-main-title-container {
      font-size: 20px;
    }
  }

  @media (max-width: 1133px) {
    .card-main-title-container {
      font-size: 18px;
    }
  }

  @media (max-width: 769px) {
    .card-main-title-container {
      font-size: 14px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-main-title-container {
      font-size: 14px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .card-main-title-container {
      font-size: 48px;
    }
  }



</style>