<template>
  <main-header-desktop-tablet v-if="!isMobile"
                              @scrollToTop="scrollToTop"
                              @scrollTo="scrollTo"
                              :menu-info="menuInfo"
                              :active-fixed-header="activeFixedHeader"/>
  <main-header-mobile v-else :menu-info="menuInfo"
                      @scrollToTop="scrollToTop"
                      @scrollTo="scrollTo"/>
</template>

<script>
import MainHeaderDesktopTablet from "@/components/widgets/MainHeaderDesktopTablet.vue";
import MainHeaderMobile from "@/components/widgets/MainHeaderMobile.vue";

export default {
  name: "MainHeader",
  components: {MainHeaderMobile, MainHeaderDesktopTablet},
  props: {
    activeFixedHeader: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data() {
    return {
      routs: ['about', 'features', 'technology', 'price', 'developer', 'support', 'contact']
    }
  },
  computed: {
    menuInfo() {
      return this.$tm('mainHeader.menuInfo').map((item, index) => ({
        ...item,
        rout: this.routs[index],
        id: index
      }))
    }
  },
  methods: {
    scrollToTop() {
      this.$router.push(`/`)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    scrollTo(item) {
      this.$router.push(`/${item.rout}`)
      let parent = document.querySelector('.main-wrapper');
      let block = parent.querySelector(`#${ item.rout }`)
      block?.scrollIntoView({
        block: this.isActivePositionScroll(item.rout),
        ...this.isMobile ? { top: block?.offsetTop }: {},
        behavior: "smooth",
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>