<template>
  <div class="video-screen-container">
    <div class="video-screen-content main-content" v-animateonscroll="{ enterClass: 'fade-in'}">
      <play-button/>
    </div>
  </div>
</template>

<script>
import PlayButton from "@/components/shared/buttons/PlayButton.vue";

export default {
  name: "VideoScreen",
  components: {PlayButton}
}
</script>

<style scoped lang="scss">

.video-screen-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-font-alice-blue);
  height: var(--calc-height);
  .video-screen-content {
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
@media(max-width: 900px) {
  .video-screen-container {
    height: 240px;
  }
}
</style>