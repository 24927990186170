<template>
  <div class="cookie-container blur-active">
    <transition name="fade-opacity-lazy">
      <cookie-start-button v-if="!cookieSetting"
                           @changeModalCookieSetting="changeModalCookieSetting"
                           @closeCookie="closeCookie"/>
      <cookie-modal @changeStateCookie="changeModalCookieSetting"
                    @closeCookie="closeCookie"
                    v-else/>
    </transition>
  </div>
</template>

<script>

import CookieStartButton from "@/components/entities/cookie/CookieStartButton.vue";
import CookieModal from "@/components/entities/cookie/CookieModal.vue";
import {mapActions} from "vuex";

export default {
  name: "CookieScreen",
  components: {CookieModal, CookieStartButton},
  data() {
    return {
      cookieSetting: false,
    }
  },
  methods: {
    ...mapActions(['IS_ACTIVE_MODAL_COOKIE']),
    changeModalCookieSetting() {
      this.cookieSetting = !this.cookieSetting;
    },
    closeCookie() {
      localStorage.setItem('cookie_is_active', 'true')
      this.IS_ACTIVE_MODAL_COOKIE(false)
    }
  }
}
</script>

<style scoped lang="scss">
.cookie-container {
  position: fixed;
  display: flex;
  justify-content: center;
  top:0;left:0;right:0;bottom: 0;
  width: var(--calc-width);
  height: var(--calc-height);
  z-index: 999;

  .toggle-container {
    z-index: 901;
    position: absolute;
  }
}

@media (max-width: 3024px){
  .cookie-container {
    backdrop-filter: blur(8px);
    .toggle-container {
      right: 200px;
      top: 100px
    }
  }
}


@media (max-width: 1920px) {
  .cookie-container {
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}


@media (max-width: 1600px) {
  .cookie-container {
    backdrop-filter: blur(7px);
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}
@media (max-height: 800px){
  .cookie-container {
    backdrop-filter: blur(5px);
    .toggle-container {
      right: 50px;
      top: 25px
    }
  }
}


@media (max-width: 1400px) and (min-height: 801px) {
  .cookie-container {
    backdrop-filter: blur(7px);
    .toggle-container {
      right: 100px;
      top: 50px
    }
  }
}


@media (max-width: 1133px) {
  .cookie-container {
    backdrop-filter: blur(5px);
    .toggle-container {
      right: 50px;
      top: 25px
    }
  }
}

@media (max-width: 900px) {
  .cookie-container {
    backdrop-filter: blur(3px);
    align-items: center;
    padding: 0 14px;
  }
}
@media (max-width: 900px)  and (min-height: 748px) and (max-height: 1000px) {
  .cookie-container {
    align-items: center;
    padding: 0 16px;
  }
}


@media (min-width: 3025px) {
  .cookie-container {
    backdrop-filter: blur(12px);
    .toggle-container {
      right: 400px;
      top: 200px
    }
  }
}
</style>