<template>
  <div class="about-screen-elements-container">
<!--      <slider-scroll class="active-gradient"-->
<!--                     :slides-per-view="slideCount"-->
<!--                     v-if="!isMobile">-->
<!--        <swiper-slide v-for="(item) in elementsInfo">-->
<!--          <card-element :item="item"/>-->
<!--        </swiper-slide>-->
<!--      </slider-scroll>-->
    <div class="about-screen-elements-content">
      <card-element v-for="(item) in elementsInfo"
                    :item="item"
                    :key="item.id"/>
    </div>
  </div>
</template>

<script>
import {Mousewheel} from "swiper";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";
import CardElement from "@/components/entities/card/CardElement.vue";
export default {
  name: "AboutScreenElements",
  components: {CardElement, SliderScroll, MainParagraphCard, MainTitleCard},
  data() {
    return {
      slideCount: 3.5
    }
  },
  computed: {
    elementsInfo() {
      return this.$tm('aboutScreenElements.elementsInfo').map((item, index) => ({...item,
        id: index,
        icon: index + 1 }))
    }
  },
  watch: {
    innerWidthCustom() {
      this.checkSize();
    }
  },
  methods: {
    checkSize() {
      if(this.innerWidthCustom > 3840) {
        this.slideCount = 4.2
      } else if (this.innerWidthCustom > 3024) {
        this.slideCount = 4.1
      } else if (this.innerWidthCustom > 1921) {
        this.slideCount = 3.7
      } else {
        this.slideCount = 3.5
      }
    }
  }
}
</script>

<style scoped lang="scss">
.about-screen-elements-content {
  display: flex;
  flex-direction: row;

  .element-container {
    flex-basis: 20% !important;
    max-width: 100% !important;
  }
}



@media (max-width: 3024px){
  .about-screen-elements-content {
    column-gap: 30px;
  }

}


@media (max-width: 1920px) {
  .about-screen-elements-content {
    column-gap: 20px;
  }
}


@media (max-width: 1600px) {
  .about-screen-elements-content {
    column-gap: 20px;
  }
}
@media (max-height: 800px) {
  .about-screen-elements-content {
    column-gap: 16px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .about-screen-elements-content {
    column-gap: 20px;
  }
}

@media (max-width: 1133px) {
  .about-screen-elements-content {
    column-gap: 16px;
  }
}
@media (max-width: 900px) {
  .about-screen-elements-content {
    flex-wrap: wrap;
    row-gap: 16px;
    align-items: center;
    justify-content: center;

    .element-container {
      flex-basis: 30% !important;
    }
  }
}
@media (max-width: 769px) {
  .about-screen-elements-content {
    flex-direction: column;
    row-gap: 32px;

    .element-container {
      flex-basis: 100% !important;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .about-screen-elements-content {
    column-gap: 40px;
  }
}

</style>