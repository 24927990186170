de<template>
  <div class="main-header-content-logo">
    <div class="logo-container icon">
      <img :src="getUrlLogo(logoImg)" alt="logo">
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderLogo",
  data() {
    return {
      logoImg: 'logo-item'
    }
  }
}
</script>

<style scoped lang="scss">
  .main-header-content-logo {
    height: inherit;
    width: 100%;
    position: absolute;
    background: var(--color-font-delft);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    cursor: pointer;

    & img {
      width: 100%;
      height: 100%;
    }
  }

  @media (max-width: 3024px){

      .main-header-content-logo {
        border-radius: 50px;
      }

    .icon {
      max-width: 32px;
      max-height: 22px;
    }
  }


  @media (max-width: 1920px) {
      .main-header-content-logo {
        border-radius: 50px;
      }

    .icon {
      max-width: 28px;
      max-height: 20px;
    }
  }


  @media (max-width: 1600px) {
      .main-header-content-logo {
        border-radius: 50px;
      }

    .icon {
      max-width: 22px;
      max-height: 16px;
    }
  }
  @media (max-height: 800px) {
    .main-header-content-logo {
      position: relative;
      width: 62px !important;
    }
    .icon {
      max-width: 20px;
      max-height: 14px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
      .main-header-content-logo {
        border-radius: 50px;
      }

    .icon {
      max-width: 22px;
      max-height: 16px;
    }
  }

  @media (max-width: 1133px) {
    .main-header-content-logo {
      position: relative;
      width: 62px !important;
    }
    .icon {
      max-width: 20px;
      max-height: 14px;
    }
  }

  @media (max-width: 769px) {
    //14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
      .main-header-content-logo {
        border-radius: 75px;
      }

    .icon {
      max-width: 48px;
      max-height: 34px;
    }
  }
</style>