<template>
  <div class="color-text-container">
    <sub-title v-for="(item, index) in sliceText" :key="index">
           <span :class="{'active-color-text' : sliceColorText.includes(item), 'active-split' : item === ','}" >
              {{ item }}
           </span>
    </sub-title>
  </div>

</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";

export default {
  name: "ColorText",
  components: {SubTitle},
  props: {
    textInfo: {
        type: String,
        required: true
    },
    activeColorText: {
      type: String,
      required: true
    }
  },
  computed: {
      sliceText() {
        return this.textInfo.split(/(\s+)/);
      },
      sliceColorText() {
        return this.activeColorText.split(/(\s+)/);
      }
    },
}
</script>

<style scoped lang="scss">
  .color-text-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    text-transform: uppercase;
    .active-color-text {
      line-height: 100%;
      color: var(--color-font-delft)
    }
  }



  @media (max-width: 3024px){
    .color-text-container {
      column-gap: 9px;
      .active-split {
        margin-left: -18px;
      }
    }
  }


  @media (max-width: 1920px) {
    .color-text-container {
      column-gap: 4px;
      .active-split {
        margin-left: -12px;
      }
    }
  }


  @media (max-width: 1600px) {
    .color-text-container {
      column-gap: 3px;
      .active-split {
        margin-left: -6px;
      }
    }
  }
  @media (max-height: 800px) {

  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .color-text-container {
      column-gap: 3px;
      .active-split {
        margin-left: -6px;
      }
    }
  }

  @media (max-width: 1133px) {

  }

  @media (max-width: 900px) {
    .color-text-container {
      column-gap: 2px;
      .active-split {
        margin-left: -4px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .color-text-container {
      column-gap: 12px;
      .active-split {
        margin-left: -24px;
      }
    }
  }

</style>