<template>
  <div class="input-container"
       :style="{ width: `${inputWidth}px` }">
    <input :type="info?.type || 'number'"
           :name="info?.dataIndex || 'value'"
           :placeholder="info?.placeholder || '-'"
           v-model="textInput">
    <slot/>
    <span ref="textWidth" class="hidden-span">{{ textInput || info?.placeholder }}</span>
  </div>
</template>

<script>

export default {
  name: "InputBorder",
  props: {
    maxLength: {
      type: Number,
      required: true
    },
    activeCurrency: {
      type: Boolean
    },
    info: {
      type: Object,
      required: true
    },
    nameBlock: {
      type: String
    },
    activeTextInput: {
      type: [String, Number]
    }
  },

  mounted() {
    if(this.activeTextInput !== undefined) {
      this.textInput = this.activeTextInput
    }
    this.checkSize(window.innerWidth);
  },


  data() {
    return {
      inputWidth: 80,
      textInput: '',
      size: 0
    }
  },
  methods: {
    checkSize(width) {
      if (width > 3024) {
        this.size = !this.activeCurrency ? 66 : 112
      } else if (width > 1920) {
        this.size = !this.activeCurrency ? 56 : 81
      } else if (width > 1600) {
        this.size = !this.activeCurrency ? 34 : 72
      } else if (width > 1300) {
        this.size = !this.activeCurrency ? 34 : 68
      } else {
        this.size = !this.activeCurrency ? 24 : 68
      }
    }
  },
  watch: {
    activeTextInput() {
      this.textInput = this.activeTextInput
    },
    innerWidthCustom() {
      this.checkSize(this.innerWidthCustom)
    },
    textInput() {
      if(this.maxLength < this.textInput) {
       this.textInput = this.maxLength
      }
      this.inputWidth = this.$refs.textWidth.offsetWidth + this.size;
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
  },
}
</script>

<style scoped lang="scss">

.input-container {
  overflow: visible;
  cursor: text;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  position: relative;
  transition: all .5s ease;
  height: 100%;
  width: fit-content;
  border-color: var(--color-font-alice-blue);
  border-style: solid;

  & input {
    white-space: nowrap;
    width: 100%;
    overflow: visible;
    color: var(--color-font-squid) !important;
    background-color: rgba(1,1,1,0) !important;
    &::placeholder {
      color: var(--color-font-cool-grey);
      opacity: .4;
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.hidden-span {
  font-family: inherit;
  white-space: nowrap;
  visibility: hidden;
  position: absolute;
}
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}



@media (max-width: 3024px){
  .input-container {
    column-gap: 6px;
    min-width: 56px;
    border-radius: 6px;
    padding: 8px 14px;
    max-height: 42px;
    border-width: 2px;

    & input {
      font-size: 22px;
    }
  }

  .hidden-span {
    font-size: 22px;
  }
}


@media (max-width: 1920px) {
  .input-container {
    min-width: 50px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 34px;
    border-width: 2px;

    & input {
      font-size: 18px;
    }
  }

  .hidden-span {
    font-size: 18px;
  }
}


@media (max-width: 1600px) {
  .input-container {
    min-width: 48px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 34px;
    border-width: 2px;

    & input {
      font-size: 16px;
    }
  }

  .hidden-span {
    font-size: 16px;
  }
}
@media (max-height: 800px) {
  .input-container {
    min-width: 48px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 29px;
    border-width: 2px;

    & input {
      font-size: 14px;
    }
  }

  .hidden-span {
    font-size: 14px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .input-container {
    min-width: 48px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 34px;
    border-width: 2px;

    & input {
      font-size: 16px;
    }
  }

  .hidden-span {
    font-size: 16px;
  }
}

@media (max-width: 1133px) {
  .input-container {
    min-width: 48px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 29px;
    border-width: 2px;

    & input {
      font-size: 14px;
    }
  }

  .hidden-span {
    font-size: 14px;
  }
}

@media (max-width: 900px) {
  .input-container {
    min-width: 48px;
    border-radius: 6px;
    padding: 6px 10px;
    max-height: 32px;
    border-width: 2px;

    & input {
      height: 100%;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .input-container {
    column-gap: 16px;
    min-width: 86px;
    border-radius: 6px;
    padding: 12px 18px;
    max-height: 62px;
    border-width: 2px;

    & input {
      font-size: 32px;
    }
  }

  .hidden-span {
    font-size: 32px;
  }
}

</style>