<template>
  <header class="main-header-container">
    <div class="main-header-content">
      <div class="img-container logo" @click="scrollToTop">
        <img :src="getUrlLogo(logoImgHover)" alt="logo">
      </div>
      <div class="img-container menu" :class="{'cross' : activeMenu}">
        <transition name="fade-rotate">
          <div class="img-content" v-if="!activeMenu" @click="changeStateMenu">
            <img  :src="getUrlIcons(menuIcon)" alt="logo">
          </div>
          <div class="img-content" v-else @click="changeStateMenu">
            <img :src="getUrlIcons(crossIcon)" alt="logo">
          </div>
        </transition>
      </div>

    </div>
    <transition name="fade-translate-x-card-revert">
      <div class="menu-burger-container" v-if="activeMenu">
        <menu-item :menu-info="menuInfo"
                   @scrollTo="scrollTo"/>
        <div class="action-container">
          <main-button class="light small" @click="changeLocal">
                        {{ $t('local') }}
           </main-button>
          <main-button class="light small" @click="openModalDemo">
            {{ $t('mainHeader.buttonText') }}
          </main-button>
        </div>
      </div>
    </transition>
  </header>
</template>

<script>
import MenuItem from "@/components/widgets/MenuItem.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";

export default {
  name: "MainHeaderMobile",
  components: {MainButton, MenuItem},
  props: {
    menuInfo: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      activeMenu: false,
      timer: null,
      logoImgHover: 'logo',
      menuIcon: 'menu',
      crossIcon: 'cross'
    }
  },
  methods: {
    changeLocal() {
      this.$i18n.locale === 'en' ? this.$i18n.locale = 'ru' :  this.$i18n.locale = 'en'
      document.documentElement.lang = this.$i18n.locale
    },
    changeStateMenu() {
      this.activeMenu = !this.activeMenu
    },
    scrollToTop() {
      clearTimeout(this.timer)
      if(this.activeMenu) {
        this.timer =setTimeout(() => {
          this.activeMenu = false;
        }, 600)
      } else {
        this.activeMenu = false;
      }
      this.$emit('scrollToTop')
    },
    scrollTo(id) {
      clearTimeout(this.timer)
      if(id !== 5) {
        this.timer = setTimeout(() => {
          this.activeMenu = false;
        }, 600)
      }
      this.$emit('scrollTo', id)
    },
  }
}
</script>

<style scoped lang="scss">
  .main-header-container {
    z-index: 950;
    position: fixed;
    top: 0;
    width: 100%;
    .main-header-content {
      backdrop-filter: blur(20px);
      position: relative;
      z-index: 901;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 100%;
      background: var(--color-background-header);

      .img-container {
        transition: all .3s ease;
        width: 100%;
        height: 100%;

        .img-content {
          width: 100%;
          height: 100%;
        }

        & img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }

    .menu-burger-container {
      z-index: 900;
      position: absolute;
      width: var(--calc-width);
      height: var(--calc-height);
      top: 0;
      left: 0;
      background: linear-gradient(90deg, var(--azure), var(--pale));
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .action-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  @media (max-width: 900px) {
    .main-header-container {
      padding: 12px 22px 0;
      height: 58px;
      .main-header-content {
        padding: 12px 16px;
        border-radius: 50px;

        .img-container {
          &.logo {
            max-width: 82px;
          }
          &.menu {
            max-width: 20px;
          }
          &.cross {
            padding: 2px;
          }
        }
      }

      .menu-burger-container {
        padding: 72px 22px 40px;
      }
    }
  }

  @media (max-width: 769px) {
    .main-header-container {
      padding: 12px 22px 0;
      height: 58px;
      .main-header-content {
        padding: 12px 16px;
        border-radius: 50px;

        .img-container {
          &.logo {
            max-width: 82px;
          }
          &.menu {
            max-width: 20px;
          }
          &.cross {
            padding: 2px;
          }
        }
      }

      .menu-burger-container {
        padding: 90px 22px 40px;
      }
    }
  }
  @media (max-width: 375px) {
    .main-header-container {
      padding: 12px 16px 0;
      height: 58px;
      .main-header-content {
        padding: 12px 16px;
        border-radius: 50px;

        .img-container {
          &.logo {
            max-width: 82px;
          }
          &.menu {
            max-width: 20px;
          }
        }
      }

      .menu-burger-container {
        padding: 90px 16px 24px;
      }
    }
  }


  @media (max-width: 320px) {
    //se old
  }
</style>