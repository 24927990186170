import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import reusedCodeMixin from "@/mixins/reused-code.mixin";
import VueAwesomeSwiper from "vue-awesome-swiper";
import modalMixin from "@/mixins/modal.mixin";
import AnimateOnScroll from '@/library/animateonscroll';
import particlesJS from 'particles.js'
import i18n from "@/locales/i18n-config";
import VueTelInput from 'vue-tel-input';
import './assets/styles/tel-phone.scss';
createApp(App)
    .use(store)
    .use(router)
    .use(i18n)
    .use(VueTelInput)
    .use(particlesJS)
    .use(VueAwesomeSwiper)
    .directive('animateonscroll', AnimateOnScroll)
    .mixin(reusedCodeMixin)
    .mixin(modalMixin).mount('#app')
