<template>
  <layout-modal-form
              class="cookie"
               :disabled="false"
               :main-title="$t('cookie.modal.mainTitle')"
               :text-button="$t('cookie.modal.buttonText')"
               :active-disclaimer="false"
               @actionButton="closeCookie"
               @closeModal="changeModalCookieSetting">
    <div class="item-container">
      <div class="item-content"
           v-for="(item, index) in $tm('cookie.modal.info')"
           :key="index">
        <div class="main-title-container">
          {{ item }}
        </div>
        <div class="main-title-container dark" v-if="index === 0">
          {{ $t('cookie.modal.infoStatic') }}
        </div>
        <toggle v-else :name-check="item + index"/>
      </div>
    </div>
  </layout-modal-form>
</template>

<script>

import LayoutModalBackground from "@/components/entities/modals/layouts/LayoutModalBackground.vue";
import LayoutModalForm from "@/components/entities/modals/layouts/LayoutModalForm.vue";
import Toggle from "@/components/shared/Toggle.vue";

export default {
  name: "CookieModal",
  components: {Toggle, LayoutModalForm, LayoutModalBackground},
  data() {
    return {
      isActiveButton: [true, true, true]
    }
  },
  methods: {
    changeModalCookieSetting() {
      this.$emit('changeStateCookie')
    },
    closeCookie() {
      this.$emit('closeCookie', false)
    },

    changeActive(index) {
      console.log(index)
      this.isActiveButton[index] = !this.isActiveButton[index]
    }
  }
}
</script>

<style scoped lang="scss">

  .modal-layout-container {
    .item-container {
      display: flex;
      flex-direction: column;

      .main-title-container {
        color: var(--color-font-nickel);

        &.dark {
          color: var(--color-font-squid)
        }
      }
      .item-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }
  }

  @media (max-width: 3024px){
    .modal-layout-container {
      .item-container {
        margin-top: 30px;
        row-gap: 28px;

        .main-title-container {
          font-size: 24px;
        }
      }
    }
  }



    @media (max-width: 1920px) {
      .modal-layout-container {
        .item-container {
          margin-top: 20px;
          row-gap: 20px;

          .main-title-container {
            font-size: 16px;
          }
        }
      }
    }

  @media (max-width: 900px) {
    .modal-layout-container {
      .item-container {
        margin-top: 8px;
        row-gap: 14px;

        .main-title-container {
          white-space: pre-wrap;
          font-size: 12px;
          &.dark {
            white-space: nowrap;
          }
        }
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 740px) and (max-height: 1000px) {
    .frame-modal-container {
    }
  }

  @media (max-width: 375px){
    .frame-modal-container {
    }
  }

  @media (max-width: 320px){
    .frame-modal-container {
    }
  }


  @media (min-width: 3025px) {
    .modal-layout-container {
      .item-container {
        margin-top: 40px;
        row-gap: 40px;

        .main-title-container {
          font-size: 36px;
        }
      }
    }
  }


</style>