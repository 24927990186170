<template>
  <div class="slider-scroll-container">
    <swiper @slide-change="onSwiperMain"
            @swiper="changeSettingSwiper"
            :slideToClickedSlide="true"
            class="swiper"
            :breakpoints="
            {
              0: {
                spaceBetween: 20,
                allowTouchMove: true
              },
              380: {
                spaceBetween: 24,
                allowTouchMove: true
              },
              1000: {
                spaceBetween: 20,
                allowTouchMove: true
              },
              1600: {
                spaceBetween: 20,
                allowTouchMove: true
              },
              1921: {
                allowTouchMove: true,
                spaceBetween: 20,
              },
              3025: {
                allowTouchMove: true,
                spaceBetween: 40
              }
            }"
            :mousewheel="true"
            :slides-per-view="slidesPerView"
            :allow-touch-move="true"
            :speed="speed"
            direction="horizontal"
            :effect="'fade'">
      <slot/>
    </swiper>
  </div>


</template>

<script>
export default {
  name: "SliderScroll",
  props: {
    speed: {
      type: Number,
      default: 1000
    },
    slidesPerView: {
      type: Number,
      default: 3.5
    },
    navigationClass: {
      type: String,
      default: 'default'
    },
    activeScroll: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      scrollSwiper: '',
      activeIndex: 0
    }
  },
  methods: {
    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
      this.$emit('changeActiveIndex', this.activeIndex)
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
      this.$emit('initSwiper')
    },
  }
}
</script>

<style scoped lang="scss">


.slider-scroll-container {
  overflow: hidden;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  width: 100%;
  height: 100%;

  .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;
  }

  .swiper-button-left {
    z-index: 100;
    position: absolute;
    cursor: pointer;
  }

  .swiper-button-right {
    z-index: 100;
    position: absolute;
    cursor: pointer;
  }
}

.swiper {
  display: flex;
  flex-direction: column-reverse;
}

</style>