<template>
  <div class="icon-circle-container">
    <div class="img-container" v-if="img">
      <img :src="getUrlImplements(img)" alt="icon">
    </div>
    <div class="text-container" v-else>
      <slot/>
    </div>
  </div>
</template>

<script>
export default {
  name: "IconCircleContainer",
  props: {
    img: {
      type: String
    }
  },
}
</script>

<style scoped lang="scss">
  .icon-circle-container {
    background: var(--color-font-non-photo-blue);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .text-container {
      color: var(--color-font-delft);
      font-weight: 600;
    }
    .img-container {
      transform: rotate(90deg);
      & img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }



  @media (max-width: 3024px){
    .icon-circle-container {
      min-width: 66px;
      max-width: 66px;
      height: 66px;
      .text-container {
        font-size: 40px;
      }
      .img-container {
        width: 38px;
        height: 38px;
      }
    }
  }


  @media (max-width: 1920px) {
    .icon-circle-container {
      min-width: 56px;
      max-width: 56px;
      height: 56px;
      .text-container {
        font-size: 36px;
      }
      .img-container {
        width: 32px;
        height: 32px;
      }
    }

  }


  @media (max-width: 1600px) {
    .icon-circle-container {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      .text-container {
        font-size: 32px;
      }
      .img-container {
        width: 27px;
        height: 27px;
      }
    }
  }
  @media (max-height: 800px) {
    .icon-circle-container {
      min-width: 44px;
      max-width: 44px;
      height: 44px;
      .text-container {
        font-size: 24px;
      }
      .img-container {
        width: 27px;
        height: 27px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .icon-circle-container {
      min-width: 48px;
      max-width: 48px;
      height: 48px;
      .text-container {
        font-size: 32px;
      }
      .img-container {
        width: 27px;
        height: 27px;
      }
    }
  }

  @media (max-width: 1133px) {
    .icon-circle-container {
      min-width: 44px;
      max-width: 44px;
      height: 44px;
      .text-container {
        font-size: 24px;
      }
      .img-container {
        width: 27px;
        height: 27px;
      }
    }
  }
  @media (max-width: 900px) {
    .icon-circle-container {
      min-width: 36px;
      max-width: 36px;
      height: 36px;
      .text-container {
        font-size: 18px;
      }
      .img-container {
        width: 27px;
        height: 27px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .icon-circle-container {
      width: 42px;
      height: 42px;
      .img-container {
        width: 25px;
        height: 25px;
      }
    }
  }

  @media (min-width: 3025px) {
    .icon-circle-container {
      min-width: 114px;
      max-width: 114px;
      height: 114px;
      .text-container {
        font-size: 62px;
      }
      .img-container {
        width: 65px;
        height: 65px;
      }
    }
  }

</style>