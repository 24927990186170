<template>

  <div id="circle-container">
    <div class="circle-content"
         :class="{'active' : isActiveScreen}">
        <div
            id="particles-js"
        ></div>
    </div>

  </div>
</template>

<script>
export default {
  name: "CircleBackground",
  data() {
    return {
      innerHeight: 0,
      particles: [],
      isActiveScreen: false,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.initParticleJS()
    })
  },
  methods: {
    initParticleJS () {
      particlesJS('particles-js', {
        "particles": {
          "number": {
            "value": 150,
            "density": {
              "enable": true,
              "value_area": 800
            }
          },
          "color": {
            "value": '293E6B'
          },
          "shape": {
            // circle, edge, triangle, polygon, star, image
            "type": 'circle',
            "stroke": {
              "width": 0,
              "color": "#192231"
            },
            "polygon": {
              "nb_sides": 5
            }
          },
          "opacity": {
            "value": 0.7,
            "random": false,
            "anim": {
              "enable": false,
              "speed": 1,
              "opacity_min": 0.1,
              "sync": false
            }
          },
          "size": {
            "value": this.isMobile ? 3 : 7,
            "random": true,
            "anim": {
              "enable": false,
              "speed": 40,
              "size_min": 0.1,
              "sync": false
            }
          },
          "line_linked": {
            "enable": true,
            "distance": 150,
            "color": "#192231",
            "opacity": 0.4,
            "width": 1
          },
          "move": {
            "enable": true,
            "speed": 3,
            "direction": "none",
            "random": false,
            "straight": false,
            "out_mode": "out",
            "bounce": false,
            "attract": {
              "enable": false,
              "rotateX": 600,
              "rotateY": 1200
            }
          }
        },
        "interactivity": {
          "detect_on": "canvas",
          "events": {
            "onhover": {
              "enable": true,
              "mode": 'repulse'
            },
            "onresize": {
              "enable": true,
              "density_auto": true,
              "density_area": 400
            }
          },
          "modes": {
            "repulse": {
              "distance": 75,
              "duration": 1
            },
          },
        },
        "retina_detect": true
      });
      this.isActiveScreen = true;
    }
  }
}
</script>

<style scoped lang="scss">
#circle-container {
  opacity: .4;
  top: 0;
  left: 0;
  position: absolute;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  &:before {
    pointer-events: none;
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: linear-gradient(270deg, rgba(252, 253, 255, 0) 0, rgba(252, 253, 255) 100%);
  }
}
#particles-js {
  height: 100%;
}
.circle-content {
  height: 100%;
  transform: scale(1.1);
  opacity: 0;
  transition: all 1s ease;
  &.active {
    opacity: 1;
  }
}
</style>