<template>
  <div class="tariff-screen-wrapper"
       v-animateonscroll="{ enterClass: 'fade-in'}">
    <div class="tariff-screen-container main-content">
      <div class="top-container">
        <sub-title>
          {{ $t('tariffScreen.mainTitle') }}
        </sub-title>
        <mini-title-card>
          {{ $t('tariffScreen.description') }}
        </mini-title-card>
      </div>
      <div class="bottom-container">
        <card-tariff v-for="(item, index) in cardsTariff"
                     :class="{'active' : isVisible(index)}"
                     :key="index"
                     :item="item"/>
      </div>
    </div>
  </div>

</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import CardTariff from "@/components/entities/card/CardTariff.vue";

export default {
  name: "TariffScreen",
  components: {CardTariff, MiniTitleCard, SubTitle},
  data() {
    return {
      visibleIndexItem: -1,
    }
  },
  mounted() {
    this.showNextItem();
  },
  methods: {
    showNextItem() {
      if (this.visibleIndexItem < 2) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 500)
      }
    },
  },
  computed: {
    cardsTariff() {
      return this.$tm('tariffScreen.tariffCard').map((item, index) => ({
        ...item,
        id: index + 1
      }))
    }
  }
}
</script>

<style scoped lang="scss">
.tariff-screen-wrapper {
  background: var(--color-anti-flash-white);
}
  .tariff-screen-container {
    display: flex;
    align-items: center;

    .top-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .sub-title-container {
        text-transform: uppercase;
      }
      .card-mini-title-container {
        font-weight: 400;
        text-align: center;
        color: var(--color-font-nickel)
      }
    }
    .bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;

      & > * {
        flex-basis: 49%;
      }
    }
  }

  .card-tariff-container {
    opacity: 0;
    transition: opacity .5s ease;
    &.active {
      opacity: 1;
    }
  }


  @media (max-width: 3024px){
    .tariff-screen-container {
      row-gap: 60px;

      .top-container {
        max-width: 640px;
        row-gap: 60px;
      }
      .bottom-container {
        max-width: 1580px;
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }


  @media (max-width: 1920px) {
    .tariff-screen-container {
      row-gap: 44px;

      .top-container {
        max-width: 640px;
        row-gap: 44px;
      }
      .bottom-container {
        max-width: 1280px;
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }


  @media (max-width: 1600px) {
    .tariff-screen-container {
      row-gap: 40px;

      .top-container {
        max-width: 640px;
        row-gap: 40px;
      }
      .bottom-container {
        max-width: 780px;
        column-gap: 20px;
        row-gap: 20px;

        & > * {
          flex-basis: 48%;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .tariff-screen-container {
      row-gap: 32px;
      .top-container {
        max-width: 484px;
        row-gap: 32px;
      }
      .bottom-container {
        max-width: 820px;
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .tariff-screen-container {
      row-gap: 40px;

      .top-container {
        max-width: 640px;
        row-gap: 40px;
      }
      .bottom-container {
        max-width: 780px;
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }

  @media (max-width: 1133px) {
    .tariff-screen-container {
      row-gap: 32px;
      .top-container {
        max-width: 484px;
        row-gap: 32px;
      }
      .bottom-container {
        max-width: 820px;
        column-gap: 20px;
        row-gap: 20px;
      }
    }
  }

  @media (max-width: 769px) {
    .tariff-screen-container {
      row-gap: 16px;
      .top-container {
        align-items: flex-start;
        max-width: 100%;
        row-gap: 16px;
        .card-mini-title-container {
          text-align: left;
        }
      }
      .bottom-container {
        flex-direction: column;
        max-width: 100%;
        column-gap: 16px;
        row-gap: 16px;
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .tariff-screen-container {
      row-gap: 80px;

      .top-container {
        max-width: 1080px;
        row-gap: 80px;
      }
      .bottom-container {
        max-width: 2200px;
        column-gap: 40px;
        row-gap: 30px;
      }
    }
  }

</style>