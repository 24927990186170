<template>
  <div class="calculator-screen-text-container">
    <div class="info-container" v-animateonscroll="{ enterClass: 'fade-in'}">
      <div class="img-container people" v-if="!activeMiniMobile">
        <img :src="getUrlImg('people')" alt="bg">
      </div>
      <div class="decoration-container" v-else>
        <img :src="getUrlImg('decoration-mini')" alt="decoration">
      </div>
      <div class="right-container">
        <color-text :active-color-text="infoText[0].colorTitle" :text-info="infoText[0].mainTitle"/>
        <main-title-card>
           {{ infoText[0].subTitle }}
        </main-title-card>
        <mini-title-card>
          {{ infoText[0].mainParagraph }}
        </mini-title-card>
      </div>
    </div>
    <div class="info-container reverse" v-animateonscroll="{ enterClass: 'fade-in'}">
      <div class="left-container">
        <sub-title>
          {{ infoText[1].mainTitle }}
        </sub-title>
        <mini-title-card v-if="!isMobile">
          {{ infoText[1].mainParagraph }}
        </mini-title-card>
        <mini-title-card v-else>
          {{ infoText[1].mainParagraphMobile }}
        </mini-title-card>
        <div class="actions-container">
          <main-button @click="openModalPartner">
            {{ infoText[1].textButton }}
          </main-button>
          <mini-title-card class="button-title" @click="openMainPage">
            {{ infoText[1].textTitle }}
          </mini-title-card>
        </div>
      </div>
      <div class="right-container">
        <full-logo/>
      </div>
    </div>
  </div>
</template>

<script>
import ColorText from "@/components/shared/ColorText.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import FullLogo from "@/components/entities/FullLogo.vue";
import {mapState} from "vuex";

export default {
  name: "CalculatorScreenText",
  components: {FullLogo, MainButton, SubTitle, MiniTitleCard, MainTitleCard, ColorText},
  computed: {
    ...mapState(['activeMiniMobile']),
    infoText() {
      return this.$tm('calculatorScreenText.infoText')
    }
  },
  methods: {
    openMainPage() {
      window.open('https://element-soft.com/', '_blank')
    }
  }
}
</script>

<style scoped lang="scss">

.card-mini-title-container {
  font-weight: 400 !important;
  color: var(--color-font-nickel);
  white-space: pre-wrap;
}
.img-container {
  width: 100%;
height: 100%;
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
  .calculator-screen-text-container {
    z-index: 1;
    position: relative;
    display: flex;
    flex-direction: column;

    .info-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      &.reverse {
        position: relative;
        z-index: 1;
        .left-container {
          width: 100%;
          display: flex;
          flex-direction: column;

          .actions-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            .card-mini-title-container {
              font-weight: 600 !important;
            }
          }
        }

        .right-container {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        }
      }
      .img-container {
        overflow: hidden;
      }
      .right-container {
        .card-main-title-container {
          color: var(--color-font-delft);
        }
      }
    }
  }

  .sub-title-container {
    text-transform: uppercase;
  }


@media (max-width: 3024px) and (min-width: 1921px){
  .calculator-screen-text-container {
    row-gap: 180px;

    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 780px;
          row-gap: 32px;
          .card-mini-title-container {
            font-size: 24px;
          }
          .actions-container {
            column-gap: 40px;
            .main-button-container {
              width: 270px;
            }
          }
        }

        .right-container {
          max-width: 784px;
        }
      }
      .img-container {
        &.people {
          max-width: 960px;
          max-height: 492px;
        }
      }
      .right-container {
        max-width: 780px;
        .color-text-container {
          margin-bottom: 40px;
        }
        .card-main-title-container {
          margin-bottom: 24px;
        }
        .card-mini-title-container {
          font-size: 24px;
          max-width: 417px;
        }
      }
    }
  }

}


@media (max-width: 1920px) {
  .calculator-screen-text-container {
    row-gap: 160px;

    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 626px;
          row-gap: 24px;

          .actions-container {
            column-gap: 40px;
            .main-button-container {
              width: 287px;
            }
          }
        }

        .right-container {
          max-width: 760px;
        }
      }
      .img-container {
        &.people {
          max-width: 820px;
          max-height: 420px;
        }
      }
      .right-container {
        max-width: 640px;
        .color-text-container {
          margin-bottom: 40px;
        }
        .card-main-title-container {
          margin-bottom: 24px;
        }
        .card-mini-title-container {
          max-width: 417px;
        }
      }
    }
  }

}


@media (max-width: 1600px) {
  .calculator-screen-text-container {
    row-gap: 120px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 18px;
    }
    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 582px;
          row-gap: 24px;

          .actions-container {
            column-gap: 32px;
            .card-mini-title-container {
              font-size: 16px;
            }
            .main-button-container {
              width: 248px;
            }
          }
        }

        .right-container {
          max-width: 600px;
        }
      }
      .img-container {
        &.people {
          max-width: 609px;
          height: 420px;

          & img {
            object-fit: cover;
          }
        }
      }
      .right-container {
        max-width: 442px;
        .color-text-container {
          margin-bottom: 32px;
        }
        .card-main-title-container {
          margin-bottom: 16px;
        }
        .card-mini-title-container {
          max-width: 417px;
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .calculator-screen-text-container {
    row-gap: 80px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 16px;
    }
    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 485px;
          row-gap: 24px;

          .actions-container {
            column-gap: 32px;
            .card-mini-title-container {
              font-size: 14px;
            }
            .main-button-container {
              width: 206px;
            }
          }
        }

        .right-container {
          max-width: 443px;
        }
      }
      .img-container {
        &.people {
          max-width: 500px;
        }
      }
      .right-container {
        max-width: 387px;
        .color-text-container {
          margin-bottom: 32px;
        }
        .card-main-title-container {
          margin-bottom: 16px;
        }
        .card-mini-title-container {
          max-width: inherit;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .calculator-screen-text-container {
    row-gap: 120px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 18px;
    }
    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 582px;
          row-gap: 24px;

          .actions-container {
            column-gap: 32px;
            .card-mini-title-container {
              font-size: 16px;
            }
            .main-button-container {
              width: 248px;
            }
          }
        }

        .right-container {
          max-width: 600px;
        }
      }
      .img-container {
        &.people {
          max-width: 609px;
        }
      }
      .right-container {
        max-width: 442px;
        .color-text-container {
          margin-bottom: 32px;
        }
        .card-main-title-container {
          margin-bottom: 16px;
        }
        .card-mini-title-container {
          max-width: 417px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .calculator-screen-text-container {
    row-gap: 80px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 16px;
    }
    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 485px;
          row-gap: 24px;

          .actions-container {
            column-gap: 32px;
            .main-button-container {
              width: 206px;
            }

            .card-mini-title-container {
              font-size: 14px;
            }
          }
        }

        .right-container {
          max-width: 443px;
        }
      }
      .img-container {
        &.people {
          max-width: 500px;
        }
      }
      .right-container {
        max-width: 387px;
        .color-text-container {
          margin-bottom: 32px;
        }
        .card-main-title-container {
          margin-bottom: 16px;
        }
        .card-mini-title-container {
          max-width: inherit;
        }
      }
    }
  }
}

@media (max-width: 769px) {
  .decoration-container {
    width: 75%;
    margin-left: -22px;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .calculator-screen-text-container {
    row-gap: 48px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 14px;
    }
    .info-container {
      column-gap: 12px;
      padding-left: 0;
      &.reverse {
        padding-left: 0;
        .left-container {
          max-width: 100%;
          row-gap: 16px;

          .actions-container {
            align-items: center;
            flex-direction: column;
            row-gap: 32px;
            .main-button-container {
              width: 180px;
              margin-top: 16px;
            }

            .card-mini-title-container {
              font-size: 12px;
            }
          }
        }

        .right-container {
          display: none;
          max-width: 443px;
        }
      }
      .img-container {
        display: none;
        &.people {
          max-width: 500px;
        }
      }
      .right-container {
        padding-top: 32px;
        max-width: 100%;
        .color-text-container {
          margin-bottom: 32px;
          column-gap: 1px;
        }
        .card-main-title-container {
          margin-bottom: 8px;
        }
        .card-mini-title-container {
          max-width: inherit;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  .calculator-screen-text-container {
    row-gap: 48px;
    .card-mini-title-container {
      white-space: pre-wrap;
      font-size: 14px;
    }
    .info-container {
      column-gap: 12px;
      padding-left: 0;
      &.reverse {
        padding-left: 0;
        .left-container {
          max-width: 100%;
          row-gap: 16px;

          .actions-container {
            align-items: center;
            flex-direction: column;
            row-gap: 32px;
            .main-button-container {
              width: 180px;
              margin-top: 16px;
            }

            .card-mini-title-container {
              font-size: 12px;
            }
          }
        }

        .right-container {
          display: none;
          max-width: 443px;
        }
      }
      .img-container {
        display: none;
        &.people {
          max-width: 500px;
        }
      }
      .right-container {
        padding-top: 40px;
        max-width: 100%;
        .color-text-container {
          margin-bottom: 32px;
        }
        .card-main-title-container {
          margin-bottom: 8px;
        }
        .card-mini-title-container {
          max-width: inherit;
        }
      }
    }
  }
}
@media (max-width: 375px) {
  .decoration-container {
    width: 25%;
    margin-left: -24px;
    & img {
      width: auto;
      height: auto;
    }
  }
}
@media (max-width: 320px) {
  .decoration-container {
    width: 10%;
    margin-left: -24px;
    & img {
      width: auto;
      height: auto;
    }
  }
}

@media (min-width: 3025px) {
  .calculator-screen-text-container {
    row-gap: 160px;

    .info-container {
      column-gap: 12px;
      &.reverse {
        .left-container {
          max-width: 1360px;
          row-gap: 40px;

          .actions-container {
            column-gap: 72px;
            .main-button-container {
              width: 554px;
            }
          }
        }

        .right-container {
          max-width: 1360px;
        }
      }
      .img-container {
        &.people {
          max-width: 1640px;
          max-height: 840px;
        }
      }
      .right-container {
        max-width: 1176px;
        .color-text-container {
          margin-bottom: 60px;
        }
        .card-main-title-container {
          margin-bottom: 36px;
        }
        .card-mini-title-container {
          max-width: 560px;
        }
      }
    }
  }
}

</style>