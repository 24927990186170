<template>
  <div class="input-main-title-container">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "MainTitleInput"
}
</script>

<style scoped lang="scss">
  .input-main-title-container {
    line-height: 120%;
    color: var(--color-font-nickel);
  }



  @media (max-width: 3024px){
    .input-main-title-container {
      font-size: 24px;
    }
  }


  @media (max-width: 1920px) {
    .input-main-title-container {
      font-size: 16px;
    }

  }


  @media (max-width: 1600px) {
    .input-main-title-container {
      font-size: 16px;
    }
  }
  @media (max-height: 800px) {
    .input-main-title-container {
      font-size: 14px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .input-main-title-container {
      font-size: 16px;
    }
  }

  @media (max-width: 1133px) {
    .input-main-title-container {
      font-size: 14px;
    }
  }

  @media (max-width: 900px) {
    .input-main-title-container {
      font-size: 12px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {

  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .input-main-title-container {
      font-size: 11px;
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    .input-main-title-container {
      font-size: 12px;
    }
  }


  @media (max-width: 320px) {
    .input-main-title-container {
      font-size: 9px;
    }
  }

  @media (min-width: 3025px) {
    .input-main-title-container {
      font-size: 36px;
    }
  }

</style>