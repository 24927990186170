<template>
  <div class="card-implementation-container">
    <icon-circle-container :img="item.icon"/>
    <div class="info-container">
      <main-title-card>
         {{ item.title }}
      </main-title-card>
      <main-paragraph-card>
        {{ item.description }}
      </main-paragraph-card>
    </div>
  </div>
</template>

<script>
import IconCircleContainer from "@/components/shared/IconCircleContainer.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";

export default {
  name: "CardImplementation",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {MainParagraphCard, MainTitleCard, IconCircleContainer}
}
</script>

<style scoped lang="scss">
  .card-implementation-container {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    &.reverse {
      flex-direction: column-reverse;
      .icon-circle-container {
        bottom: -25%;
        top: auto;
      }
    }
    border-left-style: dashed;
    border-left-color: var(--color-font-non-photo-blue);

    .icon-circle-container {
      top: -25%;
      left: -28px;
      position: absolute;
    }

    .info-container {
      position: absolute;
      width: 100%;
      display: flex;
      flex-direction: column;

      .card-main-title-container {
        text-transform: uppercase;
        color: var(--color-font-non-photo-blue)
      }
      .card-main-paragraph-container {
        color: var(--color-sub-main)
      }
    }
  }



  @media (max-width: 3024px) and (min-width: 1921px){
    .card-implementation-container {
      height: 200px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -33px;
      }
      .card-main-paragraph-container {
        font-size: 22px;
      }


      .info-container {
        padding-left: 33px;
        row-gap: 16px;
      }
    }
  }


  @media (max-width: 1920px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;

      .icon-circle-container {
        left: -28px;
      }

      .info-container {
        padding-left: 24px;
        row-gap: 16px;
      }
    }
  }


  @media (max-width: 1600px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -24px;
      }
      .card-main-paragraph-container {
        font-size: 16px;
      }
      .info-container {
        padding-left: 14px;
        row-gap: 12px;
      }
    }
  }
  @media (max-height: 800px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -24px;
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
      .info-container {
        padding-left: 14px;
        row-gap: 12px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -24px;
      }
      .card-main-paragraph-container {
        font-size: 16px;
      }
      .info-container {
        padding-left: 14px;
        row-gap: 12px;
      }
    }
  }

  @media (max-width: 1133px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -24px;
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
      .info-container {
        padding-left: 14px;
        row-gap: 12px;
      }
    }
  }


  @media (max-width: 1024px) {
    .card-implementation-container {
      height: 160px;
      border-left-width: 1px;
      &.reverse {
        .icon-circle-container {
          bottom: -18%;
        }
      }
      .icon-circle-container {
        top: -18%;
        left: -24px;
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
      .info-container {
        padding-left: 14px;
        row-gap: 12px;
      }
    }
  }

  @media (max-width: 769px) {
    .card-implementation-container {
      width: 100%;
      flex-direction: row;
      height: 160px;
      border-left-width: 0;
      align-items: center;
      &.reverse {
        flex-direction: row;
        .icon-circle-container {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .icon-circle-container {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
      .info-container {
        left: 0;
        max-width: 286px;
        padding-left: 0;
        margin-left: 68px;
        row-gap: 12px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-implementation-container {
      width: 100%;
      flex-direction: row;
      height: 160px;
      border-left-width: 0;
      align-items: center;
      &.reverse {
        flex-direction: row;
        .icon-circle-container {
          top: 50%;
          left: 0;
          transform: translateY(-50%);
        }
      }
      .icon-circle-container {
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
      .info-container {
        left: 0;
        max-width: 286px;
        padding-left: 0;
        margin-left: 68px;
        row-gap: 12px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .card-implementation-container {
      .info-container {
        max-width: 271px;
      }
    }
  }


  @media (max-width: 320px) {
    .card-implementation-container {
      width: 100%;
      flex-direction: row;
      height: 160px;
      border-left-width: 0;
      align-items: center;
      .card-main-paragraph-container {
        font-size: 12px;
      }
      .info-container {
        left: 0;
        max-width: 230px;
        padding-left: 0;
        margin-left: 56px;
        row-gap: 12px;
      }
    }
  }

  @media (min-width: 3025px) {
    .card-implementation-container {
      height: 280px;
      border-left-width: 2px;
      &.reverse {
        .icon-circle-container {
          bottom: -30%;
        }
      }
      .icon-circle-container {
        top: -30%;
        left: -57px;
      }
      .info-container {
        padding-left: 58px;
        row-gap: 16px;
      }
    }
  }

</style>