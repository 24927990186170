<template>
  <div class="title-input-container">
    <main-title-input>
      {{ item.title }}
      <span v-if="item?.required">
        *
      </span>
    </main-title-input>
    <input-main :info="item"
                :name-block="nameBlock"
                @changeCountryCode="value => $emit('changeCountryCode', value)"
                @changeTextInput="(value, key) => $emit('changeTextInput', value, key)"/>
  </div>
</template>

<script>
import MainTitleInput from "@/components/shared/text/input/MainTitleInput.vue";
import InputMain from "@/components/shared/input/InputMain.vue";

export default {
  name: "TitleInput",
  components: {InputMain, MainTitleInput},
  props: {
    item: {
      type: Object,
      required: true
    },
    nameBlock: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
  .title-input-container {
    display: flex;
    flex-direction: column;
    .input-main-title-container {
      & span {
        color: var(--color-red)
      }
    }
  }


  @media (max-width: 3024px){
    .title-input-container {
      row-gap: 10px;
    }

  }


  @media (max-width: 1920px) {
    .title-input-container {
      row-gap: 8px;
    }
  }
  @media (max-width: 1600px) {
    .title-input-container {
      row-gap: 8px;
    }
  }
  @media (max-height: 800px) {
    .title-input-container {
      row-gap: 6px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .title-input-container {
      row-gap: 8px;
    }
  }

  @media (max-width: 1133px) {
    .title-input-container {
      row-gap: 6px;
    }
  }


  @media (max-width: 900px) {
    .title-input-container {
      row-gap: 4px;
    }
  }



  @media (max-width: 320px) {
    .title-input-container {
      row-gap: 2px;
    }
  }

  @media (min-width: 3025px) {
    .title-input-container {
      row-gap: 16px;
    }
  }

</style>