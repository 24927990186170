<template>
  <div class="decoration-screen-container">
    <div class="decoration-screen-content main-content"
         v-animateonscroll="{ enterClass: 'fade-in'}"
         @animationend="showAnimation">
      <full-logo/>
      <mini-title>
        {{ $t('decorationScreen.mainTitle') }}
      </mini-title>
    </div>
    <circle-background v-if="isActiveAnimation"/>
  </div>
</template>

<script>
import MiniTitle from "@/components/shared/text/MiniTitle.vue";
import FullLogo from "@/components/entities/FullLogo.vue";
import CircleBackground from "@/components/entities/CircleBackground.vue";

export default {
  name: "DecorationScreen",
  components: {CircleBackground, FullLogo, MiniTitle},
  data() {
    return {
      isActiveAnimation: false,
    }
  },
  methods: {
    showAnimation() {
      this.isActiveAnimation = true;
    }
  }

}
</script>

<style scoped lang="scss">
  .decoration-screen-container {
    overflow: hidden;
    position: relative;

    .decoration-screen-content {
      pointer-events: none;
      position: relative;
      z-index: 3;
      align-items: center;

      .mini-title-container {
        text-transform: uppercase;
        text-align: center;
      }
    }
  }



  @media (max-width: 3024px){
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 180px 16px;
        max-width: 1292px;
        row-gap: 80px;
      }
    }
  }


  @media (max-width: 1920px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 160px 16px;
        max-width: 1050px;
        row-gap: 60px;
      }
    }
  }


  @media (max-width: 1600px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 168px 16px;
        max-width: 1012px;
        row-gap: 36px;
      }
    }
  }
  @media (max-height: 800px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 177px 16px;
        max-width: 783px;
        row-gap: 36px;

        .mini-title-container {
          font-size: 24px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 168px 16px;
        max-width: 1012px;
        row-gap: 36px;
      }
    }
  }

  @media (max-width: 1133px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 177px 16px;
        max-width: 783px;
        row-gap: 36px;

        .mini-title-container {
          font-size: 24px;
        }
      }
    }
  }

  @media (max-width: 769px) {
    .decoration-screen-container {
      .decoration-screen-content {
        padding: 60px 16px;
        max-width: 320px;
        row-gap: 15px;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .decoration-screen-container {
      .decoration-screen-content {
        padding: 60px 16px;
        max-width: 320px;
        row-gap: 15px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .decoration-screen-container {

      .decoration-screen-content {
        padding: 260px 16px;
        max-width: 2232px;
        row-gap: 120px;
      }
    }
  }

</style>