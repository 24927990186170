<template>
  <div class="what-is-video-screen-container">
    <div class="what-is-video-screen-content main-content" v-animateonscroll="{ enterClass: 'fade-in'}">
      <main-title>
        {{ $t('whatIsVideoScreen.mainTitle') }}
      </main-title>
      <play-button/>
      <main-title class="color">
        {{ mainTitleSecond }}
      </main-title>
    </div>
  </div>
</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import PlayButton from "@/components/shared/buttons/PlayButton.vue";

export default {
  name: "WhatIsVideoScreen",
  components: {PlayButton, MainTitle},
  data() {
    return {
      mainTitleSecond: 'quanta?'
    }
  }
}
</script>

<style scoped lang="scss">
  .what-is-video-screen-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-font-alice-blue);
    height: var(--calc-height);
    .what-is-video-screen-content {
      align-items: center;
      justify-content: center;
      flex-direction: row;
      .main-title-container {
        &.color {
          color: var(--color-font-delft);
        }
      }
    }
  }


  @media (max-width: 3024px){
    .what-is-video-screen-container {
      .what-is-video-screen-content {
        column-gap: 20px;
      }
    }
  }


  @media (max-width: 1920px) {
    .what-is-video-screen-container {
      .what-is-video-screen-content {
        column-gap: 20px;
      }
    }
  }


  @media (max-width: 1600px) {
    .what-is-video-screen-container {
      .what-is-video-screen-content {
        column-gap: 16px;
      }
    }
  }

  @media (max-width: 900px) {
    .what-is-video-screen-container {
      height: auto;
      .what-is-video-screen-content {
        padding: 120px 0;
        column-gap: 8px;
        .main-title-container {
          font-size: 18px !important;
        }
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .what-is-video-screen-container {
      height: auto;
      .what-is-video-screen-content {
        padding: 120px 0;
        column-gap: 8px;
        .main-title-container {
          font-size: 18px !important;
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .what-is-video-screen-container {
      .what-is-video-screen-content {
        column-gap: 20px;
      }
    }
  }

</style>