<template>
  <div class="process-screen-container">
    <div class="process-screen-content">
      <navigation-process :active-direction="activeDirection"
                          @changeDirection="changeDirection"/>
      <div class="main-title-container">
        {{ $t('processScreen.mainTitle') }}
      </div>
        <div class="swiper-button-team-top-process arrow-container top" :class="{'not-active' : !isActiveNavigation}">
          <img :src="getUrlIcons('arrow-top')" alt="arrow">
        </div>

      <transition name="fade-opacity-lazy" mode="out-in">
        <swiper @slide-change="onSwiperMain"
                ref="swiperScroll"
                :key="activeDirection"
                class="process-screen-items-container"
                @swiper="changeSettingSwiper"
                :allowTouchMove="true"
                :modules="modules"
                :breakpoints="
                    {
                      0: {
                        spaceBetween: 20
                      },
                      380: {
                        spaceBetween: 24
                      },
                      1000: {
                        spaceBetween: 32
                      },
                      1600: {
                        spaceBetween: 40
                      },
                      1921: {
                        spaceBetween: 48,
                      },
                      3025: {
                        spaceBetween: 64
                      }
                 }"
                :navigation = "{
                  nextEl: `.swiper-button-team-bottom-process`,
                  prevEl: `.swiper-button-team-top-process`,
                  hidden: 'swiper-button-hidden'
                }"
                :scrollbar="{
                  draggable: true,
                  dragClass: 'swiper-scrollbar-drag-custom'
                 }"
                :space-between="40"
                :autoHeight="true"
                :mousewheel="true"
                :slides-per-view="'auto'"
                :allow-touch-move="true"
                :speed="500"
                direction="vertical">
          <swiper-slide  v-for="(item, index) in filterProcess"
                         :key="item.id">
            <card-process @changeStatus="changeStatus"
                          v-if="item.type !== 'empty'"
                          :item="item"/>
            <div class="empty-card" v-else></div>
          </swiper-slide>
        </swiper>
      </transition>
        <div class="swiper-button-team-bottom-process arrow-container bottom" :class="{'not-active' : !isActiveNavigation}">
          <img :src="getUrlIcons('arrow-bottom')" alt="arrow">
        </div>
      </div>
    <div class="bg-container">
      <img :src="getUrlProcess('process.webp')" alt="bg">
    </div>
  </div>
</template>

<script>
import NavigationProcess from "@/components/entities/process/NavigationProcess.vue";
import CardProcess from "@/components/entities/process/CardProcess.vue";
import {Mousewheel, Navigation, Scrollbar} from "swiper";

export default {
  name: "ProcessScreen",
  components: {CardProcess, NavigationProcess, Scrollbar},
  data() {
    return {
      isActiveNavigation: false,
      scrollSwiper: '',
      activeIndex: 0,
      modules: [Mousewheel, Navigation, Scrollbar],
      activeDirection: 2,
      processActive: [
        {
          id: 0,
          value: false,
          type: 'avatar',
          time: new Date(),
          direction: 'output',
        },
        {
          id: 1,
          value: false,
          type: 'avatar',
          time: new Date(),
          direction: 'input',
        },
        {
          id: 2,
          value: false,
          type: 'car',
          time: new Date(),
          direction: 'output',
        },
        {
          id: 3,
          value: false,
          type: 'avatar',
          time: new Date(),
          direction: 'output',
        },
        {
          id: 4,
          value: false,
          type: 'avatar',
          time: new Date(),
          direction: 'input',
        },
        {
          id: 5,
          value: false,
          type: 'car',
          time: new Date(),
          direction: 'output',
        }
      ]
    }
  },
  computed: {
    processCard() {
      return [
        {
          name: 'Иванов Иван Иванович',
          zone: 'выход из зоны “Въезд”',
          floor: '2 этаж',
          location: 'Левашово'
        },
        {
          name: 'Иванов Иван Иванович',
          zone: 'вход в зону “Въезд”',
          location: 'Левашово'
        },
        {
          name: 'В 415 АА 198',
          zone: 'выход из зоны “Въезд”',
          floor: '1 этаж',
          location: 'Левашово'
        },
        {
          name: 'Иванов Иван Иванович',
          zone: 'выход из зоны “Въезд”',
          floor: '2 этаж',
          location: 'Левашово'
        },
        {
          name: 'Иванов Иван Иванович',
          zone: 'вход в зону “Въезд”',
          location: 'Левашово'
        },
        {
          name: 'В 415 АА 198',
          zone: 'выход из зоны “Въезд”',
          floor: '1 этаж',
          location: 'Левашово'
        }
      ].map((item, index) => ({
        ...item,
        ...this.processActive[index]
      }))
    },
    filterProcess() {
      let cards = this.processCard.filter(item => {
        switch (this.activeDirection) {
          case 0:
            return item.direction === 'input';
          case 1:
            return item.direction === 'output';
          case 2:
            return true;
        }
      })
      return [...cards, {type: 'empty'}]
    }
  },
  methods: {
    changeStatus(process) {
      let index = this.processActive.findIndex(itemProcess => itemProcess.id === process.id)
      if(index !== -1) {
        this.processActive[index].active = !this.processActive[index].active
      }
    },
    changeDirection(index) {
      this.activeDirection = index
      this.scrollSwiper.update();
    },
    onSwiperMain(swiper) {
      this.activeIndex = swiper.activeIndex;
      this.scrollSwiper = swiper;
      this.isActiveNavigation = swiper.snapGrid.length > 1
    },

    changeSettingSwiper(swiper) {
      this.scrollSwiper = swiper
      this.isActiveNavigation = swiper.snapGrid.length > 1
    },
  }
}
</script>

<style scoped lang="scss">
  .process-screen-container {
    overflow: hidden;
    position: relative;
    height: var(--calc-height);
    width: 100%;

    .process-screen-content {
      display: flex;
      flex-direction: column;
      background: rgba(var(--color-rgba-squid), .3);
      height: 100%;
      margin-left: auto;
      position: relative;
      z-index: 1;

      .main-title-container {
        color: var(--color-font-white);
      }

      .process-screen-items-container {
        position: static;
        margin-right: 0;
        width: 100%;
        height: 100%;
      }

      &:after {
        pointer-events: none;
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 20%;
        z-index: 2;
        background: linear-gradient(180deg, rgba(var(--color-rgba-squid), 0) 0, #22262E 100%);
      }
    }

    .bg-container {
      z-index: 0;
      top: 0;
      width: 100%;
      height: 100%;
      filter: blur(10px);
      position: absolute;
      & img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .swiper-slide {
    &:nth-last-child(2) {
    .card-process-container {
        &:after {
          display: none;
        }
      }
    }
  }

  .arrow-container {
    cursor: pointer;
    margin: 0 !important;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    opacity: .6;
    transition: all .3s ease;

    &.not-active {
      pointer-events: none;
      opacity: 0;
    }

    &.hover {
      opacity: 1;
    }

    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    &.top {
      top: 0;
    }
    &.bottom {
      bottom: 0;
    }
  }

  .card-process-container {
    position: relative;
    padding: 0 0 40px;
    &:after {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      background: rgba(220, 228, 248, .16);
    }
  }



  @media (max-width: 3024px){
    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 40px 40px;
        backdrop-filter: blur(50px);
        max-width: 584px;

        .main-title-container {
          font-size: 32px;
          margin: 47px 0 45px;
        }

        & > * {
          margin-right: 64px;
        }

        .process-screen-items-container {
          padding-right: 64px;
        }
      }
    }

    .empty-card {
      height: 256px;
    }

    .arrow-container {
      right: 6px;
      width: 40px;
      height: 40px;
      padding: 0;
      &.top {
        top: 16px;
      }
      &.bottom {
        bottom: 16px;
      }
    }

    .card-process-container {
      padding: 0 0 48px;
      &:after {
        height: 2px;
      }
    }
  }


  @media (max-width: 1920px) {
    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 40px 30px;
        backdrop-filter: blur(50px);
        max-width: 451px;

        .main-title-container {
          font-size: 28px;
          margin: 20px 0 50px;
        }

        & > * {
          margin-right: 60px;
        }

        .process-screen-items-container {
          padding-right: 60px;
        }
      }
    }

    .empty-card {
      height: 215px;
    }

    .arrow-container {
      right: 4px;
      width: 44px;
      height: 44px;
      padding: 8px;

      &.top {
        top: 14px;
      }
      &.bottom {
        bottom: 14px;
      }
    }

    .card-process-container {
      padding: 0 0 40px;
      &:after {
        height: 2px;
      }
    }
  }


  @media (max-width: 1600px) {
    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 30px 30px;
        backdrop-filter: blur(50px);
        max-width: 393px;

        .main-title-container {
          font-size: 24px;
          margin: 22.5px 0 34.5px;
        }

        & > * {
          margin-right: 50px;
        }

        .process-screen-items-container {
          padding-right: 50px;
        }
      }
    }

    .empty-card {
      height: 172px;
    }

    .arrow-container {
      right: 6px;
      width: 24px;
      height: 24px;
      padding: 0;

      &.top {
        top: 10px;
      }
      &.bottom {
        bottom: 10px;
      }
    }

    .card-process-container {
      padding: 0 0 32px;
      &:after {
        height: 2px;
      }
    }
  }
  @media (max-height: 800px) {
    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 30px 30px;
        backdrop-filter: blur(50px);
        max-width: 377px;

        .main-title-container {
          font-size: 24px;
          margin: 20px 0 32px;
        }

        & > * {
          margin-right: 59px;
        }

        .process-screen-items-container {
          padding-right: 34px;
        }
      }
    }

    .empty-card {
      height: 146px;
    }

    .arrow-container {
      right: 6px;
      width: 20px;
      height: 20px;
      padding: 0;

      &.top {
        top: 6px;
      }
      &.bottom {
        bottom: 6px;
      }
    }

    .card-process-container {
      padding: 0 0 32px;
      &:after {
        height: 2px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 30px 30px;
        backdrop-filter: blur(50px);
        max-width: 393px;

        .main-title-container {
          font-size: 24px;
          margin: 22.5px 0 34.5px;
        }

        & > * {
          margin-right: 50px;
        }

        .process-screen-items-container {
          padding-right: 50px;
        }
      }
    }

    .empty-card {
      height: 172px;
    }

    .arrow-container {
      right: 6px;
      width: 24px;
      height: 24px;
      padding: 0;

      &.top {
        top: 10px;
      }
      &.bottom {
        bottom: 10px;
      }
    }

    .card-process-container {
      padding: 0 0 32px;
      &:after {
        height: 2px;
      }
    }
  }

  @media (max-width: 1133px) {

    .process-screen-container {
      .process-screen-content {
        padding: 40px 0 30px 30px;
        backdrop-filter: blur(50px);
        max-width: 377px;

        .main-title-container {
          font-size: 24px;
          margin: 20px 0 32px;
        }

        & > * {
          margin-right: 59px;
        }

        .process-screen-items-container {
          padding-right: 34px;
        }
      }
    }

    .empty-card {
      height: 146px;
    }

    .arrow-container {
      right: 6px;
      width: 20px;
      height: 20px;
      padding: 0;

      &.top {
        top: 6px;
      }
      &.bottom {
        bottom: 6px;
      }
    }

    .card-process-container {
      padding: 0 0 32px;
      &:after {
        height: 2px;
      }
    }
  }

  @media (max-width: 769px) {
    //14
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {

    .process-screen-container {
      .process-screen-content {
        padding: 60px 0 60px 50px;
        backdrop-filter: blur(50px);
        max-width: 772px;

        .main-title-container {
          font-size: 44px;
          margin: 40px 0 38px;
        }

        & > * {
          margin-right: 78px;
        }

        .process-screen-items-container {
          padding-right: 78px;
        }
      }
    }

    .empty-card {
      height: 330px;
    }

    .arrow-container {
      right: 6px;
      width: 54px;
      height: 54px;
      padding: 6px;
      &.top {
        top: 30px;
      }
      &.bottom {
        bottom: 20px;
      }
    }

    .card-process-container {
      padding: 0 0 64px;
      &:after {
        height: 2px;
      }
    }
  }

  .disabled {
    opacity: .6;
    pointer-events: none;
  }
</style>