<template>
  <div class="about-title-screen-container">
    <color-text :active-color-text="$t('aboutScreenTitle.colorText')"
                :text-info="$t('aboutScreenTitle.mainTitle')"
                v-animateonscroll="{ enterClass: 'fade-in'}"/>
    <div class="img-container"
         v-if="!isMobile"
         v-animateonscroll="{ enterClass: 'fade-in-right'}">
      <img :src="getUrlImg(img)" alt="decoration">
    </div>
  </div>
</template>

<script>
import ColorText from "@/components/shared/ColorText.vue";

export default {
  name: "AboutScreenTitle",
  components: {ColorText},
  data() {
    return {
      img: 'about-decoration'
    }
  }
}
</script>

<style scoped lang="scss">
.about-title-screen-container {
  align-items: center;
  position: relative;
  display: flex;
  flex-direction: row;
  z-index: 3;
  justify-content: space-between;
  .img-container {
    width: 100%;
    height: 100%;

    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}



@media (max-width: 3024px){
  .about-title-screen-container {
    .color-text-container {
      max-width: 940px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 780px;
      max-height: 450px;
    }
  }
}


@media (max-width: 1920px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 820px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 638px;
      max-height: 366px;
    }
  }
}


@media (max-width: 1600px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 579px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 417px;
      max-height: 241px;
    }
  }
}
@media (max-height: 800px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 484px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 346px;
      max-height: 200px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 579px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 417px;
      max-height: 241px;
    }
  }
}

@media (max-width: 1133px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 484px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 346px;
      max-height: 200px;
    }
  }
}

@media (max-width: 769px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 345px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .about-title-screen-container {
    .color-text-container {
      max-width: 1950px;
    }
    .img-container {
      margin-right: -16px;
      max-width: 1080px;
      max-height: 623px;
    }
  }
}

</style>