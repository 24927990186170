<template>
  <div class="navigation-process-container">
    <div class="navigation-process-content"
         @click="changeDirection(index)"
         :class="{'active' : activeDirection === index}"
         v-for="(item, index) in $tm('processScreen.navigation')"
         :key="index">
      <span>
      {{ item }}
      </span>
      <div class="bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NavigationProcess",
  props: {
    activeDirection: {
      type: Number,
      required: true
    }
  },
  methods: {
    changeDirection(index) {
      this.$emit('changeDirection', index)
    }
  }
}
</script>

<style scoped lang="scss">
  .navigation-process-container {
    display: flex;
    flex-direction: row;
    background: rgba(256,256,256, .2);
    box-shadow: 0 4px 4px rgba(0,0,0,.25);

    .navigation-process-content {
      overflow: hidden;
      position: relative;
      white-space: nowrap;
      color: var(--color-font-white);
      font-weight: 500;
      transition: all .3s ease;
      cursor: pointer;
      & span {
        position: relative;
        z-index: 1;
      }
      &.active {
        box-shadow: 0 3px 16px rgba(0,0,0,.25);
        font-weight: 600;
        color: var(--color-sub-main);
        .bg {
          transform: scaleX(1);
          transform-origin: left;
          transition: transform 0.25s;
        }
      }

      &:hover {
        box-shadow: 0 3px 16px rgba(0,0,0,.25);
        font-weight: 600;
        color: var(--color-sub-main);
        .bg {
          transform: scaleX(1);
          transform-origin: left;
          transition: transform 0.25s;
        }
      }

      .bg {
        background: var(--color-font-delft);
        z-index: 0;
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        transform: scaleX(0);
        transform-origin: right;
        transition: transform 0.25s;
      }
    }
  }



  @media (max-width: 3024px){
    .navigation-process-container {
      padding: 3px;
      border-radius: 44px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 24px;
        padding: 12px 28px;
      }
    }
  }


  @media (max-width: 1920px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 50px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 18px;
        padding: 8px 20px;
      }
    }
  }


  @media (max-width: 1600px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 44px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 16px;
        padding: 6px 16px;
      }
    }
  }
  @media (max-height: 800px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 44px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 14px;
        padding: 6px 16px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 44px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 16px;
        padding: 6px 16px;
      }
    }
  }

  @media (max-width: 1133px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 44px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 14px;
        padding: 6px 16px;
      }
    }
  }


  @media (max-width: 769px) {
    .navigation-process-container {
      background: #1E2025;
      padding: 0;
      border-radius: 0;
      width: 100%;
      box-shadow: none;
      height: 44px;

      .navigation-process-content {
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-basis: 32%;
        border-radius: 50px;
        font-size: 14px;
        padding: 0;
        color: var(--color-font-nickel);

        &:last-child {
          &:before {
            display: none;
          }
        }

        &:before {
          position: absolute;
          content: "";
          right: 0;
          width: 1px;
          height: 100%;
          background: var(--color-font-nickel);
        }

        &.active {
          box-shadow: none;
          .bg {
            display: none;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .navigation-process-container {
      padding: 3px;
      border-radius: 50px;

      .navigation-process-content {
        border-radius: 50px;
        font-size: 32px;
        padding: 16px 40px;
      }
    }
  }

</style>