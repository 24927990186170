<template>
  <div class="img-container logo">
    <img :src="getUrlLogo('full-logo')" alt="bg">
  </div>
</template>

<script>
export default {
  name: "FullLogo"
}
</script>

<style scoped lang="scss">
.img-container {
  width: 100%;
  height: 100%;
  &.logo {
    position: relative;
    z-index: 1;
  }
  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}



@media (max-width: 3024px){
  .img-container {
    &.logo {
      max-width: 623px;
    }
  }
}


@media (max-width: 1920px) {
  .img-container {
    &.logo {
      max-width: 532px;
    }
  }
}


@media (max-width: 1600px) {
  .img-container {
    &.logo {
      max-width: 372px;
    }
  }
}
@media (max-height: 800px) {
  .img-container {
    &.logo {
      max-width: 283px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .img-container {
    &.logo {
      max-width: 372px;
    }
  }
}

@media (max-width: 1133px) {
  .img-container {
    &.logo {
      max-width: 283px;
    }
  }
}

@media (max-width: 900px) {
  .img-container {
    &.logo {
      max-width: 162px;
    }
  }
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  .img-container {
    &.logo {
      max-width: 162px;
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .img-container {
    &.logo {
      max-width: 940px;
    }
  }
}

</style>