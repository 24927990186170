<template>
  <div class="about-screen-container">
    <div class="about-screen-content main-content">
      <about-screen-title id="about"/>
      <about-screen-elements v-animateonscroll="{ enterClass: 'fade-in'}"/>
      <about-screen-features v-animateonscroll="{ enterClass: 'fade-in-left'}" id="features"/></div>
  </div>
</template>

<script>
import ColorText from "@/components/shared/ColorText.vue";
import AboutScreenTitle from "@/components/features/about-screen/AboutScreenTitle.vue";
import AboutScreenElements from "@/components/features/about-screen/AboutScreenElements.vue";
import AboutScreenFeatures from "@/components/features/about-screen/AboutScreenFeatures.vue";

export default {
  name: "AboutScreen",
  components: {AboutScreenFeatures, AboutScreenElements, AboutScreenTitle, ColorText},
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">
  .about-screen-container {
    overflow: hidden;
    position: relative;
  }



  @media (max-width: 3024px){
    .about-screen-container {
      .about-screen-content {
        row-gap: 180px;
      }
    }
  }


  @media (max-width: 1920px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 160px;
      }
    }
  }


  @media (max-width: 1600px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 80px;
      }
    }
  }
  @media (max-height: 800px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 100px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 80px;
      }
    }
  }

  @media (max-width: 1133px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 100px;
      }
    }
  }

  @media (max-width: 900px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 48px;
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 48px;
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .about-screen-container {
      .about-screen-content {
        row-gap: 260px;
      }
    }
  }

</style>