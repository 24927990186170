<template>
  <div class="element-container">
    <div class="top-container">
      <div class="icon-container" :class="{'active-hover' : activeIcon}"
           @click="$emit('actionIcon', item.id)">
        <img :src="getUrlFeatures(item.icon)" alt="icon">
      </div>
      <main-title-card v-if="!isMobile">
        {{ item.title }}
      </main-title-card>
      <main-title-card v-else>
        {{ item?.titleMobile || item.title }}
      </main-title-card>
    </div>
    <main-paragraph-card>
      {{ item.description }}
    </main-paragraph-card>
  </div>
</template>

<script>
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";

export default {
  name: "CardElement",
  components: {MainTitleCard, MainParagraphCard},
  props: {
    item: {
      type: Object,
      required: true
    },
    activeIcon: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getUrlFeatures(picture) {
      return require(`@/assets/features/${picture}.svg`)
    },
  }
}
</script>

<style scoped lang="scss">
.card-main-title-container {
  text-transform: uppercase;
  white-space: pre-wrap;
}
.card-main-paragraph-container {
  color: var(--color-font-nickel)
}
.card-main-title-container {
  color: var(--color-font-delft);
}
.element-container {
  .top-container {
    display: flex;
    flex-direction: column;
  }
  .icon-container {
    &.active-hover {
      cursor: pointer;
      opacity: 1;
      transition: all .3s ease;
      &:hover {
        opacity: .6;
      }
    }
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
@media (max-width: 3024px){
    .element-container {
      max-width: 459px;
      .text-content {
        max-width: 429px;
      }
      .icon-container {
        width: 80px;
        height: 80px;
        margin-bottom: 40px;
      }
      .card-main-title-container {
        margin-bottom: 20px;
        font-size: 30px;
      }
      .card-main-paragraph-container {
        font-size: 24px;
      }
    }
}


@media (max-width: 1920px) {
    .element-container {
      max-width: 400px;
      .text-content {
        max-width: 375px;
      }
      .icon-container {
        width: 60px;
        height: 60px;
        margin-bottom: 40px;
      }
      .card-main-title-container {
        margin-bottom: 20px;
        font-size: 22px;
      }
      .card-main-paragraph-container {
        font-size: 18px;
      }
    }
}


@media (max-width: 1600px) {
    .element-container {
      max-width: 280px;
      .text-content {
        max-width: inherit;
      }
      .icon-container {
        width: 40px;
        height: 40px;
        margin-bottom: 24px;
      }
      .card-main-title-container {
        margin-bottom: 28px;
        font-size: 16px;
      }
      .card-main-paragraph-container {
        font-size: 14px;
      }
    }
}
@media (max-height: 800px) {
    .element-container {
      max-width: 233px;
      .text-content {
        max-width: inherit;
      }
      .icon-container {
        width: 32px;
        height: 32px;
        margin-bottom: 24px;
      }
      .card-main-title-container {
        margin-bottom: 28px;
      }
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
    .element-container {
      max-width: 280px;
      .text-content {
        max-width: inherit;
      }
      .icon-container {
        width: 40px;
        height: 40px;
        margin-bottom: 24px;
      }
      .card-main-title-container {
        margin-bottom: 28px;
      }
    }
  }

@media (max-width: 1133px) {
    .element-container {
      max-width: 233px;
      .text-content {
        max-width: inherit;
      }
      .icon-container {
        width: 32px;
        height: 32px;
        margin-bottom: 24px;
      }
      .card-main-title-container {
        margin-bottom: 28px;
      }
    }
  }
@media (max-width: 900px) {
  .element-container {
    max-width: 250px;
  }
}


@media (max-width: 769px) {
  .element-container {
    max-width: 100%;
    .top-container {
      align-items: center;
      flex-direction: row;
      column-gap: 12px;
      margin-bottom: 8px;
      .card-main-title-container {
        white-space: pre-wrap;
        font-size: 14px;
      }
    }
    .text-content {
      max-width: inherit;
    }
    .icon-container {
      min-width: 24px;
      max-width: 24px;
      height: 24px;
      margin-bottom: 0;
    }
    .card-main-title-container {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .element-container {
      max-width: 100%;
      .top-container {
        align-items: center;
        flex-direction: row;
        column-gap: 12px;
        margin-bottom: 8px;
        .card-main-title-container {
          white-space: normal;
        }
      }
      .text-content {
        max-width: inherit;
      }
      .icon-container {
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        margin-bottom: 0;
      }
      .card-main-title-container {
        margin-bottom: 0;
      }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .element-container {
    max-width: 760px;

    .text-content {
      max-width: 730px;
    }

    .icon-container {
      width: 120px;
      height: 120px;
      margin-bottom: 60px;
    }

    .card-main-title-container {
      margin-bottom: 40px;
      font-size: 48px;
    }
    .card-main-paragraph-container {
      font-size: 40px;
    }
  }
}
</style>