<template>
  <div class="main-title-container">
      <slot/>
  </div>
</template>

<script>
export default {
  name: "MainTitle"
}
</script>

<style scoped lang="scss">
.main-title-container {
  font-weight: 500;
  color: var(--color-font-squid);
  text-transform: uppercase;
}

  @media (max-width: 3024px){
    .main-title-container {
      font-size: 72px;
    }
  }


  @media (max-width: 1920px) {
    .main-title-container {
      font-size: 60px;
    }
  }


  @media (max-width: 1600px) {
    .main-title-container {
      font-size: 48px;
    }
  }
  @media (max-height: 800px) {
    .main-title-container {
      font-size: 32px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .main-title-container {
      font-size: 48px;
    }
  }

  @media (max-width: 1133px) {
    .main-title-container {
      font-size: 32px;
    }
  }

  @media (max-width: 769px) {
    .main-title-container {
      font-size: 26px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .main-title-container {
      font-size: 26px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .main-title-container {
      font-size: 112px;
    }

  }

</style>