<template>
  <div class="card-process-container" v-if="!activeMiniMobile">
    <div class="top-container">
      <div class="left-container">
        <div class="header-container">
          <div class="icon-container">
            <img :src="getUrlProcess(`${item.type}.svg`)" alt="icon">
          </div>
          <main-paragraph-card>
            {{ item.name }}
          </main-paragraph-card>
        </div>
        <mini-paragraph class="title">
          {{ item.zone }}
        </mini-paragraph>
      </div>
      <div class="icon-container process">
        <img :src="getUrlProcess(`${item.icon}.svg`)" alt="icon">
      </div>
    </div>
    <div class="bottom-container">
      <mini-paragraph>
        {{ timeComputed }}
      </mini-paragraph>
      <mini-paragraph>
        {{ item.location }}
      </mini-paragraph>
    </div>
  </div>
  <div class="card-process-container" v-else>
    <div class="left-container">
      <div class="icon-container">
        <img :src="getUrlProcess(`${item.type}.svg`)" alt="icon">
      </div>
      <main-paragraph-card>
        {{ item.name }}
      </main-paragraph-card>
    </div>
    <div class="center-container">
      <mini-paragraph class="title">
        {{ item.zone }}
      </mini-paragraph>
      <mini-paragraph>
        {{ timeComputed }}
      </mini-paragraph>
    </div>
    <div class="right-container">
      <mini-paragraph>
        {{ item.location }}
      </mini-paragraph>
      <div class="icon-container process">
        <img :src="getUrlProcess(`${item.icon}.svg`)" alt="icon">
      </div>
    </div>
  </div>
</template>

<script>
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";
import CheckBox from "@/components/shared/CheckBox.vue";
import MiniParagraph from "@/components/shared/text/card/MiniParagraph.vue";
import {mapState} from "vuex";

export default {
  name: "CardProcess",
  components: {MiniParagraph, CheckBox, MainParagraphCard},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatFullDate(date)  {
      return new Date(date).toLocaleDateString("ru-RU", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
    },
    returnTime(date)  {
      return new Date(date).toLocaleTimeString('it-IT', {
        hour: "2-digit",
        minute: "2-digit",
      })
    },
  },
  computed: {
    ...mapState(['activeMiniMobile']),
    timeComputed() {
      return `${this.formatFullDate(this.item.expireAt)} ${this.returnTime(this.item.expireAt)}`
    }
  }
}
</script>

<style scoped lang="scss">
.icon-container {
  width: 100%;
  height: 100%;

  & img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.card-mini-paragraph-container {
  font-style: normal;
}
.card-process-container {
  position: relative;
  display: flex;
  flex-direction: column;
  .top-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .left-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .header-container {
      display: flex;
      flex-direction: row;
      .card-main-paragraph-container {
        white-space: pre-wrap;
        color: var(--color-font-white)
      }
    }

    .title {
      font-style: normal;
      font-weight: 600;
      color: var(--color-font-lavender) !important;
    }
    .icon-container {
      height: inherit;
      &.process {
        right: 0;
        top: 0;
        position: absolute;
      }
    }
  }
  .bottom-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}



@media (max-width: 3024px){
  .card-process-container {
    row-gap: 28px;
    .top-container {
      .left-container {
        row-gap: 28px;
      }
      .header-container {
        column-gap: 20px;
        .card-main-paragraph-container {
          font-size: 24px;
        }
        .icon-container {
          max-width: 64px;
          min-width: 64px;
          max-height: 64px;
        }
      }

      .title {
        font-size: 20px;
      }
      .icon-container {
        max-width: 120px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 20px;
      }
    }
  }
}


@media (max-width: 1920px) {
  .card-process-container {
    row-gap: 20px;
    .top-container {
      .left-container {
        row-gap: 20px;
      }
      .header-container {
        column-gap: 10px;
        .card-main-paragraph-container {
          font-size: 20px;
        }
        .icon-container {
          min-width: 44px;
          max-width: 44px;
          max-height: 44px;
        }
      }

      .title {
        font-weight: 600;
        font-size: 16px;
      }
      .icon-container {
        max-width: 89px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 14px;
      }
    }
  }
}


@media (max-width: 1600px) {
  .card-process-container {
    row-gap: 12px;
    .top-container {
      .left-container {
        row-gap: 12px;
      }
      .header-container {
        column-gap: 10px;
        .card-main-paragraph-container {
          font-size: 16px;
        }
        .icon-container {
          min-width: 44px;
          max-width: 44px;
          max-height: 44px;
        }
      }

      .title {
        font-size: 14px;
      }
      .icon-container {
        max-width: 76px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 12px;
      }
    }
  }
}
@media (max-height: 800px) {
  .card-process-container {
    row-gap: 8px;
    .top-container {
      .left-container {
        row-gap: 8px;
      }
      .header-container {
        column-gap: 10px;
        .card-main-paragraph-container {
          font-size: 14px;
        }
        .icon-container {
          min-width: 36px;
          max-width: 36px;
          max-height: 36px;
        }
      }

      .title {
        font-size: 12px;
      }
      .icon-container {
        max-width: 64px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .card-process-container {
    row-gap: 12px;
    .top-container {
      .left-container {
        row-gap: 12px;
      }
      .header-container {
        column-gap: 10px;
        .card-main-paragraph-container {
          font-size: 16px;
        }
        .icon-container {
          min-width: 44px;
          max-width: 44px;
          max-height: 44px;
        }
      }

      .title {
        font-size: 14px;
      }
      .icon-container {
        max-width: 76px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 1133px) {
  .card-process-container {
    row-gap: 8px;
    .top-container {
      .left-container {
        row-gap: 8px;
      }
      .header-container {
        column-gap: 10px;
        .card-main-paragraph-container {
          font-size: 14px;
        }
        .icon-container {
          min-width: 36px;
          max-width: 36px;
          max-height: 36px;
        }
      }

      .title {
        font-size: 12px;
      }
      .icon-container {
        max-width: 64px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 12px;
      }
    }
  }
}

@media (max-width: 769px) {
  .card-process-container {
    display: flex;
    flex-direction: row;
    column-gap: 32px;
    align-items: center;
    .left-container {
      min-width: 123px;
      max-width: 123px;
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;

      .icon-container {
        max-width: 36px;
        min-width: 36px;
        max-height: 36px;
      }
      .card-main-paragraph-container {
        white-space: pre-wrap;
        font-size: 12px;
        color: var(--color-font-white);
        font-weight: 600;
      }
    }

    .center-container {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      .card-mini-paragraph-container {
        font-size: 11px;
        &.title {
          font-weight: 600;
          color: var(--color-font-white)
        }
      }
    }
    .right-container {
      margin-left: auto;
      align-self: flex-end;
      .card-mini-paragraph-container {
        font-size: 11px;
      }
      .icon-container {
        position: absolute;
        top: 0;
        right: 0;
        width: 52px;
        height: 52px;
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .card-process-container {
    .left-container {
      min-width: 110px;
      max-width: 110px;
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {

  .card-process-container {
    column-gap: 32px;
    .left-container {
      min-width: 81px;
      max-width: 81px;
      column-gap: 10px;

      .icon-container {
        max-width: 36px;
        min-width: 36px;
        max-height: 36px;
      }
      .card-main-paragraph-container {
        font-size: 10px;
      }
    }

    .center-container {
      row-gap: 8px;

      .card-mini-paragraph-container {
        font-size: 9px;
      }
    }
    .right-container {
      .card-mini-paragraph-container {
        font-size: 10px;
      }
      .icon-container {
        width: 48px;
        height: 48px;
      }
    }
  }
}

@media (min-width: 3025px) {
  .card-process-container {
    row-gap: 32px;
    .top-container {
      .left-container {
        row-gap: 32px;
      }
      .header-container {
        column-gap: 28px;
        .card-main-paragraph-container {
          font-size: 40px;
        }
        .icon-container {
          min-width: 82px;
          max-width: 82px;
          max-height: 82px;
        }
      }

      .title {
        font-size: 32px;
      }
      .icon-container {
        max-width: 172px;
      }
    }
    .bottom-container {
      .card-mini-paragraph-container {
        font-size: 32px;
      }
    }
  }
}

</style>