<template>
  <div class="card-result-container"
       @mouseenter="focusTrue"
       @mouseleave="focusFalse">
    <mini-title-card>
      {{ item.title }}
    </mini-title-card>
    <div class="bottom-container">
      <div class="arrow-container" :class="item?.direction ? item.direction : 'default'">
        <div class="img-content">
          <transition name="fade-opacity-lazy">
            <img v-if="!focusParam" :src="getUrlIcons('big-arrow')" alt="arrow">
            <img v-else :src="getUrlIcons(`big-arrow-${item?.direction === 'down' ? 'red' : 'green'}`)" alt="arrow">
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";

export default {
  name: "CardResult",
  components: {MiniTitleCard},
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      focusParam: false
    }
  },
  methods: {
    focusTrue() {
      this.focusParam = true;
    },
    focusFalse() {
      this.focusParam = false;
    },
  }
}
</script>

<style scoped lang="scss">
  .card-result-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    background: var(--color-font-lavender);

    .card-mini-title-container {
      color: var(--color-font-delft);
    }

    .bottom-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      column-gap: 10px;

      .arrow-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .img-content {
          position: relative;
          width: inherit;
          height: inherit;
        }
        &.up {
          transform: rotate(-90deg);
        }
        &.default {
          opacity: 0;
        }
        & img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }

      .percent-container {
        color: rgba(var(--color-rgba-cool-grey), .6);
        font-weight: 600;
      }
    }
  }



  @media (max-width: 3024px){
    .card-result-container {
      border-radius: 10px;
      padding: 30px;
      height: 370px;

      .bottom-container {
        column-gap: 10px;

        .arrow-container {
          padding: 16px;
          width: 82px;
          height: 82px;
        }

        .percent-container {
          font-size: 72px;
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .card-result-container {
      border-radius: 12px;
      padding: 20px;
      height: 280px;

      .bottom-container {
        column-gap: 10px;

        .arrow-container {
          padding: 12px;
          width: 64px;
          height: 64px;
        }

        .percent-container {
          font-size: 64px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .card-result-container {
      border-radius: 6px;
      padding: 16px;
      height: 250px;

      .bottom-container {
        column-gap: 4px;

        .arrow-container {
          padding: 12px;
          width: 46px;
          height: 46px;
        }

        .percent-container {
          font-size: 44px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-result-container {
      border-radius: 4px;
      padding: 16px;
      height: 200px;

      .bottom-container {
        column-gap: 4px;

        .arrow-container {
          padding: 8px;
          width: 33px;
          height: 33px;
        }

        .percent-container {
          font-size: 32px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-result-container {
      border-radius: 6px;
      padding: 16px;
      height: 250px;

      .bottom-container {
        column-gap: 4px;

        .arrow-container {
          padding: 12px;
          width: 46px;
          height: 46px;
        }

        .percent-container {
          font-size: 44px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-result-container {
      border-radius: 4px;
      padding: 16px;
      height: 200px;

      .bottom-container {
        column-gap: 4px;

        .arrow-container {
          padding: 8px;
          width: 33px;
          height: 33px;
        }

        .percent-container {
          font-size: 32px;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .card-result-container {
      border-radius: 4px;
      padding: 12px;
      height: 114px;

      .card-mini-title-container {
        white-space: pre-wrap;
      }

      .bottom-container {
        column-gap: 0;

        .arrow-container {
          padding: 8px;
          width: 33px;
          height: 33px
        }

        .percent-container {
          font-size: 32px;
        }
      }
    }
  }


  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .card-result-container {
      border-radius: 4px;
      padding: 10px;
      height: 114px;

      .card-mini-title-container {
        white-space: pre-wrap;
        font-size: 11px;
      }

      .bottom-container {
        column-gap: 0;

        .arrow-container {
          padding: 8px;
          width: 27px;
          height: 27px;
        }

        .percent-container {
          font-size: 28px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-result-container {
      border-radius: 12px;
      padding: 44px;
      height: 540px;

      .bottom-container {
        column-gap: 24px;

        .arrow-container {
          padding: 20px;
          width: 118px;
          height: 118px;
        }

        .percent-container {
          font-size: 108px;
        }
      }
    }
  }

</style>