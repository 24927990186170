<template>
  <div class="project-screen-img-container">
    <div class="project-screen-img-content main-content" v-animateonscroll="{ enterClass: 'fade-in'}" @animationend="showNextItem">
      <div class="left-container">
        <color-text :active-color-text="$t('projectScreenImg.colorText')"
                    :text-info="$t('projectScreenImg.mainTitle')"/>
        <main-paragraph-card>
          {{ $t('projectScreenImg.subTitle') }}
        </main-paragraph-card>
        <main-button class="light small" @click="openModalDemo">
          {{ $t('projectScreenImg.buttonText') }}
        </main-button>
      </div>
      <div class="img-container">
        <div class="img-content" v-for="(item, index) in img"
             :key="index"
             :class="[item, 'box-shadow', {'active' : isVisible(index)}]" >
          <img :src="getUrlDemoImg(item)" alt="demo-img">
        </div>
        <div class="img-content decoration first" :class="{'active' : isVisible(5)}">
          <img :src="getUrlImg('decoration')" alt="decoration">
        </div>
        <div class="img-content decoration second" :class="{'active' : isVisible(5)}">
          <img :src="getUrlImg('decoration')" alt="decoration">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ColorText from "@/components/shared/ColorText.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import {mapActions} from "vuex";
import Decoration from "@/components/entities/Decoration.vue";

export default {
  name: "ProjectScreenImg",
  components: {Decoration, MainButton, MainParagraphCard, ColorText},
  data() {
    return {
      visibleIndexItem: -1,
      img: ['mini-map', 'big-map', 'chart', 'realistic-mini-map', 'table'],
    }
  },
  methods: {
    getUrlDemoImg(picture) {
      return require(`@/assets/demo-img/${picture}.webp`)
    },
    showNextItem() {
      if (this.visibleIndexItem < this.img.length + 1) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 200)
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .project-screen-img-container {
    position: relative;
    .left-container {
      white-space: pre-wrap;
      position: relative;
      z-index: 1;
      display: flex;
      flex-direction: column;
      .main-button-container {
        width: 100%;
      }
      .card-main-paragraph-container {
        color: var(--color-font-nickel)
      }
    }
    .project-screen-img-content {
      height: 100%;
      padding: 0 !important;
      position: relative;
    }
    .img-container {
      height: 100%;
      z-index: 0;
      width: 100%;
      position: absolute;
      right: 0;
      top: 0;

      .img-content {
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        opacity: 0;
        transition: all .5s ease;
        &.active {
          opacity: 1;
        }
        & img {
           width: 100%;
          height: 100%;
        }
        &.decoration {
          z-index: 0;
          transform: translateY(-50%);
          top: 50%;
          &.second {
            right: -75%;
          }
          & img {
            object-fit: contain;
          }
        }
        &.mini-map {
          z-index: 1;
          left: 0;
          bottom: 0;
        }
        &.big-map {
          z-index: 1;
          top: 0;
        }
        &.chart {
          z-index: 1;
          bottom: 0;
        }
        &.realistic-mini-map {
          z-index: 2;
        }
        &.table {
          z-index: 3;
        }
      }
    }
  }



  @media (max-width: 3024px) and (min-width: 1921px){
    .project-screen-img-container {
      height: 746px;
      padding-top: 140px;
      .left-container {
        max-width: 780px;
        row-gap: 44px;
        .main-button-container {
          max-width: 270px;
        }
      }

      .img-container {
        max-width: 1076px;
        height: 606px;

        .img-content {
          border-radius: 11px;

          &.box-shadow {
            box-shadow: 0 3.5px 38.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: -10%;
            }

            &.second {
              right: -90%;
            }
          }
          &.mini-map {
            max-width: 280px;
            max-height: 190px;
          }
          &.big-map {
            max-width: 656px;
            max-height: 372px;
            right: 115px;
          }
          &.chart {
            max-width: 534px;
            max-height: 218px;
            right: 55px;
          }
          &.realistic-mini-map {
            max-width: 251px;
            max-height: 156px;
            right: 30px;
            bottom: 258px;
          }
          &.table {
            max-width: 254px;
            max-height: 450px;
            left: 208px;
            bottom: 35px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .project-screen-img-container {
      height: 638px;
      padding-top: 120px;
      .left-container {
        max-width: 630px;
        row-gap: 44px;
        .main-button-container {
          max-width: 211px;
        }
      }

      .img-container {
        max-width: 890px;

        .img-content {
          border-radius: 6px;

          &.box-shadow {
            box-shadow: 0 3.5px 38.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: -5%;
            }
          }
          &.mini-map {
            max-width: 239px;
            max-height: 162px;
          }
          &.big-map {
            max-width: 558px;
            max-height: 317px;
            right: 72px;
          }
          &.chart {
            max-width: 454px;
            max-height: 185px;
            right: 20px;
          }
          &.realistic-mini-map {
            max-width: 213px;
            max-height: 134px;
            right: 0;
            bottom: 164px;
          }
          &.table {
            max-width: 216px;
            max-height: 382px;
            left: 176px;
            bottom: 30px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .project-screen-img-container {
      height: 464px;
      padding-top: 80px;
      .left-container {
        white-space: inherit;
        max-width: 480px;
        row-gap: 32px;
        .main-button-container {
          max-width: 166px;
        }
      }

      .img-container {
        height: 384px;
        max-width: 680px;

        .img-content {
          border-radius: 6px;

          &.box-shadow {
            box-shadow: 0 2.5px 24.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: -58px;
            }
            &.second {
              right: -90%;
            }
          }
          &.mini-map {
            max-width: 177px;
            max-height: 121px;
          }
          &.big-map {
            max-width: 413px;
            max-height: 235px;
            right: 74px;
          }
          &.chart {
            max-width: 336px;
            max-height: 137px;
            right: 36px;
          }
          &.realistic-mini-map {
            max-width: 158px;
            max-height: 99px;
            right: 21px;
            bottom: 122px;
          }
          &.table {
            max-width: 160px;
            max-height: 284px;
            left: 131px;
            bottom: 22px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .project-screen-img-container {
      height: 380px;
      padding-top: 60px;
      .left-container {
        white-space: inherit;
        max-width: 400px;
        row-gap: 32px;
        .main-button-container {
          max-width: 137px;
        }
      }

      .img-container {
        max-width: 586px;
        height: 320px;

        .img-content {
          border-radius: 4.33px;

          &.box-shadow {
            box-shadow: 0 1.8px 20.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              right: -101%;
            }
          }
          &.mini-map {
            max-width: 149px;
            max-height: 102px;
          }
          &.big-map {
            max-width: 346px;
            max-height: 197px;
            right: 61px;
          }
          &.chart {
            max-width: 282px;
            max-height: 115px;
            right: 28px;
          }
          &.realistic-mini-map {
            max-width: 130px;
            max-height: 83px;
            right: 16px;
            bottom: 101px;
          }
          &.table {
            max-width: 135px;
            max-height: 238px;
            left: 109px;
            bottom: 17px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-screen-img-container {
      height: 464px;
      padding-top: 80px;
      .left-container {
        white-space: inherit;
        max-width: 480px;
        row-gap: 32px;
        .main-button-container {
          max-width: 166px;
        }
      }

      .img-container {
        max-width: 680px;
        height: 384px;

        .img-content {
          border-radius: 6px;

          &.box-shadow {
            box-shadow: 0 2.5px 24.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              right: -101%;
            }
          }
          &.mini-map {
            max-width: 177px;
            max-height: 121px;
          }
          &.big-map {
            max-width: 413px;
            max-height: 235px;
            right: 74px;
          }
          &.chart {
            max-width: 336px;
            max-height: 137px;
            right: 36px;
          }
          &.realistic-mini-map {
            max-width: 158px;
            max-height: 99px;
            right: 21px;
            bottom: 122px;
          }
          &.table {
            max-width: 160px;
            max-height: 284px;
            left: 131px;
            bottom: 22px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .project-screen-img-container {
      height: 380px;
      padding-top: 60px;
      .left-container {
        white-space: inherit;
        max-width: 400px;
        row-gap: 32px;
        .main-button-container {
          max-width: 137px;
        }
      }

      .img-container {
        max-width: 586px;
        height: 320px;

        .img-content {
          border-radius: 4.33px;

          &.box-shadow {
            box-shadow: 0 1.8px 20.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              right: -101%;
            }
          }
          &.mini-map {
            max-width: 149px;
            max-height: 102px;
          }
          &.big-map {
            max-width: 346px;
            max-height: 197px;
            right: 61px;
          }
          &.chart {
            max-width: 282px;
            max-height: 115px;
            right: 28px;
          }
          &.realistic-mini-map {
            max-width: 130px;
            max-height: 83px;
            right: 16px;
            bottom: 101px;
          }
          &.table {
            max-width: 135px;
            max-height: 238px;
            left: 109px;
            bottom: 17px;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .project-screen-img-container {
      .img-container {
        right: 0;
      }
    }
  }

  @media (max-width: 900px) {
    .project-screen-img-container {
      padding: 32px 18px 0;
      height: 445px;

      .card-main-paragraph-container {
        white-space: pre-line;
        font-size: 12px;
      }

      .project-screen-img-content {
        padding: 0;
        height: 100%;
        flex-direction: column;
        row-gap: 48px;
      }
      .left-container {
        padding: 0;
        white-space: inherit;
        max-width: 100%;
        row-gap: 24px;
        align-items: center;
        text-align: center;
        .main-button-container {
          max-width: 153px;
        }
      }

      .img-container {
        max-width: 420px;
        margin: 0 auto;
        min-height: 201px;
        max-height: 201px;
        position: relative;
        right: 0;
        top: 0;

        .img-content {
          border-radius: 2.7px;

          &.box-shadow {
            box-shadow: 0 1.25px 13.83px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              display: none;
            }
          }
          &.mini-map {
            max-width: 93px;
            max-height: 64px;
          }
          &.big-map {
            max-width: 216px;
            max-height: 123px;
            right: 38px;
          }
          &.chart {
            max-width: 176px;
            max-height: 72px;
            right: 18px;
          }
          &.realistic-mini-map {
            max-width: 80px;
            max-height: 52px;
            right: 10px;
            bottom: 65px;
          }
          &.table {
            max-width: 84px;
            max-height: 148px;
            left: 68px;
            bottom: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .project-screen-img-container {
      padding: 40px 22px 0;
      height: 472px;

      .card-main-paragraph-container {
        white-space: normal;
        font-size: 12px;
      }

      .project-screen-img-content {
        padding: 0;
        height: 100%;
        flex-direction: column;
        row-gap: 48px;
      }
      .left-container {
        padding: 0;
        white-space: inherit;
        max-width: 100%;
        row-gap: 24px;
        align-items: center;
        text-align: center;
        .main-button-container {
          max-width: 153px;
        }
      }

      .img-container {
        min-height: 218px;
        max-height: 218px;
        position: relative;
        right: 0;
        bottom: 0;

        .img-content {
          border-radius: 2.95px;

          &.box-shadow {
            box-shadow: 0 1.25px 13.83px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              display: none;
            }
          }
          &.mini-map {
            max-width: 102px;
            max-height: 69px;
          }
          &.big-map {
            max-width: 236px;
            max-height: 134px;
            right: 38px;
          }
          &.chart {
            max-width: 192px;
            max-height: 78px;
            right: 16px;
          }
          &.realistic-mini-map {
            max-width: 88px;
            max-height: 57px;
            right: 9px;
            bottom: 70px;
          }
          &.table {
            max-width: 92px;
            max-height: 162px;
            left: 74px;
            bottom: 11px;
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .project-screen-img-container {
      padding: 32px 16px 0;
      height: 452px;

      .card-main-paragraph-container {
        white-space: unset;
        font-size: 12px;
      }

      .project-screen-img-content {
        padding: 0;
        height: 100%;
        flex-direction: column;
        row-gap: 48px;
      }
      .left-container {
        padding: 0;
        white-space: inherit;
        max-width: 100%;
        row-gap: 24px;
        align-items: center;
        text-align: center;
        .main-button-container {
          max-width: 153px;
        }
      }

      .img-container {
        min-height: 201px;
        max-height: 201px;
        position: relative;
        right: 0;
        top: 0;

        .img-content {
          border-radius: 2.7px;

          &.box-shadow {
            box-shadow: 0 1.25px 13.83px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              right: -101%;
            }
          }
          &.mini-map {
            max-width: 93px;
            max-height: 64px;
          }
          &.big-map {
            max-width: 216px;
            max-height: 123px;
            right: 32px;
          }
          &.chart {
            max-width: 176px;
            max-height: 72px;
            right: 15px;
          }
          &.realistic-mini-map {
            max-width: 80px;
            max-height: 52px;
            right: 7px;
            bottom: 65px;
          }
          &.table {
            max-width: 84px;
            max-height: 148px;
            left: 60px;
            bottom: 12px;
          }
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {

    .project-screen-img-container {
      padding: 32px 18px 0;
      height: 452px;

      .card-main-paragraph-container {
        padding: 0 12px;
        font-size: 12px;
      }

      .project-screen-img-content {
        padding: 0;
        height: 100%;
        flex-direction: column;
        row-gap: 48px;
      }
      .left-container {
        padding: 0;
        white-space: inherit;
        max-width: 100%;
        row-gap: 24px;
        align-items: center;
        text-align: center;
        .main-button-container {
          max-width: 153px;
        }
      }

      .img-container {
        min-height: 201px;
        max-height: 201px;
        position: relative;
        right: 0;
        top: 0;

        .img-content {
          border-radius: 2.7px;

          &.box-shadow {
            box-shadow: 0 1.25px 13.83px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: 0;
            }
            &.second {
              right: -100%;
            }
          }
          &.mini-map {
            max-width: 93px;
            max-height: 64px;
          }
          &.big-map {
            max-width: 216px;
            max-height: 123px;
            right: 21px;
          }
          &.chart {
            max-width: 176px;
            max-height: 72px;
            right: 0;
          }
          &.realistic-mini-map {
            max-width: 80px;
            max-height: 52px;
            right: 0;
            bottom: 65px;
          }
          &.table {
            max-width: 84px;
            max-height: 148px;
            left: 68px;
            bottom: 12px;
          }
        }
      }
    }
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .project-screen-img-container {
      height: 1083px;
      padding-top: 160px;
      .left-container {
        max-width: 1080px;
        row-gap: 44px;
        .main-button-container {
          max-width: 419px;
        }
      }

      .img-container {
        max-width: 1640px;

        .img-content {
          border-radius: 16px;

          &.box-shadow {
            box-shadow: 0 5.5px 58.5px rgba(198, 202, 208, .8);
          }
          &.decoration {
            &.first {
              left: -25%;
            }
          }
          &.mini-map {
            max-width: 421px;
            max-height: 285px;
          }
          &.big-map {
            max-width: 983px;
            max-height: 559px;
            right: 198px;
          }
          &.chart {
            max-width: 801px;
            max-height: 327px;
            right: 108px;
          }
          &.realistic-mini-map {
            max-width: 376px;
            max-height: 236px;
            right: 71px;
            bottom: 301px;
          }
          &.table {
            max-width: 382px;
            max-height: 675px;
            left: 312px;
            bottom: 65px;
          }
        }
      }
    }
  }

</style>