<template>
  <div class="start-screen-img-container">
    <div class="img-container" :class="{'active' : !isLoading}">
        <video @loadeddata="load" class="video" loop playsinline preload="auto" autoplay  muted>
          <source :src="getUrlVideo(`${mainBG}.webm`)" type="video/webm"/>
          <source :src="getUrlVideo(`${mainBG}.mp4`)" type="video/mp4"/>
        </video>
    </div>
<!--    <div class="img-container main">-->
<!--      <img :src="getUrlImg(mainBG)" alt="main-bg">-->
<!--    </div>-->
<!--    <div class="img-container sub">-->
<!--      <img :src="getUrlImg(subBG)" alt="sub-bg">-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "StartScreenImg",
  data() {
    return {
      isLoading: true,
      mainBG: '3d',
      subBG: 'main-decoration'
    }
  },
  methods: {
    load() {
      this.isLoading = false;
    }
  }
}
</script>

<style scoped lang="scss">
.start-screen-img-container {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all .5s ease;
  .img-container {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    transition: all .3s ease;

    &.active {
      opacity: 1;
    }
    &.sub {
      position: absolute;
      z-index: 0;
    }
    & video {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
    & img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 3024px){
      .start-screen-img-container {
        .img-container {
          &.main {
            max-width: 882px;
            max-height: 497px;
          }
          &.sub {
            right: -20%;
            width: 150%;
          }
        }
      }
}

@media (max-width: 1920px) {

      .start-screen-img-container {
        .img-container {
          &.main {
            max-width: 882px;
            max-height: 497px;
          }
          &.sub {
            right: -20%;
            width: 150%;
          }
        }
      }
}


@media (max-width: 1600px) {
      .start-screen-img-container {
        .img-container {
          &.main {
            max-width: 580px;
            max-height: 326px;
          }
          &.sub {
            right: -25%;
          }
        }
      }
}

@media (max-height: 800px) {

      .start-screen-img-container {
        align-items: flex-start;
        .img-container {
          &.main {
            max-width: 580px;
            max-height: 326px;
          }
          &.sub {
            top: -10%;
            right: -25%;
          }
        }
      }
}

@media (max-width: 1400px) and (min-height: 801px) {

      .start-screen-img-container {
        .img-container {
          &.main {
            max-width: 580px;
            max-height: 326px;
          }
          &.sub {
            right: -25%;
          }
        }
      }
}

@media (max-width: 1133px) {
      .start-screen-img-container {
        align-items: flex-start;
        .img-container {
          &.main {
            max-width: 580px;
            max-height: 326px;
          }
          &.sub {
            top: -10%;
            right: -25%;
          }
        }
      }
}

@media (max-width: 900px) {
  .start-screen-img-container {
    .img-container {
      &.main {
        max-width: 320px;
        max-height: 284px;
      }
      &.sub {
        width: 130%;
        top: -15%;
        right: -12%;
      }
    }
  }
}


@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
      .start-screen-img-container {
        .img-container {
          &.main {
            max-width: 1323px;
            max-height: 746px;
          }
          &.sub {
            right: -20%;
            width: 150%;
            top: -10%;
          }
        }
      }
}
</style>