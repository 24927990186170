<template>
  <div class="card-mini-paragraph-container text-content">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "MiniParagraph"
}
</script>

<style scoped lang="scss">
 .card-mini-paragraph-container {
   color: var(--color-font-cool-grey);
   font-style: italic;
   white-space: pre-wrap;
   line-height: 120%;
 }


 @media (max-width: 3024px){
   .card-mini-paragraph-container {
     font-size: 16px;
   }
 }


 @media (max-width: 1920px) {
   .card-mini-paragraph-container {
     font-size: 14px;
   }
 }


 @media (max-width: 1600px) {
   .card-mini-paragraph-container {
     font-size: 13px;
   }
 }
 @media (max-height: 800px) {
   .card-mini-paragraph-container {
     font-size: 12px;
   }
 }

 @media (max-width: 1400px) and (min-height: 801px) {
   .card-mini-paragraph-container {
     font-size: 13px;
   }
 }

 @media (max-width: 1133px) {
   .card-mini-paragraph-container {
     font-size: 12px;
   }
 }

 @media (max-width: 769px) {
   .card-mini-paragraph-container {
     font-size: 8px;
   }
 }

 @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
   .card-mini-paragraph-container {
     font-size: 8px;
   }
 }

 @media (min-height: 600px) and (max-height: 630px){

   //8 plus
 }

 @media (max-width: 375px) {
   //se new
 }

 @media (max-width: 375px) and (min-height: 630px) {
   //x
 }


 @media (max-width: 320px) {
   //se old
 }

 @media (min-width: 3025px) {
   .card-mini-paragraph-container {
     font-size: 24px;
   }
 }

</style>