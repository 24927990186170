<template>
  <div class="card-tariff-container">
    <div class="top-container">
      <img :src="getUrlImg(`tariff/${item.id}`)" alt="img">
    </div>
    <div class="card-tariff-content">
      <div class="center-container">
        <div class="header-container">
          <div class="main-title-container">
            {{ item.title }}
          </div>
        </div>
        <div class="description-container">
          {{ item.description }}
        </div>
        <div class="items-container">
          <mini-title-card class="main-title">
            {{ $t('tariffScreen.subTitleCardFirst') }}
          </mini-title-card>
          <mini-title-card
              class="description"
              v-for="(itemInfo, index) in item.info"
              :key="index">
            <div class="icon-container">
              <img :src="getUrlIcons('check-blue')" alt="check">
            </div>
            <span>
            <span v-if="item?.colorText">
              {{ item.colorText[index] }}
            </span>
              <span :class="{'title' : item?.colorText}">
                 {{ itemInfo }}
              </span>
            </span>

          </mini-title-card>
        </div>
        <div class="items-container">
          <mini-title-card class="main-title">
            {{ $t('tariffScreen.subTitleCardSecond') }}
          </mini-title-card>
          <mini-title-card
              class="description"
              v-for="(item, index) in item.infoSecond"
              :key="index">
            <div class="circle-container"></div>{{ item }}
          </mini-title-card>
        </div>
        <div class="items-container">
          <mini-title-card class="main-title color-title">
            {{ item.priceTitle }}
          </mini-title-card>
        </div>
      </div>
      <div class="bottom-container">
        <div class="action-container">
          <main-button>
            {{ $tm('tariffScreen.buttonsAction')[0] }}
          </main-button>
          <div class="description-container button-title" @click="openModalTariff">
            {{ $tm('tariffScreen.buttonsAction')[1] }}
          </div>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import MainButton from "@/components/shared/buttons/MainButton.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";

export default {
  name: "CardTariff",
  components: {MiniTitleCard, MainButton},
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.color-title {
  color: var(--color-font-delft)
}
.description-container {
  white-space: pre-wrap;
  font-weight: 300;
  color: var(--color-font-nickel);
}
  .card-tariff-container {
    overflow: hidden;
    border-style: solid;
    border-color: var(--color-font-lavender);
    .top-container {
      position: relative;
      background-color: var(--color-font-alice-blue);
      width: 100%;
      & img {
        position: relative;
        z-index: 0;
        width: 100%;
        height: 100%;
      }
      &:after {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1;
        content: "";
        background: rgba(var(--color-rgba-lavender), .5);
      }
    }
    .card-tariff-content {
      background: var(--color-font-white);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .center-container {
        .header-container {
          font-weight: 500;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          color: var(--color-font-squid);
          .main-title-container {
            text-transform: uppercase;
          }
        }

        .items-container {
          white-space: pre-wrap;
          display: flex;
          flex-direction: column;
          .circle-container {
            border-radius: 50%;
            background-color: var(--color-middle-blue);
          }
          .card-mini-title-container {
            display: flex;
            flex-direction: row;
            align-items: center;
            text-transform: none;
            font-weight: 300;
            &.main-title {
              font-weight: 500 !important;
              text-transform: uppercase;
            }
            .title {
              color: var(--color-font-nickel);
            }
            .icon-container {
              & img {
                width: 100%;
                height: 100%;
                object-fit: contain;
              }
            }
          }
        }
      }

      .bottom-container {
        display: flex;
        flex-direction: column;
        .card-mini-title-container {
          white-space: pre-wrap;
          text-transform: uppercase;
        }
      }
      .action-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        .description-container {
          font-weight: 600;
        }
      }
    }
  }



@media (max-width: 3024px) and (min-width: 1921px){

  .description-container {
    font-size: 20px !important;
  }
  .card-tariff-container {
    border-width: 3px;
    border-radius: 24px;
    .top-container {
      height: 260px;
    }
    .card-tariff-content {
      height: 740px;
      padding: 46px;
      .center-container {
        .header-container {
          margin-bottom: 12px;
          .main-title-container {
            font-size: 32px;
          }
        }

        .items-container {
          margin-top: 40px;
          row-gap: 14px;
          .circle-container {
            min-width: 8px;
            max-width: 8px;
            height: 8px;
          }
          .card-mini-title-container {
            font-size: 20px;
            column-gap: 10px;
            &.main-title {
              margin-bottom: 2px;
            }
            .icon-container {
              min-width: 23px;
              max-width: 23px;
              height: 23px;
            }
          }
        }
      }
      .bottom-container {
        row-gap: 12px;
        .card-mini-title-container {
          font-size: 20px;
        }
      }
      .action-container {
        column-gap: 37px;

        .main-button-container {
          width: 167px;
          padding: 14px;
          font-size: 18px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .description-container {
    font-size: 16px !important;
  }
  .card-tariff-container {
    border-width: 2px;
    border-radius: 18px;
    .top-container {
      height: 195px;
    }
    .card-tariff-content {
      height: 658px;
      padding: 34px;
      .center-container {
        .header-container {
          margin-bottom: 12px;
          .main-title-container {
            font-size: 28px;
          }
        }

        .items-container {
          margin-top: 32px;
          row-gap: 10px;
          .circle-container {
            min-width: 6px;
            max-width: 6px;
            height: 6px;
          }
          .card-mini-title-container {
            column-gap: 8px;
            &.main-title {
              margin-bottom: 2px;
            }
            .icon-container {
              min-width: 23px;
              max-width: 23px;
              height: 23px;
            }
          }
        }
      }

      .bottom-container {
        row-gap: 12px;
      }
      .action-container {
        column-gap: 37px;

        .main-button-container {
          width: 144px;
          padding: 16px;
          font-size: 16px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .description-container {
    font-size: 11px !important;
  }
  .card-tariff-container {
    border-width: 1px;
    border-radius: 12px;
    .top-container {
      height: 150px;
    }
    .card-tariff-content {
      height: 448px;
      padding: 20px;
      .center-container {
        .header-container {
          margin-bottom: 8px;
          .main-title-container {
            font-size: 20px;
          }
        }

        .items-container {
          margin-top: 22px;
          row-gap: 8px;
          .card-mini-title-container {
            font-size: 11px !important;
            column-gap: 6px;
            &.main-title {
              margin-bottom: 2px;
            }
            .circle-container {
              min-width: 6px;
              max-width: 6px;
              height: 6px;
            }
            .icon-container {
              min-width: 16px;
              max-width: 16px;
              height: 16px;
            }
          }
        }
      }
      .bottom-container {
        row-gap: 8px;
        .card-mini-title-container {
          font-size: 11px;
        }
      }
      .action-container {
        column-gap: 26px;

        .main-button-container {
          width: 112px;
          padding: 12px;
          font-size: 14px;
        }
        .description-container {
          font-size: 12px !important;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .description-container {
    font-size: 11px !important;
  }
  .card-tariff-container {
    border-width: 1px;
    border-radius: 12px;
    .top-container {
      height: 150px;
    }
    .card-tariff-content {
      height: 420px;
      padding: 18px;
      .center-container {
        .header-container {
          margin-bottom: 8px;
          .main-title-container {
            font-size: 18px;
          }
        }

        .items-container {
          margin-top: 22px;
          row-gap: 8px;
          .card-mini-title-container {
            font-size: 10px !important;
            column-gap: 6px;
            &.main-title {
              font-size: 11px !important;
              margin-bottom: 2px;
            }
            .circle-container {
              min-width: 5px;
              max-width: 5px;
              height: 5px;
            }
            .icon-container {
              min-width: 16px;
              max-width: 16px;
              height: 16px;
            }
          }
        }
      }

      .action-container {
        column-gap: 26px;

        .main-button-container {
          width: 96px;
          padding: 12px;
          font-size: 12px;
        }
        .description-container {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .description-container {
    font-size: 11px !important;
  }
  .card-tariff-container {
    border-width: 1px;
    border-radius: 12px;
    .top-container {
      height: 150px;
    }
    .card-tariff-content {
      height: 448px;
      padding: 20px;
      .center-container {
        .header-container {
          margin-bottom: 8px;
          .main-title-container {
            font-size: 20px;
          }
        }

        .items-container {
          margin-top: 22px;
          row-gap: 8px;
          .card-mini-title-container {
            font-size: 11px !important;
            column-gap: 6px;
            &.main-title {
              margin-bottom: 2px;
            }
            .circle-container {
              min-width: 6px;
              max-width: 6px;
              height: 6px;
            }
            .icon-container {
              min-width: 16px;
              max-width: 16px;
              height: 16px;
            }
          }
        }
      }

      .action-container {
        column-gap: 26px;

        .main-button-container {
          width: 112px;
          padding: 12px;
          font-size: 14px;
        }
        .description-container {
          font-size: 12px !important;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .description-container {
    font-size: 11px !important;
  }
  .card-tariff-container {
    border-width: 1px;
    border-radius: 12px;
    .top-container {
      height: 150px;
    }
    .card-tariff-content {
      height: 420px;
      padding: 18px;
      .center-container {
        .header-container {
          margin-bottom: 8px;
          .main-title-container {
            font-size: 18px;
          }
        }

        .items-container {
          margin-top: 22px;
          row-gap: 8px;
          .card-mini-title-container {
            font-size: 10px !important;
            column-gap: 6px;
            &.main-title {
              font-size: 11px !important;
              margin-bottom: 2px;
            }
            .circle-container {
              min-width: 5px;
              max-width: 5px;
              height: 5px;
            }
            .icon-container {
              min-width: 16px;
              max-width: 16px;
              height: 16px;
            }
          }
        }
      }

      .action-container {
        column-gap: 26px;

        .main-button-container {
          width: 96px;
          padding: 12px;
          font-size: 12px;
        }
        .description-container {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .description-container {
    font-size: 10px !important;
  }
  .card-tariff-container {
    border-width: 1px;
    border-radius: 12px;
    .top-container {
      height: 113px;
    }
    .card-tariff-content {
      height: 367px;
      padding: 16px;
      .center-container {
        .header-container {
          .main-title-container {
            font-size: 14px;
          }
        }
        .items-container {
          margin-top: 15px;
          row-gap: 4px;
          .card-mini-title-container {
            font-size: 10px !important;
            column-gap: 8px;
            &.main-title {
              font-size: 10px !important;
              margin-bottom: 4px;
            }
            .icon-container {
              padding: 2px;
              min-width: 16px;
              max-width: 16px;
              height: 16px;
            }
          }
        }
      }
      .bottom-container {
        row-gap: 15px;
        .card-mini-title-container {
          font-size: 10px;
        }
      }
      .action-container {
        column-gap: 26px;

        .main-button-container {
          width: 112px;
          padding: 10px;
          font-size: 12px;
        }
        .description-container {
          font-size: 12px;
        }
      }
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .card-tariff-container {
   .card-tariff-content {
     height: 360px;
   }
  }
}

@media (min-width: 3025px) {
  .description-container {
    font-size: 30px !important;
  }
  .card-tariff-container {
    border-width: 3px;
    border-radius: 32px;
    .top-container {
      height: 460px;
    }
    .card-tariff-content {
      height: 1140px;
      padding: 80px;
      .center-container {
        .header-container {
          margin-bottom: 22px;
          .main-title-container {
            font-size: 66px;
          }
        }

        .items-container {
          margin-top: 52px;
          row-gap: 18px;
          .card-mini-title-container {
            font-size: 30px;
            &.main-title {
              margin-bottom: 4px;
            }
            column-gap: 24px;

            .circle-container {
              min-width: 14px;
              max-width: 14px;
              height: 14px;
            }
            .icon-container {
              min-width: 40px;
              max-width: 40px;
              height: 40px;
            }
          }
        }
      }

      .bottom-container {
        row-gap: 32px;
        .card-mini-title-container {
          font-size: 30px;
        }
      }
      .action-container {
        column-gap: 52px;

        .main-button-container {
          width: 266px;
          padding: 26px;
          font-size: 28px;
        }
        .description-container {
          font-size: 32px !important;
        }
      }
    }
  }
}

</style>