<template>
  <div class="support-screen-container main-content" id="support">
    <div class="left-container">
      <sub-title>
        {{ $t('supportScreen.mainTitle') }}
      </sub-title>
      <div class="center-container">
        <mini-title-card class="main-title">
          {{ $t('supportScreen.subTitle') }}
        </mini-title-card>
        <mini-title-card class="main-paragraph">
          {{ $t('supportScreen.mainParagraph') }}
        </mini-title-card>
      </div>

      <main-button @click="openModalContact">
        {{ $t('supportScreen.textButton') }}
      </main-button>
    </div>
    <div class="right-container">
        <card-element v-for="item in supportInfo"
                      @actionIcon="downloadItem"
                      :active-icon="true"
                      :key="item.id"
                      :item="item"/>
    </div>
  </div>
</template>

<script>
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import CardElement from "@/components/entities/card/CardElement.vue";

export default {
  name: "SupportScreen",
  components: {CardElement, MainButton, MiniTitleCard, SubTitle},
  computed: {
    supportInfo() {
      return this.$tm('supportScreen.supportInfo').map((item, index) => ({...item, id: index, icon: 'pdf'}))
    }
  },
  methods: {
    downloadItem(id) {
      switch (id) {
        case 0:
          console.log('0')
          break;
        case 1:
          console.log('1')
          break;
        case 2:
          console.log('2')
          break;
      }
    }
  }
}
</script>

<style scoped lang="scss">
  .support-screen-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .left-container {
      display: flex;
      flex-direction: column;

      .center-container {
        display: flex;
        flex-direction: column;

        .main-title {
          font-weight: 600;
          text-transform: uppercase;
        }
        .main-paragraph {
          font-weight: 400;
        }
      }
    }
    .right-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-content: center;
    }
  }



  @media (max-width: 3024px){
    .support-screen-container {
      .left-container {
        max-width: 620px;
        row-gap: 44px;
        .center-container {
          row-gap: 20px;
        }
      }
      .right-container {
        max-width: 616px;
      }
    }
  }


  @media (max-width: 1920px) {
    .support-screen-container {
      .left-container {
        max-width: 500px;
        row-gap: 32px;
        .center-container {
          row-gap: 16px;
        }
      }
      .right-container {
        max-width: 513px;
      }
    }
  }


  @media (max-width: 1600px) {
    .support-screen-container {
      .left-container {
        max-width: 480px;
        row-gap: 24px;
        .center-container {
          row-gap: 8px;
        }
      }
      .right-container {
        max-width: 372px;
      }
    }
  }
  @media (max-height: 800px) {
    .support-screen-container {
      .left-container {
        max-width: 318px;
        row-gap: 24px;
        .center-container {
          row-gap: 8px;
        }
      }
      .right-container {
        max-width: 372px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .support-screen-container {
      .left-container {
        max-width: 480px;
        row-gap: 24px;
        .center-container {
          row-gap: 8px;
        }
      }
      .right-container {
        max-width: 372px;
      }
    }
  }

  @media (max-width: 1133px) {
    .support-screen-container {
      .left-container {
        max-width: 318px;
        row-gap: 24px;
        .center-container {
          row-gap: 8px;
        }
      }
      .right-container {
        max-width: 372px;
      }
    }
  }

  @media (max-width: 900px) {
    .support-screen-container {
      flex-direction: column;
      row-gap: 32px;
      .left-container {
        max-width: 100%;
        row-gap: 16px;
        .main-button-container {
          margin: 0 auto;
        }
        .center-container {
          row-gap: 8px;
        }
      }
      .right-container {
        flex-direction: column;
        row-gap: 16px;
        max-width: 100%;
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .support-screen-container {
      .left-container {
        max-width: 1080px;
        row-gap: 48px;
        .center-container {
          row-gap: 32px;
        }
      }
      .right-container {
        max-width: 978px;
      }
    }
  }

</style>