<template>
  <div class="calculator-screen-container">
    <div class="calculator-screen-content main-content">
      <calculator-screen-main />
      <calculator-screen-text id="developer"/>
    </div>
    <decoration/>
  </div>
</template>

<script>
import CalculatorScreenText from "@/components/features/calculator-screen/CalculatorScreenText.vue";
import CalculatorScreenMain from "@/components/features/calculator-screen/CalculatorScreenMain.vue";
import Decoration from "@/components/entities/Decoration.vue";

export default {
  name: "CalculatorScreen",
  components: {Decoration, CalculatorScreenMain, CalculatorScreenText}
}
</script>

<style scoped lang="scss">
  .calculator-screen-container {
    position: relative;
  }
  .decoration-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
  }


  @media (max-width: 3024px){
    .decoration-wrapper {
      right: -5%;
      max-height: 1360px;
      bottom: -320px;
    }
  }

  @media (max-width: 2240px) and (min-width: 1921px){
    :deep(.decoration-wrapper) {
     .decoration-container {
       &.second {
         right: -40%;
       }
     }
    }
  }

  @media (max-width: 1920px) {
    .decoration-wrapper {
      right: 5%;
      max-height: 1100px;
      bottom: -280px;
    }
  }


  @media (max-width: 1600px) {
    .decoration-wrapper {
      right: -5%;
      max-height: 790px;
      bottom: -175px;
    }
  }
  @media (max-height: 800px) {
    .decoration-wrapper {
      right: 0;
      max-height: 640px;
      bottom: -130px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .decoration-wrapper {
      right: -6%;
      max-height: 790px;
      bottom: -175px;
    }
  }

  @media (max-width: 1133px) {
    .decoration-wrapper {
      right: -2%;
      max-height: 640px;
      bottom: -130px;
    }
  }

  @media (max-width: 900px) {
    .calculator-screen-content {
      row-gap: 64px;
    }

    .decoration-wrapper {
      display: none;
      right: 75%;
      max-height: 480px;
      bottom: 180px;
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    //plus
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .decoration-wrapper {
      right: -10%;
      max-height: 1554px;
      position: absolute;
      bottom: -255px;
    }
  }

</style>