<template>
  <div class="menu-content">
    <a class="scrollactive-item"
             v-for="item in menuInfo"
             :href="item.rout"
             :data-section-selector="getBlock(item)"
             :key="item.id"
             @click.prevent="scrollTo(item)">
            <div class="title-container" :class="{'active' : activeIndex === item.id}">
              {{ item.text }}
            </div>
          </a>
  </div>
</template>

<script>
import MainParagraph from "@/components/shared/text/MainParagraph.vue";

export default {
  name: "MenuItem",
  components: {MainParagraph},
  props: {
    menuInfo: {
      type: Array,
      required: true
    },
    offset: {
      type: Number,
      default: 20
    },
    activeIndex: {
      type: Number
    }
  },

  methods: {
    scrollTo(item) {
      this.$emit('scrollTo', item)
    },

    getBlock(item) {
      return `#${item.rout}`;
    },

  }
}
</script>

<style scoped lang="scss">
.menu-content {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title-container {
  white-space: nowrap;
  text-transform: uppercase;
  cursor: pointer;
  transition: opacity .6s ease;

  &.active {
    opacity: .5;
  }

  &:hover {
    opacity: .5;
  }
}


.scrollactive-item {
  transition: transform 0.3s ease;
  position: relative;
}


@media (max-width: 3024px){
  .menu-content {
  }

  .title-container {
    font-size: 20px;
  }
}


@media (max-width: 1920px) {
  .title-container {
    font-size: 16px;
  }
}


@media (max-width: 1600px) {
  .title-container {
    font-size: 14px;
  }
}
@media (max-height: 800px) {
  .menu-content {
  }

  .title-container {
    font-size: 12px;
  }
}

@media (max-width: 1400px) and (min-height: 801px) {

  .title-container {
    font-size: 14px;
  }
}

@media (max-width: 1133px) {
  .title-container {
    font-size: 12px;
  }
}

@media (max-width: 900px) {
  .menu-content {
    flex-direction: column;
    row-gap: 12px;
  }
}

@media (max-width: 769px) {
  .menu-content {
    flex-direction: column;
    row-gap: 32px;
  }
  .title-container {
    font-size: 22px;
  }
}



@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  .menu-content {
    flex-direction: column;
    row-gap: 16px;
  }
  .title-container {
    font-size: 16px;
  }
}

@media (min-width: 3025px) {
  .title-container {
    font-size: 32px;
  }
}

</style>