import {mapActions} from "vuex";

export default {
        methods: {
            ...mapActions(['IS_ACTIVE_MODAL_DEMO', 'IS_ACTIVE_MODAL_TARIFF','IS_ACTIVE_MODAL_CONTACT', 'IS_ACTIVE_MODAL_PARTNER']),
            openModalDemo() {
                this.IS_ACTIVE_MODAL_DEMO(true)
            },
            openModalTariff() {
                this.IS_ACTIVE_MODAL_TARIFF(true)
            },
            openModalContact() {
                this.IS_ACTIVE_MODAL_CONTACT(true)
            },
            openModalPartner() {
                this.IS_ACTIVE_MODAL_PARTNER(true)
            }
        }
    }