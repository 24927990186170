<template>
  <div class="check-box-container">
    <input :id="'toggler-'+nameCheck"
           :name="'toggler-'+nameCheck"
           type="checkbox" v-model="checkActive"
           @change="changeState">
    <label :for="'toggler-'+nameCheck">
      <span class="toggler-on"/>
      <span class="toggler-off"/>
    </label>
  </div>
</template>

<script>
export default {
  name: "Toggle",
  props: {
    nameCheck: {
      type: String,
      required: true
    },
  },
  data() {
    return {
      checkActive: true,
    }
  },

  methods: {
    changeState() {
      this.$emit('changeState')
    }
  }
}
</script>

<style scoped lang="scss">
.check-box-container {
  transition: opacity .6s ease;
}

.check-box-container input {
  display: none;
}

.check-box-container label {
  transition: background .3s ease;
  display: block;
  position: relative;
  background: rgba(var(--color-rgba-squid), .20);
  cursor: pointer;
}

.check-box-container input:checked + label{
  background: var(--color-font-squid);
}

.check-box-container label::after, .check-box-container label::before {
  display: block;
  border-radius: 50%;
  background: var(--color-font-white);
  content: '';
  animation-name: toggler-size;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  animation-iteration-count: 1;
  animation-play-state: running;
}

.check-box-container label::after {

}


.check-box-container {
  & label {

    &:after {
      filter: blur(var(--active-blur));
    }

    &:before {
      filter: blur(var(--active-blur));
    }

    &:hover {
      &:after {
        filter: blur(0px);
      }

      &:before {
        filter: blur(0px);
      }
    }
  }
}



.check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off, .check-box-container label::before, {
  position: absolute;
  transform: translateY(-50%) translateX(-50%);
  transition: left 0.15s ease-in-out, background-color 0.2s ease-out, width 0.15s ease-in-out, height 0.15s ease-in-out, opacity 0.15s ease-in-out, filter .6s ease;
}

.check-box-container input:checked + label::after, .check-box-container input:checked + label::before {
  animation-name: toggler-size2;
}

.check-box-container .toggler-on, .check-box-container .toggler-off {
  opacity: 1;
  z-index: 2;
  position: relative;
}

.check-box-container input:checked + label .toggler-off, .check-box-container input:not(:checked) + label .toggler-on {
  width: 0;
  height: 0;
  opacity: 0;
}



.check-box-container label::before {
  height: 100%;
  width: 100%;
}


.check-box-container label::after {
  height: 100%;
  width: 100%;
}



.check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
  z-index: 10;
}


.check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
  z-index: 10;
}

@media (max-width: 3024px){
  .check-box-container {
    width: 79px;
  }

  .check-box-container label {
    width: 79px;
    height: 48px;
    margin: 0 2px;
    border-radius: 110px;
  }

  .check-box-container label::before {
    max-width: 34px;
    max-height: 34px;
  }


  .check-box-container label::after {
    max-width: 34px;
    max-height: 34px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }
}



@media (max-width: 1920px) {

  .check-box-container {
    width: 59px;
  }

  .check-box-container label {
    width: 59px;
    height: 35px;
    margin: 0 2px;
    border-radius: 110px;
  }

  .check-box-container label::before {
    max-width: 25px;
    max-height: 25px;
  }


  .check-box-container label::after {
    max-width: 25px;
    max-height: 25px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }
}


@media (max-width: 1600px) {
  .check-box-container {
    width: 59px;
  }

  .check-box-container label {
    width: 59px;
    height: 35px;
    margin: 0 2px;
    border-radius: 110px;
  }

  .check-box-container label::before {
    max-width: 25px;
    max-height: 25px;
  }


  .check-box-container label::after {
    max-width: 25px;
    max-height: 25px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }
}

@media (max-width: 900px) {
  .check-box-container {
    width: 42px;
  }

  .check-box-container label {
    width: 42px;
    height: 20px;
    margin: 0 2px;
    border-radius: 110px;
  }

  .check-box-container label::before {
    max-width: 16px;
    max-height: 16px;
    filter: blur(0px);
  }


  .check-box-container label::after {
    max-width: 16px;
    max-height: 16px;
    filter: blur(0px);
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 75%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 75%;
    z-index: 10;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 24%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 24%;
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //iPhone Plus
}
@media (min-height: 600px) and (max-height: 630px){
  //для экранов 8 плюс
}

@media (max-width: 375px){
  //iPhone 8
}


@media (max-width: 375px) and (min-height: 630px) {
  //iPhone X
}


@media (max-width: 320px) {
  //iPhone SE
}

@media (min-width: 3025px) {


  .check-box-container {
    width: 125px;
  }

  .check-box-container label {
    width: 125px;
    height: 74px;
    margin: 0 2px;
    border-radius: 110px;
  }

  .check-box-container label::before {
    max-width: 55px;
    max-height: 55px;
  }


  .check-box-container label::after {
    max-width: 55px;
    max-height: 55px;
  }


  .check-box-container input:checked + label::after, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
  }

  .check-box-container input:checked + label::before, .check-box-container input:checked + label .toggler-on, .check-box-container input:checked + label .check-box-container-off {
    left: 70%;
    z-index: 10;
  }

  .check-box-container label::after, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }

  .check-box-container label::before, .check-box-container label .check-box-container-on, .check-box-container label .check-box-container-off {
    top: 49.5%;
    left: 30%;
  }
}


</style>