<template>
  <layout-modal-form :text-button="$t('modal.textButtonDemo')"
                     :main-title="$t('modal.mainTitleDemo')"
                     :loading="loading"
                     :disabled="!isValid"
                     @closeModal="closeModal"
                     @actionButton="sendLetter">
    <title-input v-for="(item, index) in inputInfo"
                 @changeTextInput="changeText"
                 @changeCountryCode="changeCountryCode"
                 :name-block="'form-center-container'"
                 :key="index"
                 :item="item"/>
  </layout-modal-form>
</template>

<script>
import LayoutModalForm from "@/components/entities/modals/layouts/LayoutModalForm.vue";
import {mapActions} from "vuex";
import TitleInput from "@/components/shared/TitleInput.vue";

export default {
  name: "ModalDemo",
  components: {TitleInput, LayoutModalForm},

  data() {
    return {
      loading: false,
      form: {
        name: '',
        mail: '',
        phone: '',
        job: ''
      },
      country: '',
      inputInfoStatic: [
        {
          id: 0,
          required: true,
          dataIndex: 'name',
          type: 'fname'
        },
        {
          id: 1,
          required: true,
          dataIndex: 'mail',
          type: 'mail'
        },
        {
          id: 2,
          required: true,
          dataIndex: 'phone',
          mask: '+# (###) ###-##-##',
          type: 'phone'
        },
        {
          id: 3,
          dataIndex: 'company',
          type: 'text'
        },
        {
          id: 4,
          dataIndex: 'job',
          type: 'text'
        },
      ]
    }
  },
  computed: {
    isValid() {
      return this.inputInfoStatic
          .filter(item => item.required || item.type === 'mail')
          .map(item => item.type !== 'mail'
              ? this.form[item.dataIndex] !== ''
              : this.validateEmail(this.form[item.dataIndex])).every(item => item);
    },
    inputInfo() {
      return this.$tm('modalContact.info').map((item, index) => ({
        ...item,
        ...this.inputInfoStatic[index]
      }))
    }
  },
  methods: {
    ...mapActions(['IS_ACTIVE_MODAL_DEMO']),
    closeModal() {
      this.IS_ACTIVE_MODAL_DEMO(false)
    },
    sendLetter() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        this.closeModal();
      }, 1000)
    },
    changeText(value, key) {
      this.form[key] = value
    },
    changeCountryCode(code) {
      this.country = code;
    }
  }
}
</script>

<style scoped>

</style>