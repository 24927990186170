<template>
  <div class="about-screen-features-container"
       :style="{'--active-height-block': activeHeightBlock}"
  >
    <slider-scroll class="top-container"
                   :slides-per-view="slidesPerView">
      <swiper-slide @mouseleave="focusFalse"
           @mouseenter="focusTrue(index)"
           v-for="(item, index) in headerInfo"
           @click="changeActiveIndex(index)"
           :key="index">
        <div class="header-content"
             :class="{'active' : activeIndex === index}">
          <div class="arrow-container">
            <img :src="getUrlIcons('arrow-white')" alt="arrow">
          </div>
          <mini-title>
            {{ item }}
          </mini-title>
        </div>
      </swiper-slide>
    </slider-scroll>
      <div class="bottom-container">
        <div class="card-container"
             :class="{'active': isVisible(item.id)}"
             v-for="item in activeInfo"
             :key="item.id">
          <sub-title-card>
            {{ item.title }}
          </sub-title-card>
          <main-paragraph-card>
            {{ item.description }}
          </main-paragraph-card>
        </div>
      </div>
    <div class="bottom-container absolute-container">
      <div class="card-container"
           :class="{'active': isVisible(item.id)}"
           v-for="item in activeInfo"
           :key="item.id">
        <sub-title-card>
          {{ item.title }}
        </sub-title-card>
        <main-paragraph-card>
          {{ item.description }}
        </main-paragraph-card>
      </div>
    </div>

    <div class="bg-container">
      <img :src="getUrlImg('background')" alt="bg">
    </div>
  </div>
</template>

<script>
import MiniTitle from "@/components/shared/text/MiniTitle.vue";
import SubTitleCard from "@/components/shared/text/card/SubTitleCard.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";
import SliderScroll from "@/components/entities/SliderScroll.vue";
import {mapState} from "vuex";

export default {
  name: "AboutScreenFeatures",
  components: {SliderScroll, MainParagraphCard, SubTitleCard, MiniTitle},
  data() {
    return {
      activeHeightBlock: `100%`,
      timer: null,
      timerIndex: null,
      visibleIndexItem: -1,
      activeIndex: 0,
      focusIndex: -1,
      classActive: ['first', 'second', 'third']
    }
  },
  mounted() {
    this.showNextItem();
  },
  computed: {
    ...mapState(['activeMiniMobile']),
    headerInfo() {
      return this.$tm('aboutScreenFeatures.headerInfo')
    },
    cardsInfo() {
      return this.$tm('aboutScreenFeatures.cardsInfo')
    },
    activeInfo() {
      return this.cardsInfo[this.activeIndex]
    },
    slidesPerView() {
      return this.innerWidthCustom < 900
          ? this.innerWidthCustom > 769
              ? 2.5
              : this.innerWidthCustom > 400
                ? 1.5
                : this.innerWidthCustom > 320
                    ? 1.3
                    : 1.1
          : 3
    }
  },
  methods: {
    focusTrue(index) {
      this.focusIndex = index
    },
    focusFalse() {
      this.focusIndex = -1
    },
    changeActiveIndex(index) {
      clearTimeout(this.timer)
      this.hideItems(index);
    },


    showNextItem() {
      let delf = Math.floor(this.activeInfo.length / 2)
      if (this.visibleIndexItem < this.activeInfo.length) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 200)
      }
      if(delf === this.visibleIndexItem) {
        this.activeHeightBlock = `${document.querySelector('.bottom-container.absolute-container').clientHeight + 96}px`;
      }
    },
    hideItems(index) {
      if (this.visibleIndexItem > -1) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem--
          this.hideItems(index);
        }, 100)
      } else {
        clearTimeout(this.timerIndex)
        this.timerIndex = setTimeout(() => {
          this.activeIndex = index
        }, 200)
        this.showNextItem();
      }
    },
  }
}
</script>

<style scoped lang="scss">
  .about-screen-features-container {
    position: relative;
    overflow: hidden;

    .top-container {
      display: flex;
      flex-direction: row;
      position: relative;
      z-index: 1;

      .header-content {
        align-items: center;
        display: flex;
        flex-direction: row;
        cursor: pointer;
        opacity: .2;
        transition: all .3s ease;

        &:hover {
          opacity: 1;

          .mini-title-container {
            color: var(--color-font-non-photo-blue);
          }
        }

        .mini-title-container {
          white-space: nowrap;
          transition: all .3s ease;
          text-transform: uppercase;
          color: var(--color-main);
        }

        &.active {
          opacity: 1;

          .arrow-container {
            opacity: 1;
            transform: scale(1);
          }
        }

        .arrow-container {
          display: flex;
          align-items: center;
          justify-content: center;
          transform: scale(0);
          transition: all .3s ease;
          opacity: 0;

          & img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }


    .bottom-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;
      z-index: 1;

      &.absolute-container {
        padding-right: 20px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        display: none;

        @media(max-width: 769px) {
          display: flex;
        }
      }

      & > * {
        flex-basis: 45%;
      }

      .card-container {
        display: flex;
        flex-direction: column;
        opacity: 0;
        transition: all .3s ease;

        &.active {
          opacity: 1;
        }

        .card-sub-title-container {
          text-transform: uppercase;
          color: var(--color-font-non-photo-blue);
        }

        .card-main-paragraph-container {
          color: var(--color-font-alice-blue)
        }

        &:nth-child(-n + 2) {
          border-bottom-color: var(---color-queen-blue);
          border-bottom-style: solid;
        }

          &:nth-child(n + 2) {
            padding-bottom: 0;
          }
        }
      }

      .bg-container {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: absolute;
        z-index: 0;

        & img {
          width: 100%;
          height: 100%;
        }
      }
    }



  @media (max-width: 3024px) and (min-width: 1921px){
    .about-screen-features-container {
      padding: 80px 60px 60px;
      border-radius: 32px;

      .top-container {
        column-gap: 80px;
        margin-bottom: 120px;

        .header-content {
          column-gap: 20px;

          .arrow-container {
            width: 13px;
            height: 26px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          row-gap: 20px;
          padding-bottom: 72px;


          .card-sub-title-container {
            font-size: 32px;
          }
          .card-main-paragraph-container {
            font-size: 26px;
          }


          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .about-screen-features-container {
      padding: 60px 40px 80px;
      border-radius: 32px;

      .top-container {
        column-gap: 80px;
        margin-bottom: 120px;

        .header-content {
          column-gap: 20px;

          .arrow-container {
            width: 11px;
            height: 22px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          row-gap: 20px;
          padding-bottom: 63px;

          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .about-screen-features-container {
      padding: 40px;
      border-radius: 30px;

      .top-container {
        column-gap: 24px;
        margin-bottom: 64px;

        .header-content {
          column-gap: 12px;

          .arrow-container {
            width: 9px;
            height: 18px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          height: 142px;
          row-gap: 12px;
          padding-bottom: 42px;

          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .about-screen-features-container {
      padding: 30px;
      border-radius: 24px;

      .top-container {
        column-gap: 24px;
        margin-bottom: 48px;

        .header-content {
          column-gap: 8px;

          .arrow-container {
            width: 6px;
            height: 12px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          height: 140px;
          row-gap: 12px;
          padding-bottom: 42px;

          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .about-screen-features-container {
      padding: 40px;
      border-radius: 30px;

      .top-container {
        column-gap: 24px;
        margin-bottom: 64px;

        .header-content {
          column-gap: 12px;

          .arrow-container {
            width: 9px;
            height: 18px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          height: 142px;
          row-gap: 12px;
          padding-bottom: 42px;

          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .about-screen-features-container {
      padding: 30px;
      border-radius: 24px;

      .top-container {
        column-gap: 24px;
        margin-bottom: 48px;

        .header-content {
          column-gap: 8px;

          .arrow-container {
            width: 6px;
            height: 12px;
          }
        }
      }


      .bottom-container {
        row-gap: 40px;

        .card-container {
          height: 140px;
          row-gap: 12px;
          padding-bottom: 42px;

          &:nth-child(-n + 2) {
            border-bottom-width: 1px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .about-screen-features-container {
      padding: 20px;
      border-radius: 24px;
      transition: height .3s ease;
      height: var(--active-height-block);

      .top-container {
        height: fit-content;
        column-gap: 24px;
        margin-bottom: 32px;

        .header-content {
          padding-right: 16px;
          column-gap: 8px;

          .mini-title-container {
            font-size: 16px !important;
          }

          .arrow-container {
            min-width: 6px;
            max-width: 6px;
            height: 12px;
          }
        }
      }


      .bottom-container {
        flex-direction: column;
        row-gap: 20px;

        .card-container {
          height: 140px;
          row-gap: 12px;
          padding-bottom: 20px !important;
          border-bottom-width: 1px;
          border-bottom-color: var(---color-queen-blue);
          border-bottom-style: solid;

          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .about-screen-features-container {
      padding: 20px;
      border-radius: 24px;
      transition: height .3s ease;

      .top-container {
        height: fit-content;
        column-gap: 24px;
        margin-bottom: 32px;

        .header-content {
          padding-right: 16px;
          column-gap: 8px;

          .mini-title-container {
            font-size: 16px !important;
          }

          .arrow-container {
            min-width: 6px;
            max-width: 6px;
            height: 12px;
          }
        }
      }


      .bottom-container {
        flex-direction: column;
        row-gap: 20px;

        .card-container {
          height: 140px;
          row-gap: 12px;
          padding-bottom: 20px !important;
          border-bottom-width: 1px;
          border-bottom-color: var(---color-queen-blue);
          border-bottom-style: solid;


          &:last-child {
            border-bottom-width: 0;
          }
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .about-screen-features-container {
      padding: 120px 100px;
      border-radius: 48px;

      .top-container {
        column-gap: 80px;
        margin-bottom: 200px;

        .header-content {
          column-gap: 40px;

          .arrow-container {
            width: 23px;
            height: 46px;
          }
        }
      }


      .bottom-container {
        row-gap: 72px;

        .card-container {
          row-gap: 34px;
          padding-bottom: 131px;
          .card-sub-title-container {
            font-size: 52px;
          }
          .card-main-paragraph-container {
            font-size: 44px;
          }

          &:nth-child(-n + 2) {
            border-bottom-width: 6px;
          }
        }
      }
    }
  }

</style>