<template>
  <div class="card-mini-title-container text-content">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "MiniTitleCard"
}
</script>

<style scoped lang="scss">
  .card-mini-title-container {
    font-weight: 500;
    line-height: 120%;
  }


  @media (max-width: 3024px){
    .card-mini-title-container {
      font-size: 22px;
    }
  }


  @media (max-width: 1920px) {
    .card-mini-title-container {
      font-size: 18px;
    }
  }


  @media (max-width: 1600px) {
    .card-mini-title-container {
      font-size: 16px;
    }
  }
  @media (max-height: 800px) {
    .card-mini-title-container {
      font-size: 14px;
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-mini-title-container {
      font-size: 16px;
    }
  }

  @media (max-width: 1133px) {
    .card-mini-title-container {
      font-size: 14px;
    }
  }

  @media (max-width: 900px) {
    .card-mini-title-container {
      font-size: 12px;
    }
  }

  @media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
    .card-mini-title-container {
      font-size: 12px;
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .card-mini-title-container {
      font-size: 40px;
    }
  }


</style>