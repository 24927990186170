<template>
  <div class="project-screen-slider-container active-gradient left" v-animateonscroll="{ enterClass: 'fade-in'}" >
    <div class="top-container">
      <sub-title>
        {{ $t('projectScreenSlider.mainTitle') }}
      </sub-title>
      <div class="controller-container">
        <div class="arrow-container swiper-button-team-left-project left" :class="{'disabled' : activeIndex === 0}">
          <img :src="getUrlIcons('arrow')" alt="arrow-left">
        </div>
        <div class="arrow-container swiper-button-team-right-project right" :class="{'disabled' : activeIndex === projectsInfo.length - 1}">
          <img :src="getUrlIcons('arrow')" alt="arrow-right">
        </div>
      </div>
    </div>
      <slider-scroll :navigation-class="'project'"
                     :class="[{'not-active-animation' : !activeTouchAllow}, 'active-gradient']"
                     :slides-per-view="1"
                     :speed="!activeTouchAllow ? 0 : 1000"
                     @changeActiveIndex="changeActiveIndex">
        <swiper-slide v-for="(item, index) in projectsInfoComputed"
                      @initSwiper="checkSize"
                      v-slot="{ isActive }" >
          <div class="card-project-container"
               :style="{'left': `${item.left}px`,
               '--open-card-width' : `${openCard}px`,
               '--close-card-width' : `${closeCard}px`}"
               :class="{'is-active' : isActive}">
            <div class="info-container">
              <div class="img-container icon">
                <img :src="getUrlProject(`${ index + 1 }.svg`)" alt="bg">
              </div>
              <main-paragraph-card>
                {{ item.title }}
              </main-paragraph-card>
            </div>
            <div class="img-container bg color"></div>
            <div class="img-container bg">
              <img :src="getUrlProject(`${ index + 1 }.webp`)" alt="bg">
            </div>
          </div>
        </swiper-slide>
      </slider-scroll>
  </div>
</template>
<script>
import SliderScroll from "@/components/entities/SliderScroll.vue";
import SubTitle from "@/components/shared/text/SubTitle.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";

export default {
  name: "ProjectScreenSlider",
  components: {MainParagraphCard, SubTitle, SliderScroll},
  data() {
    return {
      activeIndex: 0,
      widthContainer: 0,
      openCard: 0,
      closeCard: 0,
      spaceBetween: 0
    }
  },
  computed: {
    projectsInfo() {
      return this.$tm('projectScreenSlider.projectsInfo')
    },
    projectsInfoComputed() {
      return this.projectsInfo.map((item, index) => {
        let spaceBetween = !this.activeTouchAllow ? this.spaceBetween : 0
        let spaceBetweenActiveIndex = spaceBetween * this.activeIndex;
        let spaceBetweenIndex = spaceBetween * index;

        let firstSlide = index === 0
        let indexPrev = index - 1;

        let closeCard = indexPrev * (this.closeCard + this.spaceBetween - (this.isMobile ? 42 : 0)) //колво закрытых карт до нынешней
        let staticPosition = (index * (-this.widthContainer + this.openCard)) - (firstSlide ? 0 : closeCard) //*ширина контейнера + открытая карта) * на колво слайдов, и отнимаем
        let left = this.activeIndex === 0
            ? staticPosition // дефолт
            : (staticPosition -
                (this.activeIndex >= index
                    ? this.activeIndex === index
                            ? this.openCard + (indexPrev * this.closeCard) + spaceBetweenIndex //активная карта
                            : (this.closeCard * index) + //пройденная карта - колво закрытых карт +
                              (firstSlide
                              ? this.closeCard + spaceBetweenActiveIndex  //колво закрытой карты + промежуток зависимый от активного слайда
                              : this.openCard + spaceBetweenActiveIndex) //колво открытой карты + промежуток зависимый от активного слайда
                    : (this.closeCard + spaceBetween) * this.activeIndex //будущая карта - закрытая карта + промежуток + активный слайд
                     ))
        return {
          title: item,
          left: left - (!this.activeTouchAllow ? 0 : -this.widthContainer * this.activeIndex),
          staticPosition
        }
      })
    }
  },
  watch: {
    innerWidthCustom() {
      this.checkSize();
    }
  },
  mounted() {
    this.checkSize();
  },
  methods: {
    checkSize() {
      let widthContainer = document.querySelector('.project-screen-slider-container')?.clientWidth
      this.widthContainer = widthContainer + (this.isMobile ? 12 : 0)
      this.checkSizeCard(window.innerWidth)
    },
    checkSizeCard(width) {
      this.spaceBetween = 20;
      if(width >= 3025) {
        this.spaceBetween = 40;
        this.openCard = 1080;
        this.closeCard = 520;
      } else if (width > 1920) {
        this.openCard = 620;
        this.closeCard = 300;
      } else if (width > 1600) {
        this.openCard = 500;
        this.closeCard = 240;
      } else if (width > 1300) {
        this.openCard = 380;
        this.closeCard = 180;
      } else if (width > 1000) {
        this.openCard = 317;
        this.closeCard = 148;
      } else {
        this.openCard = 220;
        this.closeCard = 120;
      }
    },
    getUrlProject(picture) {
      return require(`@/assets/projects/${picture}`)
    },
    changeActiveIndex(index) {
      this.activeIndex = index
    }
  }
}
</script>

<style scoped lang="scss">
.not-active-animation {
  :deep(.swiper) {
      .swiper-wrapper {
        transform: none !important;
      }
  }
}

  .project-screen-slider-container {
    display: flex;
    flex-direction: column;
    .top-container {
      position: relative;
      z-index: 3;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-end;
      .sub-title-container {
        text-transform: uppercase;
        white-space: pre-wrap;
      }
      .controller-container {
        display: flex;
        flex-direction: row;
        column-gap: 4px;

        .arrow-container {
          cursor: pointer;
          transition: all .3s ease;
          &.left {
            transform: rotate(180deg);
          }
          & img {
            object-fit: contain;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .card-project-container {
      height: 100%;
      position: relative;
      overflow: hidden;
      max-width: var(--close-card-width);
      width: 100%;
      transition: all 1s ease;
      &.is-active {
        max-width: var(--open-card-width);
        .card-main-paragraph-container {
          font-weight: 600;
        }
      }
      .img-container {
        position: relative;
        width: 100%;
        height: 100%;

        &.bg {
          z-index: 0;
          position: absolute;
          top: 0;
          left: 0;
        }

        &.color {
          z-index: 1;
          background: rgba(var(--color-rgba-bg), .7);
        }

        & img {
          width: 100%;
          height: 100%;
        }
      }

      .info-container {
        white-space: pre-wrap;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        z-index: 2;

        .card-main-paragraph-container {
          transition: all 1s ease;
          color: var(--color-font-white)
        }
      }
    }
  }



  @media (max-width: 3024px){
    .project-screen-slider-container {
      row-gap: 60px;
      .top-container {
        .controller-container {
          column-gap: 12px;

          .arrow-container {
            width: 38px;
            height: 38px;
          }
        }
      }
      .slider-scroll-container {
        height: 700px;
      }
      .card-project-container {
        padding: 40px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 24px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 52px;
          }
        }
        .img-container {
          &.icon {
            width: 74px;
            height: 74px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .project-screen-slider-container {
      row-gap: 50px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 32px;
            height: 32px;
          }
        }
      }
      .slider-scroll-container {
        height: 667px;
      }
      .card-project-container {
        padding: 30px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 22px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 44px;
          }
        }
        .img-container {
          &.icon {
            width: 60px;
            height: 60px;
          }
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 28px;
            height: 28px;
          }
        }
      }
      .slider-scroll-container {
        height: 500px;
      }
      .card-project-container {
        padding: 24px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 16px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 32px;
          }
        }
        .img-container {
          &.icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }
  @media (max-height: 800px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 28px;
            height: 28px;
          }
        }
      }
      .slider-scroll-container {
        height: 460px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 14px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 26px;
          }
        }
        .img-container {
          &.icon {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 28px;
            height: 28px;
          }
        }
      }
      .slider-scroll-container {
        height: 500px;
      }
      .card-project-container {
        padding: 24px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 16px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 32px;
          }
        }
        .img-container {
          &.icon {
            width: 40px;
            height: 40px;
          }
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .project-screen-slider-container {
      row-gap: 40px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 28px;
            height: 28px;
          }
        }
      }
      .slider-scroll-container {
        height: 460px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 14px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 26px;
          }
        }
        .img-container {
          &.icon {
            width: 34px;
            height: 34px;
          }
        }
      }
    }
  }

  @media (max-width: 769px) {
    .project-screen-slider-container {
      row-gap: 32px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 20px;
            height: 20px;
          }
        }
      }
      .slider-scroll-container {
        height: 220px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 12px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 18px;
          }
        }
        .img-container {
          &.icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
    .project-screen-slider-container {
      row-gap: 32px;
      .top-container {
        .controller-container {
          column-gap: 4px;

          .arrow-container {
            width: 20px;
            height: 20px;
          }
        }
      }
      .slider-scroll-container {
        height: 220px;
      }
      .card-project-container {
        padding: 16px;
        border-radius: 20px;
        .card-main-paragraph-container {
          font-size: 12px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 18px;
          }
        }
        .img-container {
          &.icon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    //se new
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    //se old
  }

  @media (min-width: 3025px) {
    .project-screen-slider-container {
      row-gap: 80px;
      .top-container {
        .controller-container {
          column-gap: 16px;

          .arrow-container {
            width: 48px;
            height: 48px;
          }
        }
      }
      .slider-scroll-container {
        height: 1200px;
      }
      .card-project-container {
        padding: 46px;
        border-radius: 48px;
        .card-main-paragraph-container {
          font-size: 40px;
        }
        &.is-active {
          .card-main-paragraph-container {
            font-size: 80px;
          }
        }
        .img-container {
          &.icon {
            width: 90px;
            height: 90px;
          }
        }
      }
    }
  }

</style>