<template>
  <div class="cookie-buttons-content">
      <main-button class="dark blocked" >
        {{ $t('cookie.mainTitle')  }}
      </main-button>
      <div class="button-info-container">
        <main-button v-for="(item, index) in icons"
                     :key="index"
                     @click="action(index)"
                     class="dark" >
          <div class="icon-container">
            <img :src="getUrlIcons(item)" alt="icon">
          </div>
        </main-button>
      </div>
  </div>
</template>

<script>

import MainButton from "@/components/shared/buttons/MainButton.vue";

export default {
  name: "CookieStartButton",
  components: {MainButton},
  data() {
    return {
      icons: ["done", "setting"]
    }
  },
    methods: {
      action(index) {
        switch (index) {
          case 0:
            this.$emit('closeCookie')
            break;
          case 1:
            this.$emit('changeModalCookieSetting')
            break;
        }
      }
  }
}
</script>

<style scoped lang="scss">
.cookie-buttons-content {
  padding: 0 16px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  .icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    & img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .main-button-container {
    font-weight: 400;
    text-transform: none;
  }
  .button-info-container {
    display: flex;
    flex-direction: row;
  }
}

@media (max-width: 3024px){
    .cookie-buttons-content {
      bottom: 100px;
      column-gap: 32px;
      .icon-container {
        width: 32px;
        height: 32px;
      }
      .main-button-container {
        padding: 20.5px 42px;
        font-size: 20px;
        box-shadow: 0 10px 35px rgba(0,0,0,.25);
      }

      .button-info-container {
        column-gap: 16px;

        .main-button-container {
          padding: 16.5px 28px;
        }
      }
  }
}


@media (max-width: 1920px) {
    .cookie-buttons-content {
      bottom: 80px;
      column-gap: 24px;
      .icon-container {
        width: 26px;
        height: 26px;
      }
      .main-button-container {
        padding: 18px 42px;
        font-size: 16px;
      }

      .button-info-container {
        column-gap: 8px;

        .main-button-container {
          padding: 14.5px 24px;
        }
      }
  }
}


@media (max-width: 1600px) {
    .cookie-buttons-content {
      bottom: 60px;
      column-gap: 16px;
      .icon-container {
        width: 26px;
        height: 26px;
      }
      .main-button-container {
        padding: 17px 42px;
        font-size: 14px;
        box-shadow: 0 10px 24px rgba(0,0,0,.25);
      }

      .button-info-container {
        column-gap: 8px;

        .main-button-container {
          padding: 12.5px 24px;
        }
      }
  }
}
@media (max-height: 800px){
  .cookie-buttons-content {
    bottom: 40px;
    column-gap: 16px;
    .icon-container {
      width: 26px;
      height: 26px;
    }
    .main-button-container {
      padding: 18px 42px;
      font-size: 12px;
    }

    .button-info-container {
      column-gap: 8px;

      .main-button-container {
        padding: 12.5px 24px;
      }
    }
}
}

@media (max-width: 1400px) {
  .cookie-buttons-content {
    bottom: 60px;
    column-gap: 16px;
    .icon-container {
      width: 26px;
      height: 26px;
    }
    .main-button-container {
      padding: 17px 42px;
      font-size: 14px;
    }

    .button-info-container {
      column-gap: 8px;

      .main-button-container {
        padding: 12.5px 24px;
      }
    }
}
}

@media (max-width: 1133px) {
  .cookie-buttons-content {
    bottom: 40px;
    column-gap: 16px;
    .icon-container {
      width: 26px;
      height: 26px;
    }
    .main-button-container {
      padding: 18px 42px;
      font-size: 12px;
    }

    .button-info-container {
      column-gap: 8px;

      .main-button-container {
        padding: 12.5px 24px;
      }
    }
}
}
@media (max-width: 769px) {
  .cookie-buttons-content {
    flex-direction: column;
    row-gap: 10px;
    padding: 0 14px;
    bottom: 14px;

    .icon-container {
      width: 22px;
      height: 22px;
    }
      .main-button-container {
        padding: 12px;
        white-space: normal;
        text-align: center;
      }
    .button-info-container {
      column-gap: 10px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 740px) and (max-height: 1000px) {
  .cookie-buttons-content {
    bottom: 16px;
    padding: 0 16px;
  }
}


@media (max-width: 320px) {
  .cookie-buttons-content {

  }
}


@media (min-width: 3025px) {
  .cookie-buttons-content {
    bottom: 128px;
    column-gap: 40px;
    .icon-container {
      width: 52px;
      height: 52px;
    }
    .main-button-container {
      box-shadow: 0 10px 45px rgba(0,0,0,.25);
      padding: 30px 42px;
      font-size: 32px;
    }

    .button-info-container {
      column-gap: 20px;

      .main-button-container {
        padding: 23px 42px;
      }
    }
  }
}


</style>