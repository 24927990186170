<template>
  <div class="modal-layout-container">
    <slot/>
    <div class="frame-modal-background-container"  @click.self="$emit('closeModal')"></div>
  </div>
</template>

<script>
export default {
  name: "LayoutModalBackground"
}
</script>

<style scoped lang="scss">

.modal-layout-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 990;
  width: var(--calc-width);
  height: var(--calc-height);

  .frame-modal-background-container {
    background-color: rgba(1,1,1,.4);
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
    z-index: 990;
  }
}
</style>