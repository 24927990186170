<template>
  <div class="decoration-wrapper">
    <div class="decoration-container first">
      <img :src="getUrlImg('decoration')" alt="decoration">
    </div>
    <div class="decoration-container second">
      <img :src="getUrlImg('decoration')" alt="decoration">
    </div>
  </div>

</template>

<script>
export default {
  name: "Decoration"
}
</script>

<style scoped lang="scss">
.decoration-container {
  z-index: 0;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  & img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 3024px){
    .decoration-container {
      max-width: 1100px;

      &.first {
        right: 20%;
      }
      &.second {
        right: -17%;
      }
    }
  }

@media (max-width: 2560px){
  .decoration-container {
    max-width: 1100px;

    &.first {
      right: 15%;
    }
    &.second {
      right: -29%;
    }
  }
}

@media (max-width: 2240px){
  .decoration-container {
    max-width: 1100px;

    &.first {
      right: 10%;
    }
    &.second {
      right: -15%;
    }
  }
}

@media (max-width: 1920px) {
    .decoration-container {
      max-width: 800px;

      &.second {
        right: -33%;
      }
    }
  }


@media (max-width: 1600px) {
    .decoration-container {
      max-width: 800px;
      &.first {
        right: 0;
      }
      &.second {
        display: none;
      }
    }
}
@media (max-height: 800px) {
    .decoration-container {
      max-width: 600px;
      &.first {
        right: 0;
      }

      &.second {
        display: none;
      }
    }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .decoration-container {
    max-width: 800px;
    &.first {
      right: 0;
    }
    &.second {
      display: none;
    }
  }
}

@media (max-width: 1133px) {
    .decoration-container {
      max-width: 600px;

      &.second {
        display: none;
      }
    }
  }

@media (max-width: 900px) {
  //14
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
    .decoration-container {
      max-width: 2000px;
      &.first {
        right: 20%;
      }
      &.second {
        right: -25%;
      }
    }
}

</style>