<template>
  <div class="card-implementation-container">
    <div class="left-container">
      <div class="info-container">
        <main-title-card>
          {{ item.title }}
        </main-title-card>
        <main-paragraph-card>
          {{ item.description }}
        </main-paragraph-card>
      </div>
      <div class="line-container"></div>
    </div>
    <icon-circle-container>
      {{ item.id }}
    </icon-circle-container>
  </div>
</template>

<script>
import IconCircleContainer from "@/components/shared/IconCircleContainer.vue";
import MainTitleCard from "@/components/shared/text/card/MainTitleCard.vue";
import MainParagraphCard from "@/components/shared/text/card/MainParagraphCard.vue";

export default {
  name: "CardImplementationNew",
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  components: {MainParagraphCard, MainTitleCard, IconCircleContainer}
}
</script>

<style scoped lang="scss">
  .card-implementation-container {
    width: 100%;
    border-left-width: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    &.reverse {
      flex-direction: row-reverse;
      .left-container {
        flex-direction: row-reverse;
      }
    }

    .left-container {
      width: 100%;
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;

      .line-container {
        width: 100%;
        height: 100%;
        border-bottom-style: dashed;
        border-bottom-color: var(--color-font-non-photo-blue);
      }
    }

    .info-container {
      display: flex;
      flex-direction: column;

      .card-main-title-container {
        text-transform: uppercase;
        color: var(--color-font-non-photo-blue)
      }
      .card-main-paragraph-container {
        color: var(--color-sub-main)
      }
    }
  }



  @media (max-width: 3024px) and (min-width: 1921px){

    .card-implementation-container {
      .left-container {
        column-gap: 20px;

        .card-main-title-container {
          font-size: 32px;
        }

        .card-main-paragraph-container {
          font-size: 22px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 620px;
        row-gap: 20px;
        max-width: 620px;
      }
    }
  }


  @media (max-width: 1920px) {
    .card-implementation-container {
      .left-container {
        column-gap: 10px;


        .card-main-title-container {
          font-size: 20px;
        }

        .card-main-paragraph-container {
          font-size: 18px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 400px;
        row-gap: 16px;
        max-width: 400px;
      }
    }
  }


  @media (max-width: 1600px) {
    .card-implementation-container {
      .left-container {
        column-gap: 20px;


        .card-main-title-container {
          font-size: 20px;
        }

        .card-main-paragraph-container {
          font-size: 16px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 380px;
        row-gap: 12px;
        max-width: 380px;
      }
    }
  }
  @media (max-height: 800px) {

    .card-implementation-container {
      .left-container {
        column-gap: 5px;


        .card-main-title-container {
          font-size: 18px;
        }

        .card-main-paragraph-container {
          font-size: 14px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 400px;
        row-gap: 12px;
        max-width: 400px;
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {

    .card-implementation-container {
      .left-container {
        column-gap: 20px;


        .card-main-title-container {
          font-size: 20px;
        }

        .card-main-paragraph-container {
          font-size: 16px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 380px;
        row-gap: 12px;
        max-width: 380px;
      }
    }
  }

  @media (max-width: 1133px) {
    .card-implementation-container {
      .left-container {
        column-gap: 5px;


        .card-main-title-container {
          font-size: 18px;
        }

        .card-main-paragraph-container {
          font-size: 14px;
        }

        .line-container {
          border-bottom-width: 1px;
        }
      }

      .info-container {
        min-width: 400px;
        row-gap: 12px;
        max-width: 400px;
      }
    }
  }


  @media (max-width: 1024px) {

  }

  @media (max-width: 900px) {
    .card-implementation-container {
      flex-direction: row-reverse;

      .left-container {
        flex-direction: row-reverse;
        column-gap: 20px;
        .card-main-title-container {
          font-size: 13px;
        }

        .card-main-paragraph-container {
          font-size: 12px;
        }

        .line-container {
          width: 0;
        }
      }

      .info-container {
        min-width: auto;
        row-gap: 12px;
        max-width: auto;
      }
    }
  }


  @media (min-width: 3025px) {
    .card-implementation-container {
      .left-container {
        column-gap: 40px;

        .card-main-title-container {
          font-size: 56px;
        }

        .card-main-paragraph-container {
          font-size: 40px;
        }

        .line-container {
          border-bottom-width: 2px;
        }
      }

      .info-container {
        min-width: 1080px;
        row-gap: 20px;
        max-width: 1080px;
      }
    }
  }

</style>