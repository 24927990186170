<template>
  <div class="main-wrapper">
    <transition name="fade-opacity-lazy">
      <cookie-screen v-if="isActiveModalCookie"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-contact v-if="isActiveModalContact"/>
    </transition>
    <transition name="fade-opacity-lazy">
        <modal-demo v-if="isActiveModalDemo"/>
    </transition>
    <transition name="fade-opacity-lazy">
      <modal-partner v-if="isActiveModalPartner"/>
    </transition>
      <transition name="fade-opacity-lazy">
         <modal-tariff v-if="isActiveModalTariff"/>
      </transition>
      <main-header v-if="isActiveMount" :active-fixed-header="activeFixedHeader"/>
      <router-view/>
    <main-footer id="contact"/>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import MainHeader from "@/components/widgets/MainHeader.vue";
import MainFooter from "@/components/widgets/MainFooter.vue";
import ModalContact from "@/components/entities/modals/ModalContact.vue";
import ModalDemo from "@/components/entities/modals/ModalDemo.vue";
import ModalTariff from "@/components/entities/modals/ModalTariff.vue";
import CookieScreen from "@/components/entities/cookie/CookieScreen.vue";
import ModalPartner from "@/components/entities/modals/ModalPartner.vue";

export default {
  name: "MainLayout",
  components: {ModalPartner, CookieScreen, ModalTariff, ModalDemo, ModalContact, MainFooter, MainHeader},
  data() {
    return {
      activeFixedHeader: true,
      isActiveMount: false,
    }
  },
  computed: {
    ...mapState(['isActiveModalDemo', 'isActiveModalContact', 'isActiveModalTariff', 'isActiveModalCookie', 'isActiveModalPartner'])
  },
  mounted() {
    if(this.$route.name !== 'main') {
      this.activeFixedHeader = false
    }
    this.isActiveMount = true;
    this.checkSize();
    this.checkHeaderFixed();
    this.checkLocalStorageCookie();
    addEventListener('scroll', this.checkHeaderFixed)
    addEventListener('resize', this.checkSize)
  },
  unmounted() {
    removeEventListener('scroll', this.checkHeaderFixed)
    removeEventListener('resize', this.checkSize)
  },

  methods: {
    ...mapActions(['ACTIVE_MOBILE', 'ACTIVE_IS_MINI_MOBILE', 'ACTIVE_TOUCH_ALLOW', 'INNER_WIDTH', 'INNER_HEIGHT', 'IS_ACTIVE_MODAL_COOKIE']),
    checkLocalStorageCookie() {
      if(!Boolean(localStorage.getItem('cookie_is_active'))) {
        setTimeout(() => { this.IS_ACTIVE_MODAL_COOKIE(true) }, 1000);
      }
    },
    checkSize() {
      this.innerHeight = window.innerHeight;
      document.documentElement.style.setProperty('--vh', `${this.innerHeight * 0.01}px`);
      this.innerWidth = window.innerWidth;
      document.documentElement.style.setProperty('--vw', `${this.innerWidth * 0.01}px`);
      this.INNER_WIDTH(this.innerWidth)
      this.INNER_HEIGHT(this.innerHeight)
      if(this.innerWidth <= 900) {
        this.ACTIVE_MOBILE(true)
      } else {
        this.ACTIVE_MOBILE(false)
      }
      if(this.innerWidth <= 769) {
        this.ACTIVE_IS_MINI_MOBILE(true)
      } else {
        this.ACTIVE_IS_MINI_MOBILE(false)
      }
      if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        this.ACTIVE_TOUCH_ALLOW(true)
      } else {
        this.ACTIVE_TOUCH_ALLOW(false)
      }
    },

    checkHeaderFixed() {
      let scrollY = window.scrollY
      let activePX = this.checkPX(window.innerWidth);
      this.activeFixedHeader = (scrollY + activePX) <= window.innerHeight;
    },

    checkPX(width) {
      if(width >= 3025) {
        return 1600
      } else if (width > 1920) {
        return 1080
      } else if (width > 1600) {
        return 60
      } else if (width > 1300) {
        return 400
      } else {
        return 24
      }
    },
  }
}

</script>

<style scoped lang="scss">
.main-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

</style>