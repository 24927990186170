<template>
  <div class="input-container"
       v-if="!info?.mask"
       @click="focusInput"
       :class="[info?.type || 'text', {'focused' : focused}]">
    <div class="letter-counter" v-if="info?.type === 'textarea'">
      <p>{{this.countLetter}}/{{info?.lengthTextarea || 0}}</p>
    </div>

    <input :type="info?.type || 'text'"
           @focus="focused = true"
           @blur="focused = false"
           :name="info?.dataIndex || 'value'"
           :placeholder="info?.placeholder || '-'"
           v-model="textInput">
<!--    <input :type="info?.type || 'text'"-->
<!--           v-else-->
<!--           v-mask="info.mask"-->
<!--           @focus="focused = true"-->
<!--           @blur="focused = false"-->
<!--           :name="info?.dataIndex || 'value'"-->
<!--           :placeholder="info?.placeholder || '-'"-->
<!--           v-model="textInput">-->
<!--    <textarea class="textarea"-->
<!--              ref="textareaMessage"-->
<!--              @focus="focused = true"-->
<!--              @blur="focused = false"-->
<!--              :name="info?.dataIndex || 'value'"-->
<!--              :placeholder="info?.placeholder || 'Ваш комментарий'"-->
<!--              :maxlength="info?.lengthTextarea"-->
<!--              v-model="textInput" v-else></textarea>-->
  </div>
  <vue-tel-input v-else
                 @click="focusInput"
                 :inputOptions="{
                   placeholder: info?.placeholder || '-'
                 }"
                 :dropdownOptions="{
                   showDialCodeInSelection: true,
                   showFlags: false
                 }"
                 @country-changed="changeCountryCode"
                 @open="openList"
                 @close="closeList"
                 v-model="textInput">
      <template v-slot:arrow-icon>
        <div class="arrow-container" :class="{'active' : activeList}">
          <img :src="getUrlIcons('arrow')" alt="arrow">
        </div>
      </template>
  </vue-tel-input>
</template>

<script>

export default {
  name: "InputMain",
  props: {
    info: {
      type: Object,
      required: true
    },
    nameBlock: {
      type: String,
      required: true
    }
  },

  data() {
    return {
      activeList: false,
      textInput: '',
      countLetter: 0,
      focused: false
    }
  },
  watch: {
    textInput() {
      this.countLetter = this.textInput.length
      if(this.info?.mask && this.textInput.length > 15) {
        this.textInput = this.textInput.substring(0, 15);
      }
      this.$emit('changeTextInput', this.textInput, this.info.dataIndex)
    },
  },
  methods: {
    focusInput() {
      let block = document.querySelector("." + this.nameBlock)
      let input = block.querySelectorAll("input")
      input[this.info.id].focus()
    },
    changeCountryCode(country) {
      this.$emit('changeCountryCode', country.dialCode)
    },
    openList() {
      this.activeList = true;
      this.$nextTick(() => {
        let block = document.querySelector('.vti__dropdown-list');
          if(block) {
            setTimeout(() => {
              block.classList.add('active');
            }, 100)
          }
      })
    },
    closeList() {
      this.activeList = false
      let block = document.querySelector('.vti__dropdown-list');
      block.classList.add('close');
      console.log(block)
    }
  }
}
</script>

<style scoped lang="scss">

.arrow-container {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform .3s ease;
  transform: rotate(90deg);
  width: 14px;
  height: 14px;
  & img {
    width: 100%;
    height: 100%;
  }
  &.active {
    transform: rotate(270deg);
  }
}
.input-container {
  width: 100%;
  overflow: visible;
  cursor: text;
  display: flex;
  justify-content: center;
  align-items: center;
  background: unset;
  position: relative;
  transition: all .5s ease;
  height: 100%;
  background: unset;
  border-color: rgba(var(--color-rgba-squid), .06);
  border-style: solid;

  &:hover {
    border-color: rgba(var(--color-rgba-squid), .2);
  }

  &.focused {
    border-color: rgba(var(--color-rgba-non-photo-blue), .6);
  }

  &.textarea {
    max-height: inherit;
  }
  .letter-counter {
    color: var(--color-font-cool-grey);
    position: absolute;
  }
  .textarea {
    resize: none;
  }
  & input, textarea {
    width: 100%;
    overflow: visible;
    height: 100%;
    color: var(--color-font-delft) !important;
    background-color: rgba(1,1,1,0) !important;

    &::placeholder {
      color: var(--color-font-cool-grey);
    }

    &::-webkit-scrollbar {
      display: none;
    }
  }
}


@media (max-width: 3024px){
  .input-container {
    border-width: 3px;
    border-radius: 22px;
    padding: 18px 20px;
    max-height: 66px;
    &.textarea {
      padding: 18px 20px 70px;
      height: 250px;
    }
    .letter-counter {
      font-size: 24px;
      bottom: 18px;
      right: 20px;
    }
    & input, textarea {
      font-size: 24px;
    }
  }
}


@media (max-width: 1920px) {
  .input-container {
    border-width: 2px;
    border-radius: 14px;
    padding: 12px 14px;
    max-height: 40px;
    &.textarea {
      padding: 12px 14px 48px;
      height: 170px;
    }
    .letter-counter {
      font-size: 16px;
      bottom: 12px;
      right: 14px;
    }
    & input, textarea {
      font-size: 16px;
    }
  }
}


@media (max-width: 1600px) {
  .input-container {
    border-width: 1px;
    border-radius: 12px;
    padding: 12px 14px;
    max-height: 40px;
    &.textarea {
      padding: 12px 14px 28px;
      height: 164px;
    }
    .letter-counter {
      font-size: 16px;
      bottom: 12px;
      right: 14px;
    }
    & input, textarea {
      font-size: 16px;
    }
  }
}
@media (max-height: 800px) {
  .input-container {
    border-radius: 12px;
    padding: 8px 10px;
    max-height: 40px;
    &.textarea {
      padding: 8px 10px 40px;
      height: 90px;
    }
    .letter-counter {
      font-size: 12px;
      bottom: 8px;
      right: 10px;
    }
    & input, textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .input-container {
    border-radius: 12px;
    padding: 12px 14px;
    max-height: 40px;
    &.textarea {
      padding: 12px 14px 28px;
      height: 164px;
    }
    .letter-counter {
      font-size: 16px;
      bottom: 12px;
      right: 14px;
    }
    & input, textarea {
      font-size: 16px;
    }
  }
}

@media (max-width: 1133px) {
  .input-container {
    border-radius: 12px;
    padding: 8px 10px;
    max-height: 40px;
    &.textarea {
      padding: 8px 10px 40px;
      height: 90px;
    }
    .letter-counter {
      font-size: 12px;
      bottom: 8px;
      right: 10px;
    }
    & input, textarea {
      font-size: 12px;
    }
  }
}

@media (max-width: 900px) {

  .arrow-container {
    width: 9px;
    height: 9px;
  }
  .input-container {
    border-radius: 8px;
    padding: 8px;
    max-height: 28px;
    &.textarea {
      padding: 8px 8px 18px;
      height: 58px;
    }
    .letter-counter {
      font-size: 10px;
      bottom: 8px;
      right: 8px;
    }
    & input, textarea {
      font-size: 10px;
    }
  }
}

@media (min-width: 3025px) {

  .arrow-container {
    width: 18px;
    height: 18px;
  }
  .input-container {
    border-radius: 32px;
    padding: 28px 32px;
    max-height: 105px;
    border-width: 4px;
    &.textarea {
      padding: 28px 32px 112px;
      height: 300px;
    }
    .letter-counter {
      font-size: 40px;
      bottom: 28px;
      right: 32px;
    }
    & input, textarea {
      font-size: 40px;
    }
  }
}

</style>