<template>
  <div class="drop-down-list-container" ref="customDropdown">
    <div class="drop-down-list-button" :class="{'active' : activeList}" @click="changeActiveList">
      <p>
        {{ activePlaceholder }}
      </p>
      <div class="arrow-container">
        <img :src="getUrlIcons('arrow')" alt="arrow">
      </div>
    </div>
    <transition name="fade-translate-y">
      <div class="drop-down-list-content" v-if="activeList">
        <ul class="list-content">
          <li class="list-text"
              :class="{'active-text' : activePlaceholder === info.value}"
              @click="chooseText(info, index)"
              v-for="(info, index) in infoList"
              :key="info?.id || index">
            {{info?.value || info}}
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "SelectCurrency",
  props: {
    value: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      infoList: [
        {
          id: 0,
          value: '$'
        },
        {
          id: 1,
          value: '₽'
        },
      ],
      activePlaceholder: '',
      activeList: false,
      activeNumberList: false,
      isActive: false,
      timer: null
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.activePlaceholder = this.value;
    })
    window.addEventListener('click', this.closeOptions);
  },
  unmounted() {
    window.removeEventListener('click', this.closeOptions)
  },
  watch: {
    value() {
      if(!this.isActive) {
        this.activePlaceholder = this.value
      }
    }
  },
  methods: {

    closeOptions(event) {
      if (!this.$refs.customDropdown.contains(event.target)) {
        this.activeList = false;
      }
    },
    changeActiveList() {
      this.activeList = !this.activeList;
    },
    chooseText(text, index) {
      clearTimeout(this.timer)
      this.$emit('changeDropdownList', text.value)
      this.activePlaceholder = text.value
      this.isActive = true;
      this.changeActiveList()
      this.timer = setTimeout(() => {
        this.isActive = false
      }, 240)
    }
  }
}
</script>

<style scoped lang="scss">
.drop-down-list-container {
  position: relative;
  .arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(90deg);
    & img {
      width: 100%;
      height: 100%;
    }
  }
  .drop-down-list-button {
    column-gap: 6px;
    cursor: pointer;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    background: unset;
    position: relative;
    transition: all .5s ease;
    height: 100%;
    width: fit-content;
    &:hover {
      opacity: 1;
    }
  }

  .drop-down-list-content {
    overflow: hidden;
    width: inherit;
    max-width: inherit;
    background-color: var(--color-font-white);
    z-index: 11;
    position: absolute;
    border-color: var(--color-font-alice-blue);
    border-style: solid;

    .list-content {
      display: flex;
      flex-direction: column;

      .list-text {
        line-height: normal;
        cursor: pointer;
        opacity: .6;
        transition: opacity .5s ease;

        &.active-text {
          opacity: 1;
          background-color: rgba(var(--color-rgba-cool-grey), .2);
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}

ul,
ol {
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;
}


@media (max-width: 3024px){
  .drop-down-list-container {
    font-size: 22px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 16px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}


@media (max-width: 1920px) {
  .drop-down-list-container {
    font-size: 18px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 8px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .drop-down-list-container {
    font-size: 16px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 12px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}
@media (max-height: 800px) {
  .drop-down-list-container {
    font-size: 14px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 10px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .drop-down-list-container {
    font-size: 16px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 12px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .drop-down-list-container {
    font-size: 14px;
    .arrow-container {
      width: 14px;
      height: 14px;
    }
    .drop-down-list-button {
      column-gap: 6px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 10px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 4px 12px;
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .drop-down-list-container {
    .arrow-container {
      width: 9px;
      height: 9px;
    }
    .drop-down-list-button {
      column-gap: 3px;
    }
  }
}

@media (max-width: 769px)  and (min-height: 720px) and (max-height: 1000px) {
  //plus
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  //se new
}

@media (max-width: 375px) and (min-height: 630px) {
  //x
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {

  .drop-down-list-container {
    margin-top: -4px;
    font-size: 32px;
    .arrow-container {
      width: 18px;
      height: 18px;
    }
    .drop-down-list-button {
      column-gap: 4px;
    }

    .drop-down-list-content {
      border-radius: 6px;
      margin-top: 24px;
      border-width: 2px;

      .list-content {
        .list-text {
          padding: 12px 18px;
        }
      }
    }
  }
}


</style>