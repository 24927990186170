<template>
  <div class="start-screen-container">
    <div class="start-screen-content main-content">
      <div class="left-container">
        <div class="top-container">
          <main-title :class="{'active' : isVisible(0)}">
            {{ $t('startScreen.mainTitle') }}
          </main-title>
          <main-paragraph :class="{'active' : isVisible(1)}">
            {{ $t('startScreen.mainParagraph')  }}
          </main-paragraph>
<!--          <start-screen-img v-if="isMobile"-->
<!--                            :class="{'active' : isVisible(2)}"/>-->
          <main-button class="dark" @click="openModalContact" :class="{'active' : isVisible(2)}">
            {{ $t('startScreen.textButton') }}
          </main-button>
        </div>
<!--        <div class="bottom-container">-->
<!--          <div class="img-container logo" v-for="(item, index) in 4"-->
<!--               :key="index"-->
<!--               :class="{'active' : isVisible(index + 2)}">-->
<!--            <img :src="getUrlImg(`logo/${item}`)" alt="logo">-->
<!--          </div>-->
<!--        </div>-->
      </div>
    </div>
    <start-screen-img/>
  </div>

</template>

<script>
import MainTitle from "@/components/shared/text/MainTitle.vue";
import MainButton from "@/components/shared/buttons/MainButton.vue";
import MainParagraph from "@/components/shared/text/MainParagraph.vue";
import {mapActions} from "vuex";
import StartScreenImg from "@/components/entities/StartScreenImg.vue";

export default {
  name: "StartScreen",
  components: {StartScreenImg, MainParagraph, MainButton, MainTitle},
  data() {
    return {
      visibleIndexItem: -1,
    }
  },
  mounted() {
    this.showNextItem();
  },
  methods: {
    showNextItem() {
      if (this.visibleIndexItem < 8) {
        this.timer = setTimeout(() => {
          this.visibleIndexItem++
          this.showNextItem();
        }, 200)
      }
    },
  }
}
</script>

<style scoped lang="scss">
.active {
  opacity: 1 !important;
}
  .start-screen-container {
    position: relative;
    overflow: hidden;
    background: linear-gradient(90deg, var(--azure), var(--pale));
    .img-container {
      & img {
        object-fit: contain;
        width: 100%;
        height: 100%;
      }
    }
    .start-screen-content {
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .left-container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .top-container {
          display: flex;
          flex-direction: column;

          & > * {
            opacity: 0;
            transition: all .5s ease;
          }
        }
        .bottom-container {
          width: 100%;
          flex-wrap: wrap;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .img-container {
            opacity: 0;
            transition: all .5s ease;
          }
        }
      }
    }
  }



@media (max-width: 3024px){
  .start-screen-container {
    padding: 265px 0 80px;
    .start-screen-content {
      padding: 0 16px;
      .left-container {
        row-gap: 280px;
        max-width: 940px;

        .top-container {
          row-gap: 40px;

          .main-button-container {
            width: 417px;
          }
        }
        .bottom-container {
          max-width: 709px;
          column-gap: 48px;
          .img-container {
            height: 64px;
            max-width: 169px;
          }
        }
      }
    }
  }
}

@media (max-width: 1920px) {
  .start-screen-container {
    padding: 235px 0 60px;
    .start-screen-content {
      padding: 0 16px;
      height: calc(var(--calc-height) - 235px - 60px);
      .left-container {
        max-width: 750px;

        .top-container {
          row-gap: 30px;

          .main-button-container {
            width: 358px;
          }
        }
        .bottom-container {
          max-width: 534px;
          column-gap: 12px;
          .img-container {
            height: 40px;
            max-width: 150px;
          }
        }
      }
    }
  }
}


@media (max-width: 1600px) {
  .start-screen-container {
    padding: 171px 0 40px;
    .start-screen-content {
      padding: 0 16px;
      height: auto;
      .left-container {
        max-width: 580px;
        .top-container {
          row-gap: 30px;
          .main-button-container {
            width: 330px;
          }
        }
        .bottom-container {
          max-width: 534px;
          column-gap: 40px;
          .img-container {
            height: 40px;
            max-width: 150px;
          }
        }
      }
    }
  }
}

@media (max-height: 800px) {
  .start-screen-container {
    padding: 171px 0 40px;
    .start-screen-content {
      padding: 0 16px;
      height: auto;
      .left-container {
        row-gap: 184px;
        max-width: 400px;
        .top-container {
          row-gap: 30px;
          .main-button-container {
            width: 270px;
          }
        }
        .bottom-container {
          max-width: 400px;
          column-gap: 30px;
          .img-container {
            height: 28px;
            max-width: 105px;
          }
        }
      }
    }
  }
}

@media (max-width: 1400px) and (min-height: 801px) {
  .start-screen-container {
    padding: 171px 0 40px;
    .start-screen-content {
      padding: 0 16px;
      height: auto;
      .left-container {
        max-width: 580px;
        .top-container {
          row-gap: 30px;
          .main-button-container {
            width: 330px;
          }
        }
        .bottom-container {
          max-width: 534px;
          column-gap: 40px;
          .img-container {
            height: 40px;
            max-width: 150px;
          }
        }
      }
    }
  }
}

@media (max-width: 1133px) {
  .start-screen-container {
    padding: 171px 0 40px;
    .start-screen-content {
      padding: 0 16px;
      height: auto;
      .left-container {
        row-gap: 184px;
        max-width: 400px;
        .top-container {
          row-gap: 30px;
          .main-button-container {
            width: 270px;
          }
        }
        .bottom-container {
          max-width: 400px;
          column-gap: 30px;
          .img-container {
            height: 28px;
            max-width: 105px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px) {
  .start-screen-container {
    padding: 94px 0 48px;
    .start-screen-content {
      padding: 0 18px;
      flex-direction: column;
      .left-container {
        padding: 0 12px;
        row-gap: 40px;
        max-width: 100%;
        .top-container {
          text-align: center;
          align-items: center;
          row-gap: 16px;
          .start-screen-img-container {
            margin: 48px 0;
          }
          .main-button-container {
            width: 243px;
          }
        }
        .bottom-container {
          max-width: 100%;
          justify-content: space-between;
          flex-wrap: nowrap;
          column-gap: 0;
          .img-container {
            height: 24px;
            max-width: 75px;
          }
        }
      }
    }
  }
}

@media (max-width: 900px)  and (min-height: 720px) and (max-height: 1000px) {
  .start-screen-container {
    padding: 106px 0 47px;
    .start-screen-content {
      padding: 0 22px;
      flex-direction: column;
      .left-container {
        padding: 0 12px;
        row-gap: 40px;
        max-width: 100%;
        .top-container {
          text-align: center;
          align-items: center;
          row-gap: 16px;
          .start-screen-img-container {
            margin: 48px 0;
          }
          .main-button-container {
            width: 243px;
          }
        }
        .bottom-container {
          max-width: 100%;
          justify-content: space-between;
          .img-container {
            height: 24px;
            max-width: 75px;
          }
        }
      }
    }
  }
}

@media (min-height: 600px) and (max-height: 630px){

  //8 plus
}

@media (max-width: 375px) {
  .start-screen-container {
    padding: 94px 0 48px;
    .start-screen-content {
      padding: 0 16px;
      flex-direction: column;
      .left-container {
        padding: 0 12px;
        row-gap: 40px;
        max-width: 100%;
        .top-container {
          text-align: center;
          align-items: center;
          row-gap: 16px;
          .start-screen-img-container {
            margin: 32px 0;
          }
          .main-button-container {
            width: 243px;
          }
        }
        .bottom-container {
          max-width: 100%;
          justify-content: space-between;
          .img-container {
            height: 24px;
            max-width: 75px;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) and (min-height: 630px) {
  .start-screen-container {
    padding: 94px 0 48px;
    .start-screen-content {
      padding: 0 16px;
      flex-direction: column;
      .left-container {
        padding: 0 12px;
        row-gap: 24px;
        max-width: 100%;
        .top-container {
          text-align: center;
          align-items: center;
          row-gap: 16px;
          .start-screen-img-container {
            margin: 24px 0;
          }
          .main-button-container {
            width: 243px;
          }
        }
        .bottom-container {
          max-width: 100%;
          justify-content: space-between;
          .img-container {
            height: 24px;
            max-width: 75px;
          }
        }
      }
    }
  }
}


@media (max-width: 320px) {
  //se old
}

@media (min-width: 3025px) {
  .start-screen-container {
    padding: 500px 0 200px;
    .start-screen-content {
      padding: 0 16px;
      .left-container {
        row-gap: 208px;
        max-width: 1359px;

        .top-container {
          row-gap: 60px;

          .main-button-container {
            width: 644px;
          }
        }
        .bottom-container {
          max-width: 1205px;
          column-gap: 72px;
          .img-container {
            height: 98px;
            max-width: 346px;
          }
        }
      }
    }
  }
}
</style>