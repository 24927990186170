<template>
  <div class="card-calculator-container" :class="{'active-currency' : item?.isActiveCurrency}">
    <div class="top-container">
      <div class="left-container">
        <sub-title-card v-if="!isMobile">
          {{ translation.title }}
        </sub-title-card>
        <sub-title-card v-else>
          {{ translation?.titleMobile || translation.title }}
        </sub-title-card>
          <mini-title-card>
            {{ translation.description }}
          </mini-title-card>
      </div>
      <input-border :info="{
                      dataIndex: item.key
                    }"
                    :max-length="item.maxValue"
                    :active-currency="item?.isActiveCurrency"
                    :class="{'disabled' : disabled}"
                    @changeTextInput="(value, key) => $emit('changeTextInput', value, key)"
                    :active-text-input="item.value">
        <select-currency v-if="item?.isActiveCurrency"
                         @changeDropdownList="value => $emit('changeCurrency', value)"
                         :value="activeCurrency"/>
      </input-border>
    </div>
    <vue-slider :max="item.maxValue"
                :marks="item.marks"
                :tooltip-formatter="`${item.value.toLocaleString('ru')}`"
                :class="{'disabled' : disabled}"
                :dot-size="[24, 24]"
                :tooltip="'always'"
                v-model="item.value"/>
  </div>
</template>

<script>
import VueSlider from "vue-3-slider-component";
import MiniTitleCard from "@/components/shared/text/card/MiniTitleCard.vue";
import SubTitleCard from "@/components/shared/text/card/SubTitleCard.vue";
import InputBorder from "@/components/shared/input/InputBorder.vue";
import SelectCurrency from "@/components/shared/SelectCurrency.vue";
export default {
  name: "CardCalculator",
  props: {
    translation: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    activeCurrency: {
      type: String,
      required: true,
      default: '$'
    },
    item: {
      type: Object,
      required: true
    },
  },
  components: {SelectCurrency, InputBorder, SubTitleCard, MiniTitleCard, VueSlider},

}
</script>

<style scoped lang="scss">
  .card-calculator-container {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    background: var(--color-font-white);
    box-shadow: 0 0 20px rgba(198, 202, 208, .8);
    .top-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      .input-container {
        width: fit-content;
      }
      .left-container {
        display: flex;
        flex-direction: column;

        .card-sub-title-container {
          color: var(--color-font-delft)
        }
        .card-mini-title-container {
          color: var(--color-font-nickel)
        }
      }
    }
  }



  @media (max-width: 3024px) and (min-width: 1921px){
    .card-calculator-container {
      height: 220px;
      padding: 20px 24px;
      border-radius: 20px;
      .top-container {
        height: 60px;
        .input-container {
          max-width: 172px
        }
        .left-container {
          row-gap: 6px;
          max-width: 600px;

          .card-sub-title-container {
            font-size: 24px;
          }
        }
      }
    }
  }


  @media (max-width: 1920px) {
    .card-calculator-container {
      height: 208px;
      padding: 16px 20px;
      border-radius: 20px;
      .top-container {
        height: 78px;
        .input-container {
          max-width: 150px
        }
        .left-container {
          row-gap: 4px;
          max-width: 500px;
        }
      }
    }
  }


  @media (max-width: 1600px) {
    .card-calculator-container {
      height: 180px;
      padding: 12px;
      border-radius: 16px;
      .top-container {
        height: 45px;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .left-container {
          row-gap: 4px;
          max-width: 375px;
        }
      }
    }
  }
  @media (max-height: 800px) {
    .card-calculator-container {
      height: 180px;
      padding: 12px;
      border-radius: 16px;
      .top-container {
        height: 39px;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .left-container {
          row-gap: 4px;
          max-width: 375px;
        }
      }
    }
  }

  @media (max-width: 1400px) and (min-height: 801px) {
    .card-calculator-container {
      height: 180px;
      padding: 12px;
      border-radius: 16px;
      .top-container {
        height: 45px;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .left-container {
          row-gap: 4px;
          max-width: 375px;
        }
      }
    }
  }

  @media (max-width: 1133px) {
    .card-calculator-container {
      height: 180px;
      padding: 12px;
      border-radius: 16px;
      .top-container {
        height: 39px;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .left-container {
          row-gap: 4px;
          max-width: 375px;
        }
      }
    }
  }

  @media (max-width: 900px) {
    .card-calculator-container {
      overflow: visible;
      height: auto;
      justify-content: flex-start;
      row-gap: 16px;
      padding: 12px;
      border-radius: 16px;
      .top-container {
        height: auto;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .text-content {
          font-size: 12px;
        }
        .left-container {
          row-gap: 4px;
          max-width: 225px;
          .card-mini-title-container {
            white-space: pre-wrap;
            font-size: 10px;
          }
        }
      }
    }
  }


  @media (min-height: 600px) and (max-height: 630px){

    //8 plus
  }

  @media (max-width: 375px) {
    .card-calculator-container {
      &.active-currency {
        .top-container {
          .left-container {
            max-width: 180px;
          }
        }
      }
    }
  }

  @media (max-width: 375px) and (min-height: 630px) {
    //x
  }


  @media (max-width: 320px) {
    .card-calculator-container {
      height: auto;
      padding: 8px;
      border-radius: 16px;
      .top-container {
        height: auto;
        white-space: pre-wrap;
        .input-container {
          max-width: 120px
        }
        .text-content {
          font-size: 10px;
        }
        .left-container {
          row-gap: 4px;
          max-width: 207px;
        }
      }
    }
  }

  @media (min-width: 3025px) {
    .card-calculator-container {
      height: 330px;
      padding: 30px 36px;
      border-radius: 40px;
      .card-mini-title-container {
        font-size: 36px;
      }
      .card-sub-title-container {
        font-size: 30px;
      }
      .top-container {
        height: 78px;
        .input-container {
          max-width: 250px
        }
        .left-container {
          row-gap: 8px;
          max-width: 900px;
        }
      }
    }
  }

</style>